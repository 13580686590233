import React from "react";
import { Header, Segment } from "semantic-ui-react";
import { ICompany } from "../../../app/models/company";

interface IProps {
  company: ICompany;
}

const CompanyInfoDetails: React.FC<IProps> = ({ company }) => {
  return (
    <Segment>
      <Header>
        <Header.Content>{company ? company.taxName : ""}</Header.Content>
        <Header.Subheader>{company ? company.vatNumber: "" }</Header.Subheader>
        <Header.Subheader>{company ? company.commercialName : "" }</Header.Subheader>
      </Header>
    </Segment>
  );
};

export default CompanyInfoDetails;
