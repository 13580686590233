import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Table } from "semantic-ui-react";
import { ICustomer } from "../../../app/models/customer";
import { RootStoreContext } from "../../../app/stores/rootStore";

interface IProps {
  selectCustomer: (...args: any[]) => any;
}

const CustomerList: React.FC<IProps> = ({ selectCustomer }) => {
  const rootStore = useContext(RootStoreContext);
  const {
    customerByName,
    loadingInitial,
    customerCount,
  } = rootStore.customerStore;
  const { addCustomer, setDebit } = rootStore.transportStore;
  const { loadCustomer } = rootStore.customerStore;
  const { closeModal } = rootStore.modalStore;

  const handleSelect = (customer: ICustomer) => {
    const { addresses } = customer;
    setDebit("");
    loadCustomer(customer.customerGuid);
    addCustomer(customer);
    selectCustomer({ ...addresses.filter(x => x.isMain)[0] });
    closeModal();
  };

  return (
    <Table celled selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Nome </Table.HeaderCell>
          <Table.HeaderCell>Empresa</Table.HeaderCell>
          <Table.HeaderCell>Contribuinte</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {customerCount ? (
          customerByName.map((customer) => (
            <Table.Row
              disabled={loadingInitial}
              key={customer.customerGuid}
              onClick={() => handleSelect(customer)}
            >
              <Table.Cell>{customer.displayName}</Table.Cell>
              <Table.Cell>{customer.companyName}</Table.Cell>
              <Table.Cell>{customer.vatNumber}</Table.Cell>
            </Table.Row>
          ))
        ) : (
          <Table.Row>
            <Table.Cell>
              Não existem clientes com os critérios pesquisados
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};

export default observer(CustomerList);
