import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Divider, Tab } from "semantic-ui-react";
import IsAuthorized from "../../../app/common/authorization/IsAuthorized";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { AuthorizationRoles } from "../../../app/models/user";
import { RootStoreContext } from "../../../app/stores/rootStore";
import CustomerDashboard from "../../customer/dashboard/CustomerDashboard";
import SupplierCompanyDetails from "../../supplier/details/SupplierCompanyDetails";
import TransportDashboard from "../../transports/dashboard/TransportDashboard";
import CompanyDetailsDashboard from "./CompanyDetailsDashboard";
import CompanyDetailsHeader from "./CompanyDetailsHeader";

interface DetailsParams {
  id: string;
}

const CompanyDetails: React.FC<RouteComponentProps<DetailsParams>> = ({
  match,
  history,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { company, loading, loadCompany, setPredicate: setPredicateCompany } = rootStore.companyStore;
  const { setPredicate : setPredicateTransport, clearTransportRegistry } = rootStore.transportStore;

  useEffect(() => {
    loadCompany(match.params.id);
    return function cleanup() {
      // setPredicateCompany("all", "");
      setPredicateTransport("all", "");
      clearTransportRegistry();
    };
  }, [loadCompany, match.params.id, setPredicateCompany]);

  if (loading || !company)
    return <LoadingComponent content="A carregar a empresa" />;

  const panes = [
    {
      menuItem: "Detalhes",
      render: () => <CompanyDetailsDashboard />,
    },
    {
      menuItem: "Transportes",
      render: () => (
        <Tab.Pane attached={false}>
          <TransportDashboard />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Clientes",
      render: () => (
        <Tab.Pane attached={false}>
          <CustomerDashboard setValues={() => null} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Empresas Consignadas",
      render: () => (
        <Tab.Pane attached={false}>
          <IsAuthorized
            roles={[AuthorizationRoles.Admin]}
            component={<SupplierCompanyDetails />}
          />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <Fragment>
      <IsAuthorized
        component={
          <Button
            icon="left arrow"
            content="Voltar atrás"
            labelPosition="left"
            onClick={() => history.push(`/companies`)}
          />
        }
        roles={[AuthorizationRoles.Admin]}
      />
      <CompanyDetailsHeader company={company} />
      <Divider horizontal />
      <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
    </Fragment>
  );
};

export default observer(CompanyDetails);
