import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import Agent from "../api/agent";
import { IUser, IUserFormValues, roles, role, ICourierFormValues, CourierFormValues } from "../models/user";
import { RootStore } from "./rootStore";
import { history } from "../..";
import { getIn } from "../common/util/util";
import { toast } from "react-toastify";

export default class UserStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    reaction(
      () => this.user,
      (user) => {
        if (user) {
          window.localStorage.setItem("roles", user.roles.toString());
        } else {
          window.localStorage.removeItem("roles");
        }
      }
    );
  }

  @observable user: IUser | null = null;
  @observable roles: string | null = window.localStorage.getItem("roles");
  @observable submitting = false;

  @computed get isLoggedIn() {
    return !!this.user;
  }

  @computed get accountType() {
    return this.user!.accounttype;
  }

  @computed get getUserAddress() {
    return this.user!.address;
  }

  @action hasRole = (roles: roles) => {
    let isAuthorized = false;
    if (this.user) {
      roles.forEach(
        (role) => (isAuthorized = isAuthorized || this.checkRole(role))
      );
    }
    return isAuthorized;
  };

  checkRole = (role: role) => {
    return this.user!.roles.indexOf(role) >= 0;
  };

  @action login = async (values: IUserFormValues) => {
    try {
      const user = await Agent.User.login(values);
      runInAction(() => {
        this.user = user;
      });
      this.rootStore.commonStore.setToken(user.token);
      history.push(getIn[user.roles[0]]);
    } catch (error) {
      throw error;
    }
  };

  @action getUser = async () => {
    try {
      const user = await Agent.User.current();
      runInAction(() => {
        this.user = user;
        if (user.roles.indexOf("Company") !== -1)
          this.rootStore.companyStore.loadCompanyUser();
      });
    } catch (error) {
      throw error;
    }
  };

  @action register = async (
    values: IUserFormValues,
    registerType: string,
    editMode: boolean
  ) => {
    if (values.password !== values.passwordConfirm) {
      toast.error("Palavras-passe não coincidem!");
      throw new Error();
    }
    if (registerType === "company")
      try {
        if (editMode) await this.editCompanyUser(values);
        else await this.addCompanyUser(values);
      } catch (error) {
        throw error;
      }
  };

  @action registerCourier = async (values: CourierFormValues) => {
    try {
      await this.addCourierUser(values);
    } catch (error) {
      throw error;
    }
  }

  @action logout = () => {
    this.rootStore.commonStore.setToken(null);
    this.user = null;
    history.push("/");
  };

  @action addCourierUser = async (values: CourierFormValues) => {
    this.submitting = true;
    try {
      const newUser = await Agent.User.registerCourier(values);
      this.rootStore.courierStore.addCourierUser(newUser);
      this.submitting = false;
    } catch (error) {
      this.submitting = false;
      throw error;
    }
  };

  @action addCompanyUser = async (values: IUserFormValues) => {
    this.submitting = true;
    try {
      const companyGuid = this.rootStore.companyStore.company!.guid;
      const newUser = await Agent.User.registerToCompany(companyGuid, values);
      this.rootStore.companyStore.addUser(companyGuid, newUser);
      this.submitting = false;
      this.rootStore.modalStore.closeModal();
    } catch (error) {
      this.submitting = false;
      throw error;
    }
  };

  @action editCompanyUser = async (values: IUserFormValues) => {
    this.submitting = true;
    try {
      // const companyGuid = this.rootStore.companyStore.company!.guid;
      const user = await Agent.User.edit(values);
      await this.rootStore.companyStore.editUser(user);
      this.rootStore.modalStore.closeModal();
      this.submitting = false;
    } catch (error) {
      this.submitting = false;
    }
  };
}
