import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Form as FinalForm } from "react-final-form";
import { Button, Checkbox, Form, Grid, Header, Segment } from "semantic-ui-react";
import { ICourier } from "../../../../app/models/user";
import { RootStoreContext } from "../../../../app/stores/rootStore";

interface IProps {
  warehouseId: number;
}

const AddCourierWh: React.FC<IProps> = ({ warehouseId }) => {
  const rootStore = useContext(RootStoreContext);
  const { closeModal } = rootStore.modalStore;
  const { addCouriers, submitting, loading } = rootStore.warehouseStore;
  const { couriers_left, filterCouriersWarehouses, clearfilteredCourier } = rootStore.courierStore;
  const [selectedCouriers, setSelectedCouriers] = useState<{ [key: string]: boolean }>({})


  useEffect(() => {
    filterCouriersWarehouses(warehouseId)
    return () => {
      clearfilteredCourier()
    }
  }, [filterCouriersWarehouses, warehouseId, clearfilteredCourier]);

  const handleCheck = (courierID: string) => setSelectedCouriers(prevSelected => {
    let checked = selectedCouriers[courierID] ? !selectedCouriers[courierID] : true;
    prevSelected[courierID] = checked;
    return { ...prevSelected };
  })

  return (
    <FinalForm
      onSubmit={() => {
        let couriers = Object.keys(selectedCouriers);
        if (couriers) addCouriers(warehouseId, couriers);
      }}
      loading={loading}
      render={({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Segment>
            <Header size="small" content="Selecione o(s) estafeta(s)*" />
            {couriers_left.length ?
              couriers_left.map((c, i) => (
                <Segment.Group horizontal key={i}>
                  <Segment>
                    <Checkbox checked={selectedCouriers[c.id]} onChange={() => handleCheck(c.id)} label={c.displayname} key={c.id} />
                  </Segment>
                  <Segment color={c.warehouseName ? "red" : "green"}>
                    Armazem: {c.warehouseName ?? "Não definida"}
                  </Segment>
                </Segment.Group>
              ))
              :
              "Não há estafetas para apresentar."
            }
          </Segment>
          <Grid>
            <Grid.Column>
              <Button
                type="submit"
                color="green"
                floated="right"
                loading={submitting}
                disabled={submitting}
                positive
              >
                Salvar
              </Button>
              <Button floated="right" onClick={() => closeModal()}>
                Voltar
              </Button>
            </Grid.Column>
          </Grid>
        </Form>
      )}
    ></FinalForm>
  );
};

export default observer(AddCourierWh);
