import { AddressFormValues, IAddress, IAddressFormValues } from "./address";
import { IRefund } from "./refund";

export interface ITransport {
  id: number;
  transportGuid: string; // Guid
  addressFrom: IAddress | IAddressFormValues; // address to be shipped from
  addressTo: IAddress | IAddressFormValues; // address to be shipped
  serviceType: string; // type of service: express; default; ...
  status: ITransportStatus; // status interface to set the status of the tarnsport
  productDetails: string; // type of product to be shipped
  observations: string; // some note to go with the shippment
  invoiceNumber: string; // invoice related to this transport
  isRefund: boolean; // ...
  refundValue?: string; // value to charge on destination
  refund: IRefund;
  isDangerous: boolean; // type of product
  numberOfVolumes: number; // number of volumes to be shipped
  volumes: IVolume[];
  printLabel: boolean;
  printManifest: boolean;
  debit: string; // company vatNumber that pays
  isConsignated: boolean;
  totalWeight: number;
  creationDate: Date;
}

export interface ITransportSummary {
  id : number;
  transportGuid : string;
  addressToDisplayName: string;
  addressToLeftPostCode: string;
  addressToRightPostCode: string;
  addressToLocation: string;
  addressToPostcodeToString: string;
  status: ITransportStatus;
  numberOfVolumes: number;
}

export interface ITransportSummaryEnvelope {
  transports: ITransportSummary[];
  transportCount: TStatus;
}

export interface ITransportEnvelope {
  transports: ITransport[];
  transportCount: TStatus;
}

export interface ITransportStatus {
  transportGuid: string;
  currentStatus: TStatus;
  history: IStatus[];

}

export type KTransportStatus =
  | "created"
  | "picked_up"
  | "warehouse"
  | "courier"
  | "delivered"
  | "canceled";

export enum TStatus {
  CANCELED,
  CREATED,
  IN_TRANSIT,
  DELIVERED
};
export interface IStatus {
  transportGuid: string; // refers to transport guid parent
  statusName: KTransportStatus; // name of the status
  status: TStatus;
  modificationDate: Date;
}

export class TransportStatus implements IStatus {
  transportGuid: string = "";
  statusName: KTransportStatus = "created";
  modificationDate: Date = new Date();
  status: TStatus = TStatus.CREATED;
  constructor(init?: IStatus) {
    Object.assign(this, init);
  }
}

export type KVolumeType =
  | "standard"
  | "windshield"
  | "oilField"
  | "pallet"
  | "lateral_door"
  | "oculus_glass";
export interface IVolume {
  volumeGuid: string; // self generated volume guid
  volumeType: KVolumeType;
  weight: string;
  width: string;
  height: string;
  length: string;
  barcode?: string;
  transport?: ITransport;
}

export class Volume implements Partial<IVolume> {
  width: string = "";
  weight: string = ""
  height: string = "";
  volumeType: KVolumeType = 'standard';
  
  constructor(init? : IVolume) {
    Object.assign(this, init)
  }
}

export interface ITransportFormValues extends Partial<ITransport> { }

export class TransportFormValues implements ITransportFormValues {
  transportGuid: string = "";
  addressFrom: IAddressFormValues = new AddressFormValues();
  addressTo: IAddressFormValues = new AddressFormValues();
  serviceType: string = "express";
  productDetails: string = "";
  observations: string = "";
  invoiceNumber: string = "";
  isRefund: boolean = false;
  refundValue: string = "0";
  isDangerous: boolean = false;
  numberOfVolumes: number = 0;
  volumes: IVolume[] = [{width: "0", length: "0", height: "0", weight: "0", volumeType: "standard", volumeGuid: ""}];
  debit = "";

  constructor(init?: ITransportFormValues) {
    Object.assign(this, init);
  }
}

export interface ICsvIntegrationEnvelope {
  isSupplierError: boolean;
  notConsignatedCompany: Array<string>;
  notFoundAddress: Array<string>;
  noCustomerError: Array<string>;
  notSupplierMsg: string;
  transports: Array<ITransport>;
  transportsCount: number;
  totalWeight: number;
  totalVolumes: number;
  totalErrors: number;
}

export interface ITransportsResume {
  totalTransports: number;
  totalVolumes: number;
  totalWeight: number;
  totalErrors: number;
}

export class TransportsResume implements ITransportsResume {
  totalTransports: number = 0;
  totalVolumes: number = 0;
  totalWeight: number = 0;
  totalErrors: number = 0;

  constructor(
    transports: number,
    volumes: number,
    weight: number,
    errors: number
  ) {
    this.totalErrors = errors;
    this.totalVolumes = volumes;
    this.totalWeight = weight;
    this.totalTransports = transports;
  }
}
