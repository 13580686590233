import { observer } from "mobx-react-lite";
import React, { Fragment, useContext } from "react";
import { Button, List, Segment } from "semantic-ui-react";
import { IRouteSummary } from "../../../../app/models/route";
import { RootStoreContext } from "../../../../app/stores/rootStore";
import AddRoutes from "./AddRoutes";

interface IProps {
  routesName: IRouteSummary[];
}

const AllRoutesCourier: React.FC<IProps> = ({ routesName }) => {
  const rootStore = useContext(RootStoreContext);
  const { openModal } = rootStore.modalStore;
  return (
    <Fragment>
      <Segment.Group>
        <Segment>
          <List divided verticalAlign="middle">
            {routesName.length > 0 ? (
              <Fragment>
                {routesName.map((route) => (
                  <List.Item key={route.id}>
                    <List.Content>{route.id} - {route.name}</List.Content>
                  </List.Item>
                ))}
              </Fragment>
            ) : (
              "Não está associado a rotas!"
            )}
          </List>
        </Segment>
        <Segment>
          <div style={{ padding: 5 }}>
            <Button
              fluid
              color="green"
              content="Adicionar rotas"
              positive
              onClick={() => openModal(<AddRoutes />)}
            />
          </div>
        </Segment>
      </Segment.Group>
    </Fragment>
  );
};

export default observer(AllRoutesCourier);
