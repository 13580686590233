import { observer } from "mobx-react-lite";
import React, { Fragment, useContext } from "react";
import { Item } from "semantic-ui-react";
import ListItemNotFound from "../../../app/common/placeholder/ListItemNotFound";
import { ICompany } from "../../../app/models/company";
import { RootStoreContext } from "../../../app/stores/rootStore";
import CompanyListItem from "./CompanyListItem";

const CompanyList: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { getCompanies } = rootStore.companyStore;
  return (
    <Fragment>
      <Item.Group>
        {getCompanies.length > 0 ? (
          getCompanies.map((company: ICompany) => (
            <CompanyListItem key={company.guid} company={company} />
          ))
        ) : (
          <ListItemNotFound content="Não existem empresas com os parâmetros inseridos." />
        )}
      </Item.Group>
    </Fragment>
  );
};

export default observer(CompanyList);
