import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { getUserAddress } from "../../../app/dataAccess/UserData";
import { RootStoreContext } from "../../../app/stores/rootStore";

interface IProps {
  setAddress: (...args: any) => any;
  addressType: string;
}

const CreateTransportShipper: React.FC<IProps> = ({
  addressType,
  setAddress,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { customerSelectType } = rootStore.customerStore;
  const { company, loadingInitial: loadingCompany } = rootStore.companyStore;
  const {user} = rootStore.userStore;
  const { addCustomer } = rootStore.transportStore;

  const handleSelfAddress = async (setAddress: any) => {
    if (company && user) {
      await customerSelectType(addressType);
      let address = getUserAddress(user);
      addCustomer(company);
      setAddress({ ...address });
    }
  };

  useEffect(() => {
    handleSelfAddress(setAddress);
    // eslint-disable-next-line
  }, [loadingCompany])

  return (
null  );
};

export default observer(CreateTransportShipper);
