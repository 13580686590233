import { PostCode } from "../models/geo";
import { IRoute, RouteFormValues } from "../models/route";
import Agent from "./agent";

export const createRoute = async (route: RouteFormValues): Promise<IRoute> => {
  return await Agent.Routes.create(route);
};

export const addWarehouse = async (routeId: number, whId: number) => {
  try {
    console.log("log", "update route warehouse")
    return await Agent.Routes.addWarehouse(routeId, whId);
  } catch (error) {
    console.log(error);
  }
};

export const addCourier = async (routeId: number, courierId: string) => {
  try {
    return await Agent.Routes.addCourier(routeId, courierId);
  } catch (error) {
    console.log(error);
  }
};

export const addLocal = async (localeIds: number[], routeName: string) => {
  try {
    return await Agent.Routes.addLocal(localeIds, routeName);
  } catch (error) {
    console.log(error);
  }
};

export const addCounty = async (routeId: number, county: string) => {
  try {
    return await Agent.Routes.addCounty(routeId, county);
  } catch (error) {
    console.log(error);
  }
};

export const addDistrict = async (routeId: number, district: string) => {
  try {
    return await Agent.Routes.addDistrict(routeId, district);
  } catch (error) {
    console.log(error);
  }
};

export const listLocales = async (routeId: number) => {
  try {
    return await Agent.Routes.listLocales(routeId);
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const listPostCodes = async (routeId: number) => {
  try {
    return await Agent.Routes.listPostCodes(routeId);
  } catch (error) {
    console.log(error);
    return [];
  }
};
