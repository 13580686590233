import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form as FinalForm } from "react-final-form";
import { Button, Checkbox, Form, Grid, Header, Segment, Table } from "semantic-ui-react";
import { RootStoreContext } from "../../../../app/stores/rootStore";

const AddRoutes: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { closeModal } = rootStore.modalStore;
  const { addRoute, submitting, loading, courier } = rootStore.courierStore;
  const { loadRoutes, clearRoutes, getRoutesFilteredCourier: getRoutesFiltered } = rootStore.routeStore;
  const [selectedRoutes, setSelectedRoutes] = useState<{ [key: number]: boolean }>({})

  useEffect(() => {
    loadRoutes()
    return () => {
      clearRoutes()
    }
  }, [loadRoutes, clearRoutes]);

  const handleCheck = (routeId: number) => setSelectedRoutes(prevSelected => {
    let checked = selectedRoutes[routeId] ? !selectedRoutes[routeId] : true;
    prevSelected[routeId] = checked;
    return { ...prevSelected };
  })
  return (
    <FinalForm
      onSubmit={async () => {
        let toAdd: number[] = [];
        for (let routeId of Object.keys(selectedRoutes).map(x => parseInt(x))) {
          if (selectedRoutes[routeId]) toAdd.push(routeId);
        }
        await addRoute(courier!.id, toAdd);
        closeModal();
        window.location.reload()
      }}
      loading={loading}
      render={({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Segment>
            <Header size="small" content="Selecione a(s) rota(s)*" />
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    Rota
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Armazém
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Estafeta
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {getRoutesFiltered.map((r, i) => (
                  <Table.Row key={r.id}>
                    <Table.Cell>

                      <Checkbox checked={selectedRoutes[r.id]} onChange={() => handleCheck(r.id)} label={r.name} key={r.id} />
                    </Table.Cell>
                    <Table.Cell negative={r.warehouseName !== null} positive={r.warehouseName === null}>

                      Armazem: {r.warehouseName ?? "Não definido"}
                    </Table.Cell>
                    <Table.Cell negative={r.courierDisplayname !== null} positive={r.courierDisplayname === null}>
                      Estafeta: {r.courierDisplayname ?? "Não definida"}

                    </Table.Cell>
                  </Table.Row>
                ))
                }
              </Table.Body>
            </Table>
          </Segment>
          <Grid>
            <Grid.Column>
              <Button
                type="submit"
                color="green"
                floated="right"
                loading={submitting}
                disabled={submitting}
                positive
              >
                Salvar
              </Button>
              <Button floated="right" onClick={() => closeModal()}>
                Voltar
              </Button>
            </Grid.Column>
          </Grid>
        </Form>
      )}
    ></FinalForm>
  );
};

export default observer(AddRoutes);
