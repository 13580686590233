import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Button, Header, Icon, Segment } from "semantic-ui-react";
import Agent from "../../../app/api/agent";
import { getRefundValue } from "../../../app/dataAccess/RefundData";
import { IRefund, IRefundStatus } from "../../../app/models/refund";
import { RootStoreContext } from "../../../app/stores/rootStore";

const TransportRefundConfirm: React.FC<{ refund: IRefund; company: string }> =
  ({ refund, company }) => {
    const [loading, setLoading] = useState(false);
    const rootStore = useContext(RootStoreContext);
    const { closeModal } = rootStore.modalStore;
    useEffect(() => {
      return () => {
        setLoading(false);
        document.querySelectorAll('[role="search_button"]')[0].dispatchEvent(
          new MouseEvent("click", {
            bubbles: true,
            view: window,
            cancelable: true,
            buttons: 1,
          })
        );
      };
    }, []);
    return (
      <Segment placeholder>
        <Header icon>
          <Icon name="question" />
          Validar pagamento no valor de {getRefundValue(refund).toFixed(2)} €?
        </Header>
        <Segment.Inline>
          <Button
            loading={loading}
            disabled={loading}
            positive
            onClick={async () => {
              setLoading(true);
              await Agent.Transport.updateRefundStatus(
                refund.id,
                IRefundStatus.PAID
              );
              closeModal();
            }}
          >
            Confirmar
          </Button>
          <Button onClick={closeModal} disabled={loading}>
            Cancelar
          </Button>
        </Segment.Inline>
      </Segment>
    );
  };

export default TransportRefundConfirm;
