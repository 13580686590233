import React from 'react'
import { FieldErrorsImpl, FieldValues, UseFormRegister } from 'react-hook-form/dist/types';
import { get } from 'react-hook-form'
import { Input, Label } from 'semantic-ui-react';
interface IProps {
    register: UseFormRegister<FieldValues>;
    errors: Partial<FieldErrorsImpl<{
        [x: string]: any;
    }>>
    validation: (...validators: any) => any | undefined;
    name: string;
    placeholder: string;
    maxLength?: number;
    loading?: boolean;
    disabled?: boolean;
    type?: string;
}
const FormTextInput: React.FC<IProps> = ({
    name,
    placeholder,
    register,
    errors,
    validation,
    maxLength,
    loading,
    disabled,
    type
}) => {
    return (
        <>
            <Input placeholder={placeholder}
                maxLength={maxLength}
                fluid
                loading={loading}
                disabled={disabled}
                type={type ?? "text"}
            >
                <input
                    {...register(`${name}` as const, {
                        validate: (value: string) => validation(value)
                    })} />
            </Input>
            {get(errors, name) &&
                <Label basic color="red">
                    {get(errors, name).message}
                </Label>
            }
        </>
    )
}

export default FormTextInput