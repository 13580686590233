import React, { Fragment } from "react";
import { Message, Segment } from "semantic-ui-react";
import { IAddress } from "../../../app/models/address";
import CompanyStoreListItem from "./CompanyStoreListItem";

const CompanyStoreList: React.FC<{ addresses: IAddress[] }> = ({
  addresses,
}) => {
  return (
    <Fragment>
      {addresses && addresses.length > 0 ? (
        addresses.map((address) => (
          <CompanyStoreListItem key={address.addressGuid} address={address} />
        ))
      ) : (
        <Segment>
          <Message warning>
            <Message.Header>Sem endereços!</Message.Header>
            Adicionar endereços antes de começar a trabalhar com esta empresa!
          </Message>
        </Segment>
      )}
    </Fragment>
  );
};

export default CompanyStoreList;
