import { FORM_ERROR } from "final-form";
import React from "react";
import { useContext } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { combineValidators, isRequired } from "revalidate";
import { Button, Form, Header } from "semantic-ui-react";
import ErrorMessage from "../../app/common/form/ErrorMessage";
import TextInput from "../../app/common/form/TextInput";
import { AppTitle } from "../../app/common/util/globals";
import { IUserFormValues } from "../../app/models/user";
import { RootStoreContext } from "../../app/stores/rootStore";

const validate = combineValidators({
  username: isRequired("Utilizador"),
  password: isRequired("password"),
});

const LoginForm = () => {
  const rootStore = useContext(RootStoreContext);
  const { login } = rootStore.userStore;
  return (
    <FinalForm
      onSubmit={(values: IUserFormValues) =>
        login(values).catch((error) => ({
          [FORM_ERROR]: error,
        }))
      }
      validate={validate}
      render={({
        handleSubmit,
        submitting,
        submitError,
        invalid,
        pristine,
        dirtySinceLastSubmit
      }) => (
        <Form onSubmit={handleSubmit} error>
          <Header as='h2' content={`Entrar em ${AppTitle}`} color='red' textAlign='center' />
          <Field name="username" component={TextInput} placeholder="Utilizador" />
          <Field
            name="password"
            component={TextInput}
            placeholder="Password"
            type="password"
          />
          {submitError && !dirtySinceLastSubmit && (
            <ErrorMessage error={submitError} text='Utilizador ou password errrados' />
          )}
          <Button
          // eslint-disable-next-line
            disabled={invalid && !dirtySinceLastSubmit || pristine}
            loading={submitting}
            color='red'
            content="Login"
            fluid

          />
        </Form>
      )}
    />
  );
};

export default LoginForm;
