import { observer } from "mobx-react-lite";
import React from "react";
import { useContext } from "react";
import { Button, Segment } from "semantic-ui-react";
import { TransportDocument } from "../../../app/models/report";
import { RootStoreContext } from "../../../app/stores/rootStore";

const CsvPrintButtons = () => {
  const rootStore = useContext(RootStoreContext);
  const { getCsvGuids } = rootStore.transportStore;
  const { generateTransportDoc, loading } = rootStore.reportStore;

  const generateDoc = (docType: TransportDocument) => {
    generateTransportDoc(getCsvGuids, docType);
  };

  return (
    <Segment>
      <div className="ui two buttons">
        <Button
          content="Imprimir etiquetas"
          color="red"
          loading={loading.get("label")}
          disabled={loading.get("resumedManifestList")}
          onClick={() => generateDoc("label")}
        />
        <Button.Or text="X" />
        <Button
          content="Imprimir manifesto"
          color="grey"
          loading={loading.get("resumedManifestList")}
          disabled={loading.get("label")}
          onClick={() => generateDoc("resumedManifestList")}
        />
      </div>
    </Segment>
  );
};

export default observer(CsvPrintButtons);
