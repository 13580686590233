import React from "react";
import { Header, Icon } from "semantic-ui-react";

interface IProps {
  selected: boolean;
  addressType: string;
}

const CreateTransportShipHeader: React.FC<IProps> = ({
  selected,
  addressType,
}) => {
  return (
    <Header as="h3" color={selected ? "green" : "red"}>
      <Icon name={selected ? "check" : "close"} circular />
      <Header.Content>{addressType}</Header.Content>
    </Header>
  );
};

export default CreateTransportShipHeader;
