import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Divider, Tab } from "semantic-ui-react";
import ListItemNotFound from "../../../app/common/placeholder/ListItemNotFound";
import {
  getCurrentStatus,
  isCanceled,
} from "../../../app/dataAccess/TransportData";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { TStatus } from "../../../app/models/transport";
import { RootStoreContext } from "../../../app/stores/rootStore";
import TransportStatus from "../status/TransportStatus";
import TransportAddressInfo from "./TransportAddressInfo";
import TransportInfo from "./TransportInfo";

interface DetailsParams {
  id: string;
}

const TransportDetails: React.FC<RouteComponentProps<DetailsParams>> = ({
  match,
  history,
}) => {
  const rootStore = useContext(RootStoreContext);
  const {
    transport,
    loadTransport,
    forceLoadTransport,
    loadingInitial,
    deleteTransport,
    deleting,
  } = rootStore.transportStore;
  const { generateTransportDoc, loading } = rootStore.reportStore;

  useEffect(() => {
    forceLoadTransport(match.params.id);
  }, [loadTransport, match.params.id]);

  const panes = [
    {
      menuItem: "Detalhes",
      render: () => (
        <Tab.Pane attached={false}>
          <TransportAddressInfo transport={transport!} />
          <Divider horizontal inverted clearing />
          <TransportInfo transport={transport!} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Localizar",
      render: () => (
        <Tab.Pane attached={false}>
          {" "}
          <TransportStatus tStatus={transport!.status} />{" "}
        </Tab.Pane>
      ),
    },
  ];

  if (loadingInitial)
    return <LoadingComponent content="A carregar transporte" />;

  if (!transport) return <ListItemNotFound content="Transport not found" />;
  return (
    <Fragment>
      <div className="ui four buttons">
        <Button
          icon="left arrow"
          content="Voltar atrás"
          labelPosition="left"
          onClick={() => history.goBack()} //push(`/transport`)}
        />
        {!isCanceled(getCurrentStatus(transport)) ? (
          <Fragment>
            <Button
              content="Imprimir Etiquetas"
              color="grey"
              loading={loading.get("label")}
              onClick={async () => {
                await generateTransportDoc([match.params.id], "label");
                forceLoadTransport(match.params.id);
              }}
            />
            <Button
              content="Imprimir Manifesto"
              color="grey"
              loading={loading.get("detailedManifest")}
              onClick={async () => {
                await generateTransportDoc(
                  [match.params.id],
                  "detailedManifest"
                );
                forceLoadTransport(match.params.id);
              }}
            />
            {transport.status.currentStatus !== TStatus.CREATED ? null : (
              <Button
                content="Anular envio"
                color="red"
                icon="delete"
                labelPosition="left"
                loading={deleting}
                disabled={transport.printManifest}
                onClick={() => {
                  deleteTransport(match.params.id);
                }}
              />
            )}
          </Fragment>
        ) : null}
      </div>

      {/* </Fragment>
        : <Container>Já não é possível imprimir a etiqueta ou manifesto</Container>
        } */}

      <Divider horizontal />
      <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
    </Fragment>
  );
};

export default observer(TransportDetails);
