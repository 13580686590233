import React, { useContext } from "react";
import { Button, Grid, Icon, Item, Segment } from "semantic-ui-react";
import IsAuthorized from "../../../app/common/authorization/IsAuthorized";
import { PT_pt } from "../../../app/common/util/expressions";
import { ICompany } from "../../../app/models/company";
import { AuthorizationRoles } from "../../../app/models/user";
import { RootStoreContext } from "../../../app/stores/rootStore";
import CreateCompany from "../create/CreateCompany";

interface IProps {
  company: ICompany;
}

const CompanyDetailsHeader: React.FC<IProps> = ({ company }) => {
  const rootStore = useContext(RootStoreContext);
  const { openModal } = rootStore.modalStore;
  return (
    <Segment>
      <Grid>
        <Grid.Column width={12}>
          <Item.Group>
            <Item>
              <Item.Content>
                <Item.Header>{company.commercialName}</Item.Header>
                <Item.Meta>
                  {PT_pt.company.taxName[0].toUpperCase() +
                    PT_pt.company.taxName.slice(1)}
                  : {company.taxName}
                </Item.Meta>
                <Item.Description>
                  {PT_pt.company.vatNumber[0].toUpperCase()+
                    PT_pt.company.vatNumber.slice(1)} : {company.vatNumber}
                </Item.Description>
              </Item.Content>
            </Item>
          </Item.Group>
        </Grid.Column>
        <Grid.Column width={4} verticalAlign="middle">
          <IsAuthorized
            roles={[AuthorizationRoles.Admin]}
            component={
              <Button
                floated="right"
                onClick={() =>
                  openModal(<CreateCompany companyEdit={company} />)
                }
                icon
                fluid
                inverted
                color="red"
              >
                <Button.Content>
                  <Icon name="edit" />
                  Editar
                </Button.Content>
              </Button>
            }
          />
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

export default CompanyDetailsHeader;
