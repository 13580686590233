import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import { toast } from "react-toastify";
import Agent from "../api/agent";
import {
  addCounty,
  addCourier,
  addDistrict,
  addLocal,
  addWarehouse,
  createRoute,
} from "../api/RouteRequest";
import { PT_pt } from "../common/util/expressions";
import { LIMIT_BY_PAGE } from "../common/util/globals";
import { County, District, Locale, PostCode, PostCodeRangeFormValues } from "../models/geo";
import { IEditRouteQuery, IRoute, RouteFormValues } from "../models/route";
import { ICourier } from "../models/user";
import { RootStore } from "./rootStore";

export default class RouteStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    reaction(
      () => this.predicate.keys(),
      () => {
        this.page = 0;
        this.clearRoutes();
        this.loadRoutes();
      }
    );
  }

  @observable routes: IRoute[] = [];
  @observable route: IRoute | null = null;
  @observable routes_left: IRoute[] = [];
  @observable loading = false;
  @observable submitting = false;
  @observable loadingInitial = false;
  @observable predicate = new Map();
  @observable page = 0;
  @observable routeCount = 0;

  @computed get axiosParams() {
    const params = new URLSearchParams();
    if (!this.predicate.has("limit"))
      params.append("limit", (LIMIT_BY_PAGE * 10).toString());
    params.append("offset", `${this.page ? this.page * LIMIT_BY_PAGE : 0}`);
    this.predicate.forEach((value, key) => {
      params.append(key, value);
    });
    return params;
  }

  @computed get totalPages() {
    return Math.ceil(this.routeCount / LIMIT_BY_PAGE);
  }

  @computed get getRoutesSliced(): IRoute[] {
    // let slicePage = this.routes.slice(
    //   this.page * LIMIT_BY_PAGE,
    //   this.page * LIMIT_BY_PAGE + LIMIT_BY_PAGE
    // );
    return this.routes;
  }

  @action setPage = (page: number | string) => {
    this.page = Number(page) - 1;
  };

  @action setInifinityPage = (page: number) => {
    this.page = page;
  };

  /**
   * Método para fazer o load inicial de todas as rotas no sistema
   * Vai popular a var "routes"
   */
  @action loadRoutes = async () => {
    try {
      this.loadingInitial = true;
      let params = this.axiosParams;
      params.delete('detailed')
      // params.append('detailed', 'false')
      const routeEnvelope = await Agent.Routes.list(params);
      const { routesDTO } = routeEnvelope;
      runInAction(() => {
        this.routes = routesDTO;
        this.routeCount = routesDTO.length
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.loadingInitial = false;
    }
  };

  @action clearRoutes = () => this.routes = []


  @computed get getRoutesFilteredWh() {
    let warehouse = this.rootStore.warehouseStore.warehouse
    let warehouseId: number = -1;
    if (warehouse) warehouseId = warehouse.id;
    return this.routes.filter(x => x.warehouseId !== warehouseId);
  }

  @computed get getRoutesFilteredCourier() {
    let courier = this.rootStore.courierStore.courier;
    let courierId: string = "";
    if (courier) courierId = courier.id;
    return this.routes.filter(x => x.courierId !== courierId);
  }
  /**
   * Load da rota pelo seu id, para ser apresentada detalhadamente
   * Atualiza a rota na lista
   * @param id rota a ser apresentada
   */
  @action loadRoute = async (id: number) => {
    try {
      this.loading = true;
      let route = await Agent.Routes.details(id);
      runInAction(() => {
        if (route) {
          this.route = route;
        }
      });
      return route;
    } catch (error) {
      toast.error(PT_pt.errors.submitting);
    } finally {
      this.loading = false;
    }
  };

  /**
   * Método usado para adicionar uma nova rota
   * @param route Rota criada
   */
  @action createRoute = async (routeValues: RouteFormValues) => {
    this.submitting = true;
    try {
      let route = await createRoute(routeValues);
      runInAction(() => {
        this.routes.push(route);
        this.routeCount++;
        this.submitting = false;
        this.rootStore.modalStore.closeModal();
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.submitting = false;
    }
  };

  @action editRoute = async (values: IEditRouteQuery) => {
    try {
      this.submitting = true;
      let route = await Agent.Routes.edit(values);
      runInAction(() => {
        this.route!.name = values.newName;
      })
    } catch (error) {
      toast.error(error)
    }
    finally {
      this.submitting = false;
    }
  }
  /**
   * Método para adicionar novos códigos postais à rota
   * @param postCode postCode a adicionar
   */
  @action addPostCodes = async (postCodes: PostCode[]) => {
    this.submitting = true;
    try {
      if (this.route) {
        // await addPostCodes(this.route?.id, postCodes);
        runInAction(() => {
          if (this.route?.postcodes) {
            for (let index = 0; index < postCodes.length; index++) {
              const element = postCodes[index];
              if (!this.hasPostCode(this.route, element)) {
                this.route.postcodes.push(element);
              } else {
                toast.error(PT_pt.errors.submitting);
                console.log("Código postal já existe!");
              }
            }
          }
        });
      }
      this.rootStore.modalStore.closeModal();
      this.submitting = false;
    } catch (error) {
      console.log(error);
      this.submitting = false;
    }
  };

  /**
   * Método para atualizar o armazém da rota
   * @param routeId rota a ser atualizada
   * @param whId id novo armazém
   */
  @action updateWarehouse = async (routeId: number, whId: number) => {
    this.loading = true;
    try {
      await addWarehouse(routeId, whId);
      runInAction(() => {
        let route = this.routes.filter(x => x.id === routeId)[0];
        let warehouse = this.rootStore.warehouseStore.getWarehouse(whId);
        if (warehouse && route !== undefined) {
          route.warehouseId = warehouse.id;
          route.warehouseName = warehouse.name;
          route.warehouseAbbreviation = warehouse.abbreviation
          warehouse.routes?.push(route);
        }

        // if current selected route
        if (warehouse && this.route && this.route.id === routeId) {
          this.route.warehouseId = warehouse.id;
          this.route.warehouseName = warehouse.name;
          this.route.warehouseAbbreviation = warehouse.abbreviation
        }
        this.rootStore.warehouseStore.addWarehouseRoute(route)
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  };

  /**
   * Método para atualizar o estafeta associado à rota
   * @param routeId
   * @param courier
   */
  @action updateCourier = async (routeId: number, courierId: string) => {
    this.loading = true;
    try {
      let route = this.getRoute(routeId);
      if (route) await addCourier(routeId, courierId);
      runInAction(() => {
        let courier = this.rootStore.courierStore.getCourier(courierId);
        // if (courier && route?.courier) route.courier = courier;
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      this.loading = false;
    }
  };

  @action addPostCodeRange = async (routeName: string, values: PostCodeRangeFormValues) => {
    this.submitting = true;
    try {
      let { updatedNos } = await Agent.Routes.addPostCodes(routeName, values);
      return updatedNos;
    } catch (error) {
      console.log(error);
    } finally {
      this.submitting = false;
    }
  };
  /**
   * Adicionar códigos-postais à rota a partir de uma localidade
   * @param local local a adicionar
   * @param country pais em que o local se encontra
   */
  @action addPostCodesLocale = async (routeName: string, locales: number[], country: string) => {
    this.submitting = true;
    try {

      await addLocal(locales, routeName);
      runInAction(() => {
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.submitting = false;

    }
  };

  /**
   * Adicionar códigos-postais à rota a partir de um concelho
   * @param county concelho a adicionar
   * @param country pais do concelho
   */
  @action addPostCodesCounty = async (county: string, country: string) => {
    this.submitting = true;
    try {
      let locals: Locale[] = [];
      if (this.route) {
        locals = await addCounty(this.route.id, county);
      }
      runInAction(() => {
        if (this.route && locals) {
          let new_county: County = { name: county, locales: locals };
          let counties = this.rootStore.geoStore.current_counties.get(
            this.route.id
          );

          if (
            counties &&
            !this.rootStore.geoStore.hasCounty(county, counties)
          ) {
            counties.push(new_county);
            // new_county.locales.map((local) =>
            //   this.addPostCodesLocal(local.name, country)
            // );
            this.rootStore.geoStore.addRouteCounty(
              county,
              country,
              this.route.id
            );
          }
        }
        this.submitting = false;
      });
    } catch (error) {
      console.log(error);
      this.submitting = false;
    }
  };

  /**
   * Adicionar códigos-postais à rota a partir de um distrito
   * @param district distrito a adicionar
   * @param country pais do distrito
   */
  @action addPostCodesDistrict = async (district: string, country: string) => {
    this.submitting = true;
    try {
      let countys: County[] = [];
      if (this.route) {
        countys = await addDistrict(this.route.id, district);
      }
      runInAction(() => {
        if (this.route && countys) {
          let new_district: District = { name: district, counties: countys };
          let districts = this.rootStore.geoStore.current_districts.get(
            this.route.id
          );

          if (
            districts &&
            !this.rootStore.geoStore.hasDistrict(district, districts)
          ) {
            districts.push(new_district);
            new_district.counties.map((county) =>
              this.addPostCodesCounty(county.name, country)
            );
            this.rootStore.geoStore.addRouteDistrict(
              district,
              country,
              this.route.id
            );
          }
        }
        this.submitting = false;
      });
    } catch (error) {
      console.log(error);
      this.submitting = false;
    }
  };

  /**
   * Método a retornar todas as rotas não associadas a um estafeta
   * @param courier estafeta a filtrar da lista
   * @returns
   */
  @action filterRoutesCourier(courier: ICourier) {
    let routes = this.routes;
    this.routes_left = routes
      .filter((x) => x.courierId !== courier.id)
      .slice(
        this.page * LIMIT_BY_PAGE,
        this.page * LIMIT_BY_PAGE + LIMIT_BY_PAGE
      );
  }

  @action updateCurrentRouteCourier = (courierId: string, courierName: string) => {
    if (this.route) {
      this.route.courierId = courierId;
      this.route.courierDisplayname = courierName;
    }
  }
  hasRoute(id: number, routes: IRoute[]) {
    for (let index = 0; index < routes.length; index++) {
      const element = routes[index];
      if (element.id === id) {
        return true;
      }
    }
    return false;
  }

  hasPostCode(route: IRoute, postCode: PostCode) {
    if (route?.postcodes) {
      for (let index = 0; index < route?.postcodes.length; index++) {
        const element = route?.postcodes[index];
        if (
          element.numCode === postCode.numCode &&
          element.extCode === postCode.extCode
        ) {
          return true;
        }
      }
      return false;
    } else {
      console.log("Rota sem códigos postais definidos!");
    }
  }

  getRoute = (id: number) => {
    try {
      for (let index = 0; index < this.routes.length; index++) {
        const element = this.routes[index];
        if (element.id === id) return element;
      }
    } catch (error) {
      console.log(error);
    }
    throw new Error("");
  };

  @action clearRoute = () => {
    this.route = null;
  };

  @action setPredicate = (predicate: string, value: string | Date) => {
    this.predicate.clear();
    if (predicate !== "all") {
      this.predicate.set(predicate, value);
    }
  };
}
