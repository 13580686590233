import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Form as FinalForm } from "react-final-form";
import { Button, Checkbox, Form, Grid, Header, Input, List, ListItem, Pagination, Segment } from "semantic-ui-react";
import { RootStoreContext } from "../../../../app/stores/rootStore";

const AddPostalLocale: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { closeModal } = rootStore.modalStore;
  const { submitting, addPostCodesLocale: addPostCodesLocal, route } = rootStore.routeStore;
  const { loading, clearLocales, localesByPage, loadLocalesNoRoute: loadLocales, totalPages, currentPage, setPage, setFilter, totalElems } = rootStore.geoStore;
  const [selectedLocales, setSelectedLocales] = useState<{ [key: number]: boolean }>({})

  const load = useCallback(
    () => {
      loadLocales(route!.id)
    },
    [loadLocales, route],
  )


  useEffect(() => {
    load()
    return () => {
      clearLocales()
    }
  }, [load, clearLocales]);

  const handleCheck = (localeId: number) => setSelectedLocales(prevSelected => {
    let checked = selectedLocales[localeId] ? !selectedLocales[localeId] : true;
    prevSelected[localeId] = checked;
    return { ...prevSelected };
  })

  return (
    <>
      <Input
        fluid
        icon='search'
        iconPosition="left"
        placeholder="Localidade..."
        onChange={(_, data) => {
          setFilter(data.value.toUpperCase())
        }}
      />
      <FinalForm
        onSubmit={async () => {
          let ids = Object.keys(selectedLocales).map(x => parseInt(x))
          await addPostCodesLocal(route!.name, ids, "Portugal")
          closeModal()
          window.location.reload()
        }}
        render={({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Segment>
              <Header size="small" content="Selecione a(s) localidade(s)" />
              <List>
                {localesByPage.map(locale =>
                  <ListItem key={locale.id}>
                    <Checkbox label={locale.name}
                      checked={selectedLocales[locale.id]}
                      onChange={() => handleCheck(locale.id)} />
                  </ListItem>
                )}

              </List>
              <Pagination
                totalPages={totalPages}
                activePage={currentPage + 1}
                onPageChange={(_, { activePage }) => setPage(activePage!)}
              />
            </Segment>
            <Grid>
              <Grid.Column>
                <Button
                  type="submit"
                  color="green"
                  floated="right"
                  loading={submitting}
                  disabled={submitting}
                  positive
                >
                  Salvar
                </Button>
                <Button floated="right" onClick={() => closeModal()}>
                  Voltar
                </Button>
              </Grid.Column>
            </Grid>
          </Form>
        )}
      ></FinalForm>
    </>
  );
};

export default observer(AddPostalLocale);
