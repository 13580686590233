import { IAddress } from "./address";
import { IRoute } from "./route";
import { ICourier } from "./user";

export interface IWarehouse {
  id: number;
  name: string;
  address: string;
  abbreviation: string;
  routes?: IRoute[];
  couriers?: ICourier[];
  postcode: string;
}

export class WarehouseFormValues {
  name: string = "";
  abbreviation: string = "";
  address: string = "";
  postcode: string = ""
  PostCodeStart: number = 0;
  PostCodeEnd: number = 0;

  constructor(init?: WarehouseFormValues) {
    Object.assign(this, init);
  }
}

export interface IWarehouseEnvelope {
  warehousesDTO: IWarehouse[];
  warehousesDetailedDTO: IWarehouse[];
  warehouseCount: number;
}
