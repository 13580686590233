import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { Button, Form, Grid, Header, Segment } from "semantic-ui-react";
import { Field, Form as FinalForm } from "react-final-form";
import { IRoute, RouteFormValues } from "../../../app/models/route";
import { RootStoreContext } from "../../../app/stores/rootStore";
import {
  composeValidators,
  maxLength,
  minLength,
  required,
} from "../../../app/common/form/Validators";
import TextInput from "../../../app/common/form/TextInput";
import { FORM_ERROR } from "final-form";

const RouteCreateModal: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { createRoute, submitting, loading } = rootStore.routeStore;
  const { closeModal } = rootStore.modalStore;
  const [initialValues] = useState(new RouteFormValues());

  return (
    <FinalForm
      onSubmit={(values: RouteFormValues) =>
        createRoute(values).catch((error) => ({ [FORM_ERROR]: error }))
      }
      loading={loading}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Segment>
            <Header size="small" content="Nome da rota*" />
            <Field
              name="routeName"
              placeholder="Rota #1"
              maxLength={40}
              validate={composeValidators(
                required,
                maxLength(40),
                minLength(3)
              )}
            >
              {(props) => <TextInput {...props} />}
            </Field>
          </Segment>
          <Grid>
            <Grid.Column>
              <Button
                type="submit"
                loading={submitting}
                disabled={submitting}
                color="green"
                floated="right"
                positive
              >
                Salvar
              </Button>
              <Button floated="right" onClick={() => closeModal()}>
                Voltar
              </Button>
            </Grid.Column>
          </Grid>
        </Form>
      )}
    ></FinalForm>
  );
};

export default observer(RouteCreateModal);
