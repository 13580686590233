import { observer } from "mobx-react-lite";
import React, { Fragment, useContext } from "react";
import { Button, List, Message, Segment } from "semantic-ui-react";
import { ICourier } from "../../../../app/models/user";
import { RootStoreContext } from "../../../../app/stores/rootStore";
import AddCourierWh from "./AddCourierWh";
import WarehouseCourierListItem from "./WarehouseCourierListItem";

interface IProps {
  whId: number; // warehouseId
  couriers?: ICourier[];
}

const WarehouseCourierList: React.FC<IProps> = ({ whId, couriers }) => {
  const rootStore = useContext(RootStoreContext);
  const { openModal } = rootStore.modalStore;

  return (
    <Fragment>
      <Segment.Group>
        <Segment>
          <List divided verticalAlign="middle">
            {couriers && couriers.length > 0 ? (
              couriers.map((courier) => (
                <WarehouseCourierListItem key={courier.id} courier={courier} />
              )
              )
            ) : (
              <Message error>
                Sem estafetas adicionados
              </Message>
            )}
          </List>
        </Segment>
        <Segment>
          <div style={{ padding: 5 }}>
            <Button
              fluid
              color="green"
              content="Adicionar estafetas"
              onClick={() => openModal(<AddCourierWh warehouseId={whId} />)}
            />
          </div>
        </Segment>
      </Segment.Group>
    </Fragment>
  );
};

export default observer(WarehouseCourierList);
