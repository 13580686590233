import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { Grid, GridColumn } from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import ZoneList from "./ZoneList";
import ZoneSidebar from "./ZoneSidebar";

const ZoneDashboard: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { loadZones } = rootStore.zoneStore;

    useEffect(() => {
        loadZones();
    }, [loadZones])
  return (
    <Grid>
      <GridColumn width={12}>
        <ZoneList />
      </GridColumn>
      <GridColumn width={4}>
          <ZoneSidebar />
      </GridColumn>
    </Grid>
  );
};

export default observer(ZoneDashboard);
