import { Field } from "react-final-form";
import { Grid, Header, Label } from "semantic-ui-react";
import TextInput from "../../../app/common/form/TextInput";
import {
  composeValidators,
  maxLength,
  minLength,
  required,
} from "../../../app/common/form/Validators";

interface IProps {
  setValue: (...args: any) => any;
}

const CreateTransportDetailsDescription: React.FC<IProps> = ({ setValue }) => {
  return (
    <Grid.Row>
      <Grid.Column width={7}>
        <Header>
          <Label
            as="a"
            onClick={() => setValue("productDetails", "PEÇAS AUTO")}
            color="green"
            ribbon
          >
            Auto
          </Label>
          Descrição dos produtos*
        </Header>

        <Field
          maxLength={50}
          name={"productDetails"}
          placeholder="Obrigatório"
          validate={composeValidators(required, minLength(4), maxLength(50))}
        >
          {(props) => <TextInput {...props} />}
        </Field>
        <Header content="Associar fatura" />
        <Field
          name="invoiceNumber"
          placeholder="Opcional"
          validate={maxLength(20)}
        >
          {(props) => <TextInput {...props} />}
        </Field>
      </Grid.Column>
      <Grid.Column width={9}>
        <Header content="Observações" />
        <Field
          name="observations"
          component="textarea"
          validate={maxLength(50)}
          autoCapitalize="characters"
          rows={2}
          placeholder="Considerações para a transportadora"
        >
          {(props) => <TextInput {...props} />}
        </Field>
      </Grid.Column>
    </Grid.Row>
  );
};

export default CreateTransportDetailsDescription;
