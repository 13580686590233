import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Button, Icon, Item, Label, Segment } from "semantic-ui-react";
import AddressInfoInline from "../../../app/common/address/AddressInfoInline";
import NegativeMessage from "../../../app/common/messages/NegativeMessage";
import { getMainAddress } from "../../../app/dataAccess/AddressData";
import { getCompanyAddressList } from "../../../app/dataAccess/CompanyData";
import { ICompany } from "../../../app/models/company";

const CompanyListItem: React.FC<{ company: ICompany }> = ({ company }) => {
  let addresses = getCompanyAddressList(company);
  return (
    <Segment.Group>
      <Segment>
        {company.isSupplier ? (
          <Label ribbon color="yellow">
            Fornecedor
          </Label>
        ) : null}
        <Item.Group>
          <Item>
            <Item.Content>
              <Item.Header as={Link} to={`/company/${company.guid}`}>
                {company.commercialName}
              </Item.Header>
              <Item.Description>{company.taxName}</Item.Description>
              <Item.Extra>{company.vatNumber}</Item.Extra>
            </Item.Content>
          </Item>
        </Item.Group>
      </Segment>
      <Segment>
        {addresses && addresses.length > 0 ? (
          <Fragment>
            <Icon name="marker" />{" "}
            <AddressInfoInline address={getMainAddress(addresses)} />
          </Fragment>
        ) : (
          <NegativeMessage header="Ainda não existem endereços associados!" />
        )}
      </Segment>
      <Segment clearing>
        {company.hasAgreement ? (
          <Label ribbon color="red">
            Tem contrato
          </Label>
        ) : null}
        <Icon name="box" />
        <span>{company.monthQuantity}</span>
        <Button
          as={Link}
          to={`/company/${company.guid}`}
          floated="right"
          content="Detalhes"
          color="teal"
        />
      </Segment>
    </Segment.Group>
  );
};

export default CompanyListItem;
