import React, { useContext, useEffect, useState } from "react";
import {
  AddressFormValues,
  IAddress,
  IAddressFormValues,
} from "../../app/models/address";
import { Form as FinalForm } from "react-final-form";
import { Button, Form, Segment } from "semantic-ui-react";
import AddressFields from "../../app/common/form/AddressFields";
import { RootStoreContext } from "../../app/stores/rootStore";
import Agent from "../../app/api/agent";
import { useForm } from "react-hook-form";

interface IProps {
  address?: IAddress | IAddressFormValues;
  entityType: string; //company or customer
  guid: string;
}

const AddressForm: React.FC<IProps> = ({ address, entityType, guid }) => {
  const rootStore = useContext(RootStoreContext);
  const { addressHandler } = rootStore.addressStore;
  const { closeModal } = rootStore.modalStore;
  // const [initialValues] = useState(
  //   address ? address : new AddressFormValues()
  // );

  const [load, setload] = useState(false)
  const [formValues, setformValues] = useState(address ? { address: address } : { address: new AddressFormValues() })

  const { register, reset, handleSubmit, watch, setError, setValue, clearErrors, getValues, formState: { errors }, control } = useForm();
  const postcode = watch('address.postcode')
  useEffect(() => {
    // setformValues({ address: new AddressFormValues() })
  }, [])


  async function submitHandler(data: any) {
    try {
      console.log(JSON.stringify(data))
      addressHandler(data.address, entityType, guid).then(() =>
        closeModal()
      )

    } catch (error) {
      setError('submit', { type: 'manual', message: JSON.stringify(error) });
    }
  }

  useEffect(() => {
    reset(formValues)
    console.log(JSON.stringify(address))
    console.log('form ',JSON.stringify(formValues))
  }, [formValues])

  useEffect(() => {
    updateLocale(postcode)
  }, [postcode])

  const updateLocale = async (postcode: string) => {
    if (postcode && postcode.match(/\d{4}-?\d{3}/) !== null) {
      try {
        setload(true)
        let postcodeApi = await Agent.Geo.getPostCode(postcode, 'pt')
        if (!postcodeApi.hasRoute) {
          setError('address.postcode', { type: 'manual', message: 'O código postal não está abrangido. Contacte a empresa.' })
        } else {
          setValue('address.location', postcodeApi.localeName);
          clearErrors('address.postcode')
          // setformValues(prevstate => {
          //   prevstate['address']['location'] = postcodeApi.localeName;
          //   return { ...prevstate }
          // })
        }
      } catch (error) {
        setError('address.postcode', { type: 'manual', message: 'O código postal não existe. Contacte a empresa!' })
      }
      finally {
        setload(false)
      }
    } else {
      setValue('address.location', '');
      // setformValues(prevstate => {
      //   let s = getValues();
      //   s['address']['location'] = "";
      //   return { ...prevstate }
      // })

    }
  }

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <Segment.Group>
        <Segment basic>
          <AddressFields prefix="address"
            register={register}
            loadLocale={load}
            errors={errors} />
        </Segment>
        <Segment basic clearing>
          <Button type="submit" floated="right" positive>
            {address ? "Atualizar endereço" : "Adicionar endereço"}
          </Button>
        </Segment>
      </Segment.Group>
    </Form>
  );
};

export default AddressForm;
