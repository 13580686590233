import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import { toast } from "react-toastify";
import Agent from "../api/agent";
import { addRoute, updateWh } from "../api/CourierRequest";
import { PT_pt } from "../common/util/expressions";
import { LIMIT_BY_PAGE } from "../common/util/globals";
import { IRoute } from "../models/route";
import { ICourier, ICourierEditQuery } from "../models/user";
import { IWarehouse } from "../models/warehouse";
import { RootStore } from "./rootStore";

export default class CourierStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    reaction(
      () => this.predicate.keys(),
      () => {
        this.page = 0;
        this.clearCouriers();
        this.loadCouriers();
      }
    );
  }

  @observable couriers: ICourier[] = [];
  @observable courier: ICourier | null = null;
  @observable couriers_left: ICourier[] = [];
  @observable loading = false;
  @observable submitting = false;
  @observable loadingInitial = false;
  @observable predicate = new Map();
  @observable page = 0;
  @observable courierCount = 0;

  @computed get axiosParams() {
    const params = new URLSearchParams();
    if (!this.predicate.has("limit"))
      params.append("limit", (LIMIT_BY_PAGE * 10).toString());
    params.append('limit', '1000')
    params.append("offset", `${this.page ? this.page * LIMIT_BY_PAGE : 0}`);
    this.predicate.forEach((value, key) => {
      params.append(key, value);
    });
    return params;
  }

  @computed get totalPages() {
    return Math.ceil(this.courierCount / LIMIT_BY_PAGE);
  }

  @computed get getCouriersSliced(): ICourier[] {
    let slicePage = this.couriers.slice(
      this.page * LIMIT_BY_PAGE,
      this.page * LIMIT_BY_PAGE + LIMIT_BY_PAGE
    );
    return slicePage;
  }

  @action setPage = (page: number | string) => {
    this.page = Number(page) - 1;
  };

  @action setInifinityPage = (page: number) => {
    this.page = page;
  };

  /**
   * Método para fazer o load inicial de todas os estafetas no sistema
   * Vai popular a var "couriers"
   */
  @action loadCouriers = async () => {
    this.loadingInitial = true;
    try {
      let params = this.axiosParams;
      if(!params.has("active")) params.set("active", "true")
      const courierEnvelope = await Agent.Couriers.list(params);
      const { couriers, totalCount } = courierEnvelope;
      runInAction(() => {
        this.couriers = [...couriers]
        this.courierCount = totalCount;
      });
    } catch (error) {
      console.log(error);
    } finally {

      this.loadingInitial = false;
    }
  };

  /**
   * Load do estafeta pelo seu id, para ser apresentada detalhadamente
   * Atualiza o estafeta na lista
   * @param courierId estafeta a ser apresentado
   */
  @action loadCourier = async (courierId: string) => {
    this.loading = true;

    let courier: ICourier;
    try {
      courier = await Agent.Couriers.details(courierId);
      runInAction(() => {
        if (courier) {
          this.courier = courier;
        }
        this.loading = false;
      });
      return courier;
    } catch (error) {
      toast.error(PT_pt.errors.submitting);
    } finally {
      this.loading = false;
    }

  };

  /**
   * Adiciona um utilizar estafeta à lista
   * @param user utilizador a adicionar
   */
  @action addCourierUser = (user: ICourier) => {
    runInAction(() => {
      try {
        this.getCourier(user.id)
      } catch (error) {
        this.couriers.push(user);
      }
    });
  };

  /**
   * Método para adicionar rota ao estafeta
   * @param route rota a adicionar
   */
  @action addRoute = async (courierId: string, routeIds: number[]) => {
    this.submitting = true;
    try {
      let promises: Promise<any>[] = [];
      routeIds.forEach(routeId => {
        promises.push(new Promise(() => {
          addRoute(courierId, routeId);
        }))
      })
      Promise.all(promises).finally(() => this.submitting = false);
    } catch (error) {
      toast.error(PT_pt.errors.submitting);
    } finally {
      this.submitting = false;
    }
  };

  @action updateRouteCourier = async (routeId: number, courierId: string) => {
    try {
      this.submitting = true;
      await addRoute(courierId, routeId);
      runInAction(() => {

      })
    } catch (error) {

    } finally {
      this.submitting = false;
    }
  }

  @action updateCourier = async (values: ICourierEditQuery) => {
    try {
      let courier: ICourier = await Agent.User.editCourier(values);
      runInAction(() => {
        this.courier = courier;
        console.log(JSON.stringify(courier))
        let a = this.couriers.filter(x => x.id !== courier.id);
        this.couriers = [...a, courier];
      })
    } catch (error) {

    }
  }

  /**
   * Método para alterar o armazém associado ao estafeta
   * @param courierID Estafeta a atualizar
   * @param whId novo armazem associado
   */
  @action updateWh = async (courierID: string, wh: IWarehouse) => {
    this.loading = true;
    try {
      let courier = this.getCourier(courierID);
      if (courier) await updateWh(courierID, wh.id);
      runInAction(() => {
        if (courier) courier.warehouse = wh;
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action updateCourierWarehouse = (courierId: string, warehouseId: number, warehouseName: string) => {
    if (this.courier && this.courier.id === courierId) {
      this.courier.warehouseId = warehouseId;
      this.courier.warehouseName = warehouseName
    }
  }

  /**
   * Método para obter todas os estafetas não associados a um dado armazém
    * @param whId id do armazem a ser filtrado
   */
  @action filterCouriersWarehouses = async (whId: number) => {
    if (this.couriers.length === 0)
      await this.loadCouriers();
    this.couriers_left = this.couriers
      .filter((x) => x.warehouseId !== whId)
      .slice(
        this.page * LIMIT_BY_PAGE,
        this.page * LIMIT_BY_PAGE + LIMIT_BY_PAGE
      );
  };

  @action clearfilteredCourier = () => {
    this.couriers_left = []
  }

  hasCourier(id: string, couriers: ICourier[]) {
    for (let index = 0; index < couriers.length; index++) {
      const element = couriers[index];
      if (element.id === id) return true;
    }
    return false;
  }

  getCourier = (id: string): ICourier => {
    let filtered = this.couriers.filter(x => x.id === id)

    if (filtered.length > 0) return filtered[0]

    throw new Error("courier not found")
  };

  hasRoute(id: number, routes: IRoute[]) {
    for (let index = 0; index < routes.length; index++) {
      const element = routes[index];
      if (element.id === id) return true;
    }
    return false;
  }

  @action clearCourier = () => {
    this.courier = null;
  };

  @action clearCouriers = () => {
    this.couriers = [];
  };

  @action setPredicate = (predicate: string, value: string | Date) => {
    this.predicate.clear();
    if (predicate !== "all") {
      this.predicate.set(predicate, value);
    }
  };
}
