import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Grid, Header, Item, Segment } from "semantic-ui-react";
import { RootStoreContext } from "../../../../app/stores/rootStore";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import AllRoutesCourier from "./AllRoutesCourier";
import CourierSetWarehouse from "./CourierSetWarehouse";
import EditCourier from "../../../courier/edit/EditCourier";

interface IProps {
  id: string;
}

const CourierDetails: React.FC<RouteComponentProps<IProps>> = ({
  match,
  history,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { courier, loading, loadCourier, clearCouriers } =
    rootStore.courierStore;
  const { openModal } = rootStore.modalStore;
  const { setPredicate: routeSetPredicate } = rootStore.routeStore;

  useEffect(() => {
    loadCourier(match.params.id);
    return function cleanup() {
      routeSetPredicate("all", "");
      clearCouriers();
    };
  }, [loadCourier, match.params.id, routeSetPredicate]);

  if (loading || !courier)
    return <LoadingComponent content="A carregar estafeta" />;

  return (
    <Segment>
      <Button
        icon="left arrow"
        content="Voltar"
        labelPosition="left"
        onClick={() => history.goBack()}
      />
      <Segment inverted>
        <Grid>
          <Grid.Row color={courier.active ? "black" : "red"}>
            <Grid.Column width={6}>
              <Item>
                <Item.Header><h2><b>{courier.displayname}</b></h2></Item.Header>
                <Item.Meta>{courier.username}</Item.Meta>
                <Item.Description>
                </Item.Description>
              </Item>
            </Grid.Column>
            <Grid.Column width={2}>
              <Button
                onClick={() => {
                  openModal(<EditCourier courier={courier} />)
                }}
                content="Editar"
              />
            </Grid.Column>
            <Grid.Column width={2} color={courier.transhipment ? "green" : "black"}>
              Transbordo? <p />
              {courier.transhipment ? "Sim" : "Não"}
            </Grid.Column>
            <Grid.Column>
              Ativo?<p />
              {courier.active ? "Sim" : "Não"}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <Segment.Group>
        <Segment>
          <Header size="small" content="Armazém" />
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column width={4}>
                {courier.warehouseName ? (
                  <a href={`/warehouse/${courier.warehouseId}`}>
                    {courier.warehouseId} (
                    {courier.warehouseName})
                  </a>
                ) : (
                  <Fragment>
                    Não está associado a nenhum armazém.
                  </Fragment>
                )}
              </Grid.Column>
              <Grid.Column width={4} textAlign="left">
                <Button
                  positive
                  onClick={() => openModal(<CourierSetWarehouse courierId={courier.id} courierWarehouseId={courier.warehouseId} />)}>
                  Atualizar Armazém
                </Button>

              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment>
          <Header size="small" content="Rotas" />
          <AllRoutesCourier routesName={courier.routeNames} />
        </Segment>
      </Segment.Group>
    </Segment>
  );
};

export default observer(CourierDetails);
