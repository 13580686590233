import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react'
import { Form as FinalForm } from "react-final-form";
import { Form, Segment, Header, Table, Checkbox, Grid, Button } from 'semantic-ui-react';
import { RootStoreContext } from '../../../app/stores/rootStore';

interface IProps {
    updateFunc: (warehouseId: number) => Promise<any>
    currentWarehouse : number | null;
}

const WarehouseSelectableList: React.FC<IProps> = ({ updateFunc, currentWarehouse }) => {

    const rootStore = useContext(RootStoreContext);
    const { closeModal } = rootStore.modalStore;
    const { submitting, loading, loadWarehouses, warehouses } = rootStore.warehouseStore;
    const [selectedWarehouse, setSelectedWarehouse] = useState<number>(currentWarehouse ?? 1)


    useEffect(() => {
        loadWarehouses()
        return () => {
        }
    }, [loadWarehouses]);

    return (
        <FinalForm
            onSubmit={async () => {
                await updateFunc(selectedWarehouse);
                closeModal();
            }}
            loading={loading}
            render={({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                    <Segment>
                        <Header size="small" content="Selecione a(s) rota(s)*" />
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        Armazém
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {warehouses.map((r) => (
                                    <Table.Row key={r.id}>
                                        <Table.Cell>
                                            <Checkbox radio checked={selectedWarehouse === r.id} onChange={() => setSelectedWarehouse(r.id)} label={r.name} key={r.id} />
                                        </Table.Cell>
                                    </Table.Row>
                                ))
                                }
                            </Table.Body>
                        </Table>
                    </Segment>
                    <Grid>
                        <Grid.Column>
                            <Button
                                type="submit"
                                color="green"
                                floated="right"
                                loading={submitting}
                                disabled={submitting}
                                positive
                            >
                                Salvar
                            </Button>
                            <Button floated="right" onClick={() => closeModal()}>
                                Voltar
                            </Button>
                        </Grid.Column>
                    </Grid>
                </Form>
            )}
        ></FinalForm>
    );
}

export default observer(WarehouseSelectableList)