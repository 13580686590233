import { observer } from "mobx-react-lite";
import { Container } from "semantic-ui-react";
import CourierList from "./CourierList";
import RouteList from "./RouteList";
import WarehouseList from "./WarehouseList";

interface IProps {
  type: string;
}

const RCWList: React.FC<IProps> = ({ type }) => {
  return (
    <Container>
      {type == "warehouses" && (
        <div>
          <WarehouseList />
        </div>
      )}
      {type == "routes" && (
        <div style={{ paddingLeft: 7, paddingRight: 7 }}>
          <RouteList />
        </div>
      )}
      {type == "couriers" && (
        <div>
          <CourierList />
        </div>
      )}
    </Container>
  );
};

export default observer(RCWList);
