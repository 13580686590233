import React, { Fragment, useContext, useEffect } from "react";
import { Container } from "semantic-ui-react";
import NavBar from "../../features/nav/NavBar";
import { observer } from "mobx-react-lite";
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
import HomePage from "../../features/home/HomePage";
import NotFound from "./NotFound";
import { ToastContainer } from "react-toastify";
import { RootStoreContext } from "../stores/rootStore";
import LoginForm from "../../features/user/LoginForm";
import LoadingComponent from "./LoadingComponent";
import ModalContainer from "../common/modals/ModalContainer";
import TransportDashboard from "../../features/transports/dashboard/TransportDashboard";
import TransportDetails from "../../features/transports/details/TransportDetails";
import CreateTransport from "../../features/transports/create/CreateTransport";
import PrivateRoute from "./PrivateRoute";
import CompanyDashboard from "../../features/company/dashboard/CompanyDashboard";
import CompanyDetails from "../../features/company/details/CompanyDetails";
import { AuthorizationRoles } from "../models/user";
import ReportDashboard from "../../features/report/adminDashboard/ReportDashboard";
import StatusDashboard from "../../features/transports/status/StatusDashboard";
import ZoneDashboard from "../../features/zones/dashboard/ZoneDashboard";
import WareHouseDashboard from "../../features/warehouse/WareHouseDashboard";
import CSVIntegration from "../../features/transports/csvload/CSVIntegration";
import RCWDashboard from "../../features/routes/dashboard/RCWDashboard";
import CourierDetails from "../../features/routes/details/courier/CourierDetails";
import WarehouseDetails from "../../features/routes/details/warehouse/WarehouseDetails";
import RouteDetails from "../../features/routes/details/route/RouteDetails";
import CreateTransportAdmin from "../../features/administration/transport/create/CreateTransportAdmin";

const App: React.FC<RouteComponentProps> = ({ location }) => {
  const rootStore = useContext(RootStoreContext);
  const { setAppLoaded, token, appLoaded } = rootStore.commonStore;
  const { getUser } = rootStore.userStore;
  const { Admin, Company, Supplier, Courier } = AuthorizationRoles;
  useEffect(() => {
    if (token) {
      getUser().finally(() => {
        setAppLoaded();
      });
    } else {
      setAppLoaded();
    }
  }, [getUser, setAppLoaded, token]);

  if (!appLoaded) {
    return <LoadingComponent content="Loading app" />;
  }

  return (
    <Fragment>
      <ModalContainer />
      <ToastContainer position="bottom-right" />
      <Route exact path="/" component={HomePage} />
      <Route
        path={"/(.+)"}
        render={() => (
          <Fragment>
            <NavBar />
            <Container style={{ marginTop: "7em" }}>
              <Switch>
                <PrivateRoute
                  exact
                  path="/transports"
                  roles={[Company]}
                  component={TransportDashboard}
                />
                <PrivateRoute
                  exact
                  path="/transport/search"
                  roles={[Company, Admin, Courier]}
                  component={StatusDashboard}
                />
                <PrivateRoute
                  exact
                  path="/transport/create"
                  roles={[Company, Supplier]}
                  component={CreateTransport}
                />
                <PrivateRoute
                  exact
                  path="/transport/csvload"
                  roles={[Company, Supplier]}
                  component={CSVIntegration}
                />
                <PrivateRoute
                  path="/transport/:id"
                  component={TransportDetails}
                />
                <PrivateRoute
                  exact
                  path="/companies"
                  roles={[Admin]}
                  component={CompanyDashboard}
                />
                <PrivateRoute
                  exact
                  path="/company/:id"
                  roles={[Admin, Company]}
                  component={CompanyDetails}
                />
                <PrivateRoute
                  exact
                  path="/report"
                  roles={[Admin]}
                  component={ReportDashboard}
                />
                <PrivateRoute
                  exact
                  path="/warehouse"
                  roles={[Admin]}
                  component={WareHouseDashboard}
                />
                <PrivateRoute
                  exact
                  path="/zones"
                  roles={[Admin]}
                  component={ZoneDashboard}
                />
                <PrivateRoute
                  exact
                  path="/rcs"
                  component={RCWDashboard}
                />
                <PrivateRoute
                  exact
                  path="/admin/transport/create"
                  component={CreateTransportAdmin}
                />
                <PrivateRoute
                  exact
                  path="/route/:id"
                  roles={[Admin, Company]}
                  component={RouteDetails}
                />
                <PrivateRoute
                  exact
                  path="/courier/:id"
                  roles={[Admin, Company]}
                  component={CourierDetails}
                />
                <PrivateRoute
                  exact
                  path="/warehouse/:id"
                  roles={[Admin, Company]}
                  component={WarehouseDetails}
                />
                <PrivateRoute path="/login" component={LoginForm} />
                <Route component={NotFound} />
              </Switch>
            </Container>
          </Fragment>
        )}
      />
    </Fragment>
  );
};

export default withRouter(observer(App));
