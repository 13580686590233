import React, { Fragment, useContext } from "react";
import { roles } from "../../models/user";
import { RootStoreContext } from "../../stores/rootStore";

interface IProps {
  roles?: roles;
  component: any;
}

const IsAuthorized: React.FC<IProps> = ({ roles, component: Component }) => {
  const rootStore = useContext(RootStoreContext);
  const { hasRole } = rootStore.userStore;

  return roles && !hasRole(roles) ? null : <Fragment>{Component}</Fragment>;
};

export default IsAuthorized;
