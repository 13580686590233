import { AddressFormValues, IAddress, IAddressFormValues } from "./address";

export interface ICustomer {
    customerGuid: string;
    displayName: string;
    companyName: string;
    phoneNumber: string;
    mobileNumber: string;
    email?: string;
    vatNumber: string;
    addresses: IAddress[];
}

export interface ICustomerFormValues extends Partial<ICustomer> {
    address: IAddressFormValues;
}
export class CustomerFormValues implements ICustomerFormValues {
    customerGuid: string = "";
    displayName: string = "";
    companyName: string = "";
    phoneNumber: string = "";
    mobileNumber: string = "";
    email?: string;
    vatNumber: string = "";
    address: IAddressFormValues = new AddressFormValues();
    addressTo: IAddressFormValues = new AddressFormValues();

    constructor (init?: ICustomer) {
        Object.assign(this, init);
    }
}

export interface ICustomerEnvelope {
    customers: ICustomer[],
    customerCount: number
}