import React from "react";
import { Table } from "semantic-ui-react";

const TransportsHeader = () => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Destinatário</Table.HeaderCell>
        <Table.HeaderCell>Morada</Table.HeaderCell>
        <Table.HeaderCell>Código-Postal</Table.HeaderCell>
        <Table.HeaderCell>Consignado</Table.HeaderCell>
        <Table.HeaderCell textAlign="center">Cobrança</Table.HeaderCell>
        <Table.HeaderCell textAlign="center">Volumes</Table.HeaderCell>
        <Table.HeaderCell textAlign="center">Peso Total</Table.HeaderCell>
        <Table.HeaderCell>Etiquetas</Table.HeaderCell>
        <Table.HeaderCell>Manifesto</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

export default TransportsHeader;
