import React from "react";
import { useContext } from "react";
import { Table } from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import TransportsRow from "./TransportsRow";

const TransportsBody = () => {
  const rootStore = useContext(RootStoreContext);
  const { csvTransportEnvelope } = rootStore.transportStore;
  return (
    <Table.Body>
      {csvTransportEnvelope!.transports.map((transport) => (
        <TransportsRow key={transport.transportGuid} transport={transport} />
      ))}
    </Table.Body>
  );
};

export default TransportsBody;
