import { observer } from "mobx-react-lite";
import React, { Fragment, useContext } from "react";
import { Segment, Button, Message } from "semantic-ui-react";
import IsAuthorized from "../../../app/common/authorization/IsAuthorized";
import { AuthorizationRoles } from "../../../app/models/user";
import { RootStoreContext } from "../../../app/stores/rootStore";
import RegisterForm from "../../user/RegisterForm";
import UserDetails from "../../user/UserDetails";

const CompanyEmployee = () => {
  const rootStore = useContext(RootStoreContext);
  const { company } = rootStore.companyStore;
  const { openModal } = rootStore.modalStore;

  return (
    <Segment.Group>
      {company && company.addresses.length > 0 ?
      <Fragment>

      <IsAuthorized
        roles={[AuthorizationRoles.Admin]}
        component={
          <Segment clearing>
            <Button
              floated="right"
              onClick={() => openModal(<RegisterForm registerType="company" />)}
              content="Adicionar funcionário"
              color="teal"
            />
          </Segment>
        }
      />
      <Segment clearing>
        {company!.appUsers && company!.appUsers.length > 0 ? (
          <UserDetails userList={company!.appUsers!} />
        ) : (
          <Message warning>
            <Message.Header>Não existem funcionários!</Message.Header>
          </Message>
        )}
      </Segment>
      </Fragment>
      :
      <Segment>
        
        <Message error>
            <Message.Header>Não é possível adicionar funcionários sem endereços!</Message.Header>
          </Message>
      </Segment>}
    </Segment.Group>
  );
};

export default observer(CompanyEmployee);
