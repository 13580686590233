import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useState } from "react";
import { Header, Icon, Item, Menu, Table } from "semantic-ui-react";
import WarningMessage from "../../../app/common/messages/WarningMessage";
import { ITransport, ITransportSummary } from "../../../app/models/transport";
import { RootStoreContext } from "../../../app/stores/rootStore";
import TransportListItem from "./TransportListItem";

const TransportList: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { transportsBy} = rootStore.transportStore;
  // const [loadingNext, setLoadingNext] = useState(false)
  // const handleGetNext = () => {
  //   setLoadingNext(true);
  //   setPage(page + 1);
  //   loadTransportsPagination().then(() => setLoadingNext(false));
  // };
  return (
    <Fragment>
      {transportsBy.length === 0 ? (
        <WarningMessage header="Não encontrado" message="Não existem transportes para apresentar com os critérios selecionados!" />
      ) : (
        <Item.Group>
          {transportsBy.map((transport: ITransportSummary) => (
            <TransportListItem
              key={transport.transportGuid}
              transport={transport}
            />
          ))}
        </Item.Group>
        // <Table selectable>
        //   <Table.Header>
        //     <Table.Row>
        //       <Table.HeaderCell>Id</Table.HeaderCell>
        //       <Table.HeaderCell>Destinatário</Table.HeaderCell>
        //       <Table.HeaderCell>Código-Postal</Table.HeaderCell>
        //       <Table.HeaderCell># Volumes</Table.HeaderCell>
        //     </Table.Row>
        //   </Table.Header>
        //   <Table.Body>
        //     {transportsBy.map((transport: ITransportSummary) => (
        //       <TransportListItem
        //         key={transport.transportGuid}
        //         transport={transport}
        //       />
        //     ))}

        //   </Table.Body>
        //   <Table.Footer>
        //     <Table.Row>
        //       <Table.HeaderCell colSpan="4">
        //         <Menu floated="right" pagination>
        //           <Menu.Item icon>
        //             <Icon name='chevron left' />
        //           </Menu.Item>
        //           {Array.from({ length: totalPages }, (_, i) => i + 1).map(
        //             x => <Menu.Item active={x == page + 1} >{x}</Menu.Item>
        //           )}
        //           <Menu.Item icononClick={nextPage}>
        //             <Icon name='chevron right' />
        //           </Menu.Item>
        //         </Menu>
        //       </Table.HeaderCell>
        //     </Table.Row>
        //   </Table.Footer>
        // </Table>
      )}
    </Fragment>
  );
};

export default observer(TransportList);
