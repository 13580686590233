import { observer } from "mobx-react-lite";
import React, { Fragment } from "react";
import { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { Button, Container, Dropdown, Menu, Image } from "semantic-ui-react";
import IsAuthorized from "../../app/common/authorization/IsAuthorized";
import { AuthorizationRoles } from "../../app/models/user";
import { RootStoreContext } from "../../app/stores/rootStore";

export const NavBar: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { user, logout } = rootStore.userStore;
  const { company } = rootStore.companyStore;
  return (
    <Menu fixed="top" inverted stackable>
      <Container>
        <Menu.Item header as={NavLink} exact to="/">
          <img src="/assets/favicon.png" alt="logo" />
        </Menu.Item>
        <Menu.Item
          name="Pesquisar Transporte"
          as={NavLink}
          to="/transport/search"
        />
        <IsAuthorized
          component={
            <Fragment>
              <Menu.Item name="Empresas" as={NavLink} to="/companies" />
              <Menu.Item name="Listagens" as={NavLink} to="/report" />
              <Menu.Item name="Armazém" as={NavLink} to="/warehouse" />
              <Menu.Item name="Picking" as={NavLink} to="/rcs" />
              <Menu.Item>
                <Button
                  as={NavLink}
                  to="/admin/transport/create"
                  positive
                  content="Criar Envio"
                />
              </Menu.Item>
            </Fragment>
          }
          roles={[AuthorizationRoles.Admin]}
        />
        <IsAuthorized
          component={
            <Fragment>
              <Menu.Item name="Meus envios" as={NavLink} to="/transports" />
              <Menu.Item>
                <Button
                  as={NavLink}
                  to="/transport/create"
                  positive
                  content="Criar Envio"
                />
              </Menu.Item>
              {/* <Menu.Item>
                <Button
                  as={NavLink}
                  to="/transport/csvload"
                  color="yellow"
                  content="Carregar ficheiro"
                />
              </Menu.Item> */}
            </Fragment>
          }
          roles={[AuthorizationRoles.Company]}
        />
        {user && (
          <Menu.Item position="right">
            <Image avatar spaced="right" src={"/assets/user.png"} />
            <Dropdown pointing="top left" text={user.displayname}>
              <Dropdown.Menu>
                <IsAuthorized
                  component={
                    <Dropdown.Item
                      as={Link}
                      to={`/company/${company?.guid}`}
                      text="Meu perfil"
                      icon="user"
                    />
                  }
                  roles={[AuthorizationRoles.Company]}
                />
                <Dropdown.Item onClick={logout} text="Logout" icon="power" />
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Item>
        )}
      </Container>
    </Menu>
  );
};

export default observer(NavBar);
