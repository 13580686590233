import { format } from "date-fns/esm";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Header, Segment } from "semantic-ui-react";
import WarningMessage from "../../app/common/messages/WarningMessage";
import { RootStoreContext } from "../../app/stores/rootStore";
import { pt } from "date-fns/locale";

const FuelFeeHistory: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { fuelFees, firstLoad, getCurrentFee } = rootStore.fuelFeeStore;

  if (!firstLoad) return <WarningMessage header="Ainda a carregar" />;
  return (
    <Segment>
      <Header as="h3">
        <Header.Content>Valor atual:</Header.Content>
        <Header.Subheader>{getCurrentFee} €/L</Header.Subheader>
      </Header>
      <Header as="h4">
        Histórico
      </Header>
      {fuelFees.slice(0,5).map((v, i) => (
        <div key={`fee_${i}`} style={{ textAlign: "center" }}>
          {v.fuelFeeValue} €/L - {format(new Date(v.creationDate), "dd/MM/yyyy HH:mm", {locale: pt})}
        </div>
      ))}
    </Segment>
  );
};

export default observer(FuelFeeHistory);
