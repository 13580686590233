import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Segment } from "semantic-ui-react";
import AddressInfo from "../../../app/common/address/AddressInfo";
import {
  getAddress,
  getMainAddress,
} from "../../../app/dataAccess/AddressData";
import { ICompany } from "../../../app/models/company";
import { ICustomer } from "../../../app/models/customer";
import { RootStoreContext } from "../../../app/stores/rootStore";
import CompanyInfoDetails from "../../company/common/CompanyInfoDetails";
import CustomerDetails from "../../customer/details/CustomerDetails";

interface IProps {
  user?: boolean;
  addressType: string;
  selectedAddressGuid?: string;
}
const CreateTransportShipInfo: React.FC<IProps> = ({
  user,
  addressType,
  selectedAddressGuid,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { getCustomerType } = rootStore.transportStore;
  const { getUserAddress } = rootStore.userStore;
  const [selected, setSelected] = useState<ICompany | ICustomer | undefined>(
    undefined
  );
  useEffect(() => {
      setSelected(getCustomerType(addressType));
    }, [setSelected, getCustomerType, addressType, selectedAddressGuid]);
    
    if (selected) {
      return (
        <Segment.Group horizontal>
        {"customerGuid" in selected ? (
          <CustomerDetails key={selected.customerGuid} customer={selected} />
          ) : (
            <CompanyInfoDetails company={selected!} />
            )}
        <AddressInfo
          address={
            user
            ? getUserAddress
            : selectedAddressGuid
            ? getAddress(selected.addresses, selectedAddressGuid)
            : getMainAddress(selected.addresses)
          }
          />
      </Segment.Group>
    );
  } else {
    return null;
  }
};

export default observer(CreateTransportShipInfo);
