import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Button, Segment } from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import CreateZone from "../create/CreateZone";

const ZoneSidebar: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { openModal } = rootStore.modalStore;
  return (
    <Segment basic>
      <Button
      fluid
        color="red"
        content="Nova zona"
        onClick={() => openModal(<CreateZone />)}
      />
    </Segment>
  );
};

export default observer(ZoneSidebar);
