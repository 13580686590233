import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import Agent from "../api/agent";
import {
  ICsvIntegrationEnvelope,
  ITransport,
  ITransportFormValues,
  ITransportsResume,
  ITransportSummary,
  TransportsResume,
} from "../models/transport";
import { RootStore } from "./rootStore";
import { v4 as uuid } from "uuid";
import { ICustomer } from "../models/customer";
import { ICompany } from "../models/company";
import { toast } from "react-toastify";
import { LIMIT_BY_PAGE } from "../common/util/globals";

export default class TransportStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;

    // React to filters
    reaction(
      () => this.predicate.keys(),
      () => {
        this.page = 0;
        this.transportRegistry.clear();
        this.loadTransports();
      }
    );
  }

  @observable transportRegistry = new Map();
  @observable transports: ITransport[] = [];
  @observable transport: ITransport | null = null;
  @observable loadingInitial = false;
  @observable loading = false;
  @observable transportCount = 0;
  @observable page = 0;
  @observable predicate = new Map();
  @observable transportCreate = new Map(); // store the customer from, to
  @observable transportDebit: string = "";
  @observable deleting = false;
  @observable csvTransportEnvelope: ICsvIntegrationEnvelope | undefined =
    undefined;

  @observable transportPagination = new Map<number, ITransportSummary[]>();

  @computed get transportsBy(): ITransportSummary[] {
    return Array.from(this.transportRegistry.values());
  }

  @computed get totalPages() {
    return Math.ceil(this.transportCount / LIMIT_BY_PAGE);
  }

  @computed get csvTotals(): ITransportsResume {
    let resume = new TransportsResume(0, 0, 0, 0);
    if (this.csvTransportEnvelope === undefined) return resume;

    resume.totalTransports = this.csvTransportEnvelope.transportsCount;
    resume.totalErrors = this.csvTransportEnvelope.totalErrors;
    resume.totalVolumes = this.csvTransportEnvelope.totalVolumes;
    resume.totalWeight = this.csvTransportEnvelope.totalWeight;

    return resume;
  }

  @computed get hasCsvTransports() {
    return this.csvTransportEnvelope !== undefined;
  }

  @computed get getCsvGuids(): string[] {
    return this.csvTransportEnvelope
      ? this.csvTransportEnvelope.transports.map((t) => t.transportGuid)
      : [];
  }

  @action cleanCsv = () => {
    this.csvTransportEnvelope = undefined;
  };

  @action setPage = (page: number) => {
    this.page = page;
  };

  // @action nextPage = () => {
  //   this.page = Math.min(this.page + 1, this.totalPages)
  //   if(!this.transportPagination.get(this.page)) {
  //     this.loadTransportsPagination();
  //   }
  // }
  // @action previousage = () => {
  //   this.page = Math.max(this.page - 1, 0)
  // }

  @computed get axiosParams() {
    const params = new URLSearchParams();
    params.append("limit", LIMIT_BY_PAGE.toString());
    params.append("offset", `${this.page ? this.page * LIMIT_BY_PAGE : 0}`);
    this.predicate.forEach((value, key) => {
      if (key === "startDate" || key === "endDate") {
        if (value) params.append(key, value.toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  @action clearTransport = () => {
    this.transportCreate.clear();
  };

  @action clearTransportRegistry = () => {
    this.transportRegistry.clear();
  };

  @action setDebit = (vatNumber: string) => {
    if (vatNumber === "")
      this.transportDebit = this.rootStore.companyStore.company!.vatNumber;
    else this.transportDebit = vatNumber;
  };

  @computed get transportFromTo() {
    return this.transportCreate.get(this.rootStore.customerStore.selectType);
  }

  @computed get getSelectedCompany() {
    return this.rootStore.companyStore.company;
  }

  @action getCustomerType = (type: string) => {
    return this.transportCreate.get(type);
  };
  @action addCustomer = (customer: ICustomer | ICompany) => {
    this.transportCreate.set(this.rootStore.customerStore.selectType, customer);
  };
  @action setPredicate = (predicate: string, value: string | Date) => {
    this.predicate.clear();
    if (predicate !== "all") {
      this.predicate.set(predicate, value);
    }
  };

  @action setMultiplePredicate = (
    predicates: string[],
    value: string[] | Date[]
  ) => {
    this.predicate.clear();
    predicates.map((predicate, i) => this.predicate.set(predicate, value[i]));
  };

  @action createTransport = async (transport: ITransportFormValues) => {
    this.loading = true;
    if (
      transport.addressFrom?.addressDisplayName?.length === 0 ||
      transport.addressTo?.addressDisplayName?.length === 0
    ) {
      this.loading = false;
      toast.error("Selecionar remetente & destinatário!");
      throw new Error();
    }
    transport.transportGuid = uuid();

    transport.volumes?.map((volume) => (volume["volumeGuid"] = uuid()));
    transport.numberOfVolumes = transport.volumes?.length;
    transport.debit = this.transportDebit;

    try {
      await Agent.Transport.create(
        transport,
        this.rootStore.companyStore.company!.guid
      );
      runInAction(() => {
        this.loading = false;
      });
      return transport;
    } catch (error) {
      console.log(error);
      this.loading = false;
    }
  };

  @action createTransportCsv = async (file: FormData) => {
    this.loading = true;
    try {
      let csvEnvelope = await Agent.Transport.createFile(file);
      console.log(csvEnvelope);
      runInAction(() => {
        this.csvTransportEnvelope = csvEnvelope;
        this.loading = false;
      });
    } catch (error) {
      this.loading = false;
    }
  };

  // @action loadTransportsPagination = async () => {
  //   let page = this.page;
  //   this.loadingInitial = true;
  //   let params = this.axiosParams;
  //   params.set('offset', (page * LIMIT_BY_PAGE).toString() );
  //   if (this.getSelectedCompany && this.rootStore.userStore.hasRole(["Admin"]))
  //     params.append("companyGuid", this.getSelectedCompany.guid);
  //   try {
  //     const transportEnvelope = await Agent.Transport.list(params);
  //     const { transports, transportCount } = transportEnvelope;
  //     runInAction(() => {
  //       this.transportPagination.set(page, []);

  //       transports.forEach((transport) => {
  //         this.transportPagination.get(page)?.push(transport);
  //       });
  //       this.transportCount = transportCount;
  //       this.loadingInitial = false;
  //     });
  //   } catch (error) {
  //     runInAction(() => {
  //       console.log(error);
  //     });
  //   }
  // };

  @action loadTransports = async () => {
    this.loadingInitial = true;
    let params = this.axiosParams;
    if (this.getSelectedCompany && this.rootStore.userStore.hasRole(["Admin"]))
      params.append("companyGuid", this.getSelectedCompany.guid);
    try {
      const transportEnvelope = await Agent.Transport.list(params);
      const { transports, transportCount } = transportEnvelope;
      runInAction(() => {
        transports.forEach((transport) => {
          this.transportRegistry.set(transport.transportGuid, transport);
        });
        this.transportCount = transportCount;
        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction(() => {
        console.log(error);
      });
    }
  };

  @action forceLoadTransport = async (id: string) => {
    this.transportRegistry.delete(id);
    await this.loadTransport(id);
  };

  @action loadTransport = async (id: string) => {
    let transport = this.getTransport(id);

    if (transport) {
      this.transport = transport;
      return transport;
    } else {
      try {
        this.loadingInitial = true;
        transport = await Agent.Transport.details(id);
        runInAction(() => {
          this.transport = transport;
          this.transportRegistry.set(transport.transportGuid, transport);
          this.loadingInitial = false;
        });
        return transport;
      } catch (error) {
        console.log(error);
        runInAction(() => {
          this.loadingInitial = false;
        });
      }
    }
  };

  getTransport = (id: string) => {
    return this.transportRegistry.get(id);
  };

  @action changePrintLabelStatus = async (transportsGuid: string[]) => {
    try {
      await Agent.Transport.printLabelStatus(transportsGuid);
      this.transport!.printLabel = true;
      this.transportRegistry.set(this.transport?.transportGuid, this.transport);
    } catch (error) {
      throw new Error();
    }
  };

  @action changePrintManifestStatus = async (transportsGuid: string[]) => {
    try {
      await Agent.Transport.printManifestStatus(transportsGuid);
    } catch (error) {
      throw new Error();
    }
  };

  @action deleteTransport = async (transportGuid: string) => {
    try {
      this.deleting = true;
      await Agent.Transport.delete(transportGuid);
      runInAction(() => {
        this.forceLoadTransport(transportGuid);
        // this.transport!.status.push({
        //   transportGuid: transportGuid,
        //   statusName: "canceled",
        //   modificationDate: new Date(),
        // });
        // this.transport!.status.reverse();
        this.deleting = false;
      });
    } catch (error) {
      this.deleting = false;
    }
  };
}
