import React, { Fragment } from "react";
import { Field } from "react-final-form";
import { Grid, Header } from "semantic-ui-react";
import { AuthorizationRoles } from "../../models/user";
import IsAuthorized from "../authorization/IsAuthorized";
import TextInput from "./TextInput";
import {
  composeValidators,
  isEmail,
  maxLength,
  minLength,
  required,
} from "./Validators";

interface IProps {
  create?: boolean;
}

const UserFields: React.FC<IProps> = ({ create }) => {
  return (
    <Fragment>
      <Grid padded>
        <Grid.Column width={8}>
          <Header size="small" content="Nome:" />
          <Field
            placeholder="Nome"
            name="displayname"
            validate={composeValidators(minLength(3), maxLength(50), required)}
          >
            {(props) => <TextInput {...props} />}
          </Field>
          <Header size="small" content="Email:" />
          <Field
            disabled={!create}
            name="email"
            placeholder="Email"
            validate={composeValidators(required, isEmail)}
          >
            {(props) => <TextInput {...props} />}
          </Field>
        </Grid.Column>
        <Grid.Column width={8}>
          <Header size="small" content="Utilizador:" />
          <Field
            disabled={!create}
            name="username"
            placeholder="Utilizador"
            validate={composeValidators(required)}
          >
            {(props) => <TextInput {...props} />}
          </Field>
          <IsAuthorized
            roles={[AuthorizationRoles.Admin]}
            component={
              <Fragment>
                <Header size="small" content="Password:" />
                <Field
                  name="password"
                  placeholder="Password"
                  type="password"
                  validate={!create ? undefined : composeValidators(required)}
                >
                  {(props) => <TextInput {...props} />}
                </Field>
                <Field
                  name="passwordConfirm"
                  placeholder="Repetir Password"
                  type="password"
                  validate={!create ? undefined : composeValidators(required)}
                >
                  {(props) => <TextInput {...props} />}
                </Field>
              </Fragment>
            }
          />
        </Grid.Column>
      </Grid>
    </Fragment>
  );
};

export default UserFields;
