import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { Field, Form as FinalForm } from "react-final-form";
import { Button, Form, Grid, Header, Segment } from "semantic-ui-react";
import { RootStoreContext } from "../../../../app/stores/rootStore";
import {
  composeValidators,
  exactLength,
  greaterThanOrEqual,
  isNumber,
  matchPostcodes,
  required,
} from "../../../../app/common/form/Validators";
import TextInput from "../../../../app/common/form/TextInput";
import { PostCodeRangeFormValues } from "../../../../app/models/geo";
import { toast } from "react-toastify";

const AddPostalRange: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { closeModal } = rootStore.modalStore;
  const { submitting, route, addPostCodeRange } = rootStore.routeStore;
  const re = /(?<numCode>\d{4})-?(?<b_ext>\d{3})\/?(?<e_ext>\d{3})?/g;
  return (
    <FinalForm
      onSubmit={async (values: PostCodeRangeFormValues) => {
        console.log('values', values.all)
        let ms =[...values.all.matchAll(re)]
        console.log(ms)
        let updated = 0
        console.log('rota!:',route?.name)
        for (const iterator of ms) {          
          updated += await addPostCodeRange(route!.name, {NumCode : iterator[1], ExtCodeBegin: iterator[2], ExtCodeEnd: iterator[3] ?? iterator[2], all : ""}) ?? 0;
        }

        toast.info(`Atualizado ${updated} CPs`);
        // closeModal()
      }}
      initialValues={new PostCodeRangeFormValues}
      render={({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Segment.Group>
            <Segment.Group horizontal>
              <Segment>
                <Header size="small" content="Início do intervalo*" />
                <Field
                  name="all"
                  placeholder="XXXX-000/999+YYYY-000/999"
                  validate={composeValidators(
                    required,
                    matchPostcodes
                  )}
                >
                  {(props) => <TextInput {...props} />}
                </Field>
                {/* <Field
                  name="NumCode"
                  placeholder="XXXX"
                  maxLength={4}
                  validate={composeValidators(
                    required,
                    exactLength(4),
                    isNumber,
                    greaterThanOrEqual(0)
                  )}
                >
                  {(props) => <TextInput {...props} />} */}
                {/* </Field> */}
              </Segment>
            </Segment.Group>
            {/* <Segment.Group horizontal>
              <Segment>
                <Header
                  size="small"
                  content="Código postal extensão - início*"
                />
                <Field
                  name="ExtCodeBegin"
                  placeholder="XXX"
                  maxLength={4}
                  validate={composeValidators(
                    required,
                    exactLength(3),
                    isNumber,
                    greaterThanOrEqual(0)
                  )}
                >
                  {(props) => <TextInput {...props} />}
                </Field>
                <Header size="small" content="Código postal final - fim*" />
                <Field
                  name="ExtCodeEnd"
                  placeholder="XXX"
                  maxLength={3}
                  validate={composeValidators(
                    required,
                    exactLength(3),
                    isNumber,
                    greaterThanOrEqual(0)
                  )}
                >
                  {(props) => <TextInput {...props} />}
                </Field>
              </Segment>
            </Segment.Group> */}
          </Segment.Group>
          <Grid>
            <Grid.Column>
              <Button
                type="submit"
                color="green"
                floated="right"
                loading={submitting}
                disabled={submitting}
                positive
              >
                Salvar
              </Button>
              <Button floated="right" onClick={() => closeModal()}>
                Voltar
              </Button>
            </Grid.Column>
          </Grid>
        </Form>
      )}
    ></FinalForm>
  );
};

export default observer(AddPostalRange);
