import { format } from "date-fns/esm";
import React from "react";
import { Header, Icon, Segment } from "semantic-ui-react";
import { ITransport } from "../../../app/models/transport";
import TransportRefundInfo from "./TransportRefundInfo";
import { pt } from "date-fns/locale";
interface IProps {
  transport: ITransport;
}

interface IVolumeIcon {
  readonly standard?: "box";
  readonly windshield?: "window maximize";
  readonly oilField?: "tint";
  readonly pallet?: "pallet";
  readonly lateral_door?: "window restore";
  readonly oculus_glass?: "car";
}

const volumeTypeIcon: IVolumeIcon = {
  standard: "box",
  windshield: "window maximize",
  oilField: "tint",
  pallet: "pallet",
  lateral_door: "window restore",
  oculus_glass: "car",
};

const TransportInfo: React.FC<IProps> = ({ transport }) => {
  return (
    <Segment.Group horizontal>
      <Segment>
        <Header content="Informação do pedido" as="h2" />
        <Header>
          <Header.Content>N.º Guia:</Header.Content>
          <Header.Subheader>{transport.id}</Header.Subheader>
        </Header>
        <Header>
          <Header.Content>Data de criação:</Header.Content>
          <Header.Subheader>
            {format(
             new Date(transport.creationDate),
              "dd 'de' MMMM 'de' y', às' HH:mm",
              {locale: pt}
            )}
          </Header.Subheader>
        </Header>
        <Header>
          <Header.Content>Tipo de serviço:</Header.Content>
          <Header.Subheader>{transport.serviceType}</Header.Subheader>
        </Header>
      </Segment>
      <Segment>
        <Header content="Detalhes do envio" as="h2" />
        <Header>
          <Header.Content>Total de volumes</Header.Content>
          {transport.volumes.map((volume) => (
            <Header.Subheader key={volume.volumeGuid}>
              <Icon name={volumeTypeIcon[volume.volumeType]} />
              {volume.length} x {volume.width} x {volume.height} cm -{" "}
              {volume.weight}kg
            </Header.Subheader>
          ))}
        </Header>
        <Header>
          <Header.Content>Descrição dos produtos</Header.Content>
          <Header.Subheader>{transport.productDetails}</Header.Subheader>
        </Header>
      </Segment>
      <Segment>
        <Header>
          <Header.Content>Detalhes de Pagamento</Header.Content>
        </Header>
        <TransportRefundInfo refund={transport.refund} vertical/>
      </Segment>
    </Segment.Group>
  );
};

export default TransportInfo;
