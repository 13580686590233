import React from "react";
import { Input } from "semantic-ui-react";

interface IProps {
  setPredicate: (...args: any) => any;
  loading: boolean;
  limit?: string;
  placeholder?: string;
}

const SearchInput: React.FC<IProps> = ({
  setPredicate,
  loading,
  limit,
  placeholder,
}) => {
  return (
    <Input
      fluid
      loading={loading}
      icon="search"
      iconPosition="left"
      placeholder={placeholder || "Pesquisar..."}
      onChange={(_, data) => {
        let predicate = [];
        let values = [];

        predicate.push("filter");
        values.push(data.value ? data.value : "");

        if (limit) {
          predicate.push("limit");
          values.push("-1");
        }

        setPredicate(predicate, values);
      }}
    />
  );
};

export default SearchInput;
