import React from "react";
import { Segment, Header, Icon, TableCell, TableRow } from "semantic-ui-react";

interface IProps {
  content: string;
  colSpan: string;
}
const TableItemNotFound: React.FC<IProps> = ({ content, colSpan }) => {
  return (
    <TableRow>
      <TableCell colSpan={colSpan}>
        <Segment placeholder basic>
          <Header icon>
            <Icon name="search" />
            {content}
          </Header>
        </Segment>
      </TableCell>
    </TableRow>
  );
};

export default TableItemNotFound;
