export interface IRefund {
    id: number;
    statusHistory: IRefundHistory[];
    value: number;
    currentStatus: IRefundStatus;
}

export interface IRefundHistory {
    id: number;
    date: Date;
    status: IRefundStatus;
}

export enum IRefundStatus {
    ND,
    NOREFUND,
    INBOUND,
    OUTBOUND,
    PAID,
    REFUSEDPAYMENT
}