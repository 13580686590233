import React from "react";
import { useContext } from "react";
import { Button, Table } from "semantic-ui-react";
import { ITransport } from "../../../app/models/transport";
import { RootStoreContext } from "../../../app/stores/rootStore";

const TransportsRow: React.FC<{ transport: ITransport }> = ({ transport }) => {
  const rootStore = useContext(RootStoreContext);
  const { generateTransportDoc } = rootStore.reportStore;
  return (
    <Table.Row key={transport.transportGuid}>
      <Table.Cell>{transport.addressTo.addressDisplayName}</Table.Cell>
      <Table.Cell>
        {transport.addressTo.addressFirst}
      </Table.Cell>
      <Table.Cell>{transport.addressTo.leftPostCode! + "-" + transport.addressTo.rightPostCode}</Table.Cell>
      <Table.Cell>Sim</Table.Cell>
      <Table.Cell textAlign="center">{transport.isRefund ? transport.refundValue + " €" : "Não"}</Table.Cell>
      <Table.Cell textAlign="center">{transport.numberOfVolumes}</Table.Cell>
      <Table.Cell textAlign="center">{transport.totalWeight} kg</Table.Cell>
      <Table.Cell>
        <Button
        //   color="grey"
          size="small"
          icon="ticket"
          onClick={async () => {
            // setLoadIndividual(true);
            await generateTransportDoc([transport.transportGuid], "label");
            // setLoadIndividual(false);
          }}
        />
      </Table.Cell>
      <Table.Cell>
        <Button
          circular
          color="grey"
          size="small"
          icon="sticky note outline"
          onClick={async () => {
            // setLoadIndividual(true);
            await generateTransportDoc([transport.transportGuid], "resumedManifest");
            // setLoadIndividual(false);
          }}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default TransportsRow;
