import Agent from "./agent";

export const reportAPI = {
  resumedDebit: (params: URLSearchParams, companiesGuid: string[]) =>
    Agent.Report.resumedDebitReport(params, companiesGuid),
  resumedTransport: (params: URLSearchParams, companiesGuid: string[]) =>
    Agent.Report.resumedTransportReport(params, companiesGuid),
  detailedDebit: (params: URLSearchParams, companiesGuid: string[]) =>
    Agent.Report.detailedDebitReport(params, companiesGuid),
  detailedTransport: (params: URLSearchParams, companiesGuid: string[]) =>
    Agent.Report.detailedTransportsReport(params, companiesGuid),
  refunds: (params: URLSearchParams, companiesGuid: string[]) =>
    Agent.Report.refundReport(params, companiesGuid),
  volumesShift: (params: URLSearchParams, companiesGuid: string[]) =>
    Agent.Report.volumeShiftReport(params, companiesGuid),
};

export const transportDocAPI = {
  detailedManifest: (transportsGuid: string[]) =>
    Agent.Report.detailedManifest(transportsGuid),
  resumedManifest: (type: string[]) =>
    Agent.Report.resumedManifest(type),
  resumedManifestList: (type: string[]) =>
    Agent.Report.resumedManifestList(type),
  label: (transportsGuid: string[]) =>
    Agent.Report.labels(transportsGuid),
};

// order that show on dropdown
// ! first option is the default
export const resumedManifestOptions = [
  //{key: "all", text: "Hoje (todos)", value: "all"},
  {key: "to_collect", text: "Manifesto (Por recolher)", value: "to_collect"},
  {key: "collected", text: "Manifesto (Recolhido)", value: "collected"},
  {key: "not_manifest", text: "Imprimir manifesto", value: "not_manifest"},
  {key: "manifest", text: "2ª via", value: "manifest"},
]