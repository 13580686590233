import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { Grid, GridColumn, Pagination } from "semantic-ui-react";
import SearchInput from "../../../app/common/search/SearchForm";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { RootStoreContext } from "../../../app/stores/rootStore";
import SupplierCompanyList from "./SupplierCompanyList";

interface IProps {
  setValues: (...args: any[]) => any;
  addressType?: string;
}

const SupplierDashboard: React.FC<IProps> = ({ setValues, addressType }) => {
  const rootStore = useContext(RootStoreContext);
  const {
    loadSupplier,
    loading,
    supplier,
    setPredicate,
    page,
    totalPages,
    setPage,
  } = rootStore.supplierStore;
  
  const { customerSelectType } = rootStore.customerStore;

  useEffect(() => {
    customerSelectType(addressType)
    loadSupplier();
    return function cleanup() {
      setPredicate("all", "all")
      customerSelectType(undefined)
    };
  }, [loadSupplier, customerSelectType, page, setPredicate, addressType]);

  if (!supplier)
    return <LoadingComponent content="A carregar fornecedores" />;

  return (
    <Grid>
      <GridColumn width={16}>
        <SearchInput loading={loading} setPredicate={setPredicate} />
        <SupplierCompanyList selectCompanySupplier={setValues} />
        <Pagination
          totalPages={totalPages}
          activePage={page + 1}
          onPageChange={(_, { activePage }) => setPage(activePage!)}
        />
      </GridColumn>
    </Grid>
  );
};

export default observer(SupplierDashboard);
