import { observer } from "mobx-react-lite";
import React, { Fragment, useContext } from "react";
import { Table } from "semantic-ui-react";
import TableItemPlaceholder from "../../../app/common/placeholder/TableItemPlaceholder";
import { RootStoreContext } from "../../../app/stores/rootStore";
import ZoneListItem from "./ZoneListItem";

const ZoneList = () => {
  const rootStore = useContext(RootStoreContext);
  const { zones, loading } = rootStore.zoneStore;

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Nome da zona</Table.HeaderCell>
          <Table.HeaderCell>Prefixo Postal</Table.HeaderCell>
          <Table.HeaderCell>Início</Table.HeaderCell>
          <Table.HeaderCell>Fim</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {loading ? 
        <TableItemPlaceholder colSpan="4" />
      :
      <Fragment>
        {zones.map((zone) => (
          <ZoneListItem key={zone.displayName} zone={zone} />
        ))}

      </Fragment>
      }
      </Table.Body>
    </Table>
  );
};

export default observer(ZoneList);
