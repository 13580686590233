import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Table } from "semantic-ui-react";
import ListItemNotFound from "../../../../app/common/placeholder/ListItemNotFound";
import TableItemPlaceholder from "../../../../app/common/placeholder/TableItemPlaceholder";
import { IWarehouse } from "../../../../app/models/warehouse";
import { RootStoreContext } from "../../../../app/stores/rootStore";

const WarehouseList: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { getWarehousesSliced, loading, } =
    rootStore.warehouseStore;

  if (loading) return <TableItemPlaceholder colSpan="2" />;

  return (
    <>
      {getWarehousesSliced.length > 0 ? (
        <>
          <Table striped textAlign="center" selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  Id
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <h3 style={{ paddingTop: 5, paddingBottom: 5 }}>Armazém</h3>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <h3>Abreviatura</h3>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {getWarehousesSliced.map((wh: IWarehouse) => (
                <Table.Row key={wh.id}>
                  <Table.Cell>
                    {wh.id}
                  </Table.Cell>
                  <Table.Cell selectable>

                    <a href={`/warehouse/${wh.id}`}>{wh.name.toUpperCase()}</a>
                  </Table.Cell>
                  <Table.Cell style={{ fontSize: 15 }} selectable>
                    <a href={`/warehouse/${wh.id}`}>
                      {wh.abbreviation.toUpperCase()}
                    </a>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          {/* <Pagination
            totalPages={totalPages}
            activePage={page + 1}
            onPageChange={(_, { activePage }) => setPage(activePage!)}
          /> */}
        </>
      ) : (
        <ListItemNotFound content="Não existem armazéns!" />
      )}
    </>
  );
};

export default observer(WarehouseList);
