import { IRefund, IRefundStatus } from "../models/refund";

export const isRefund = (refund: IRefund) => {
    return refund ? refund.value > 0 : false;
}

export const getRefundValue = (refund: IRefund) => {
  return refund ? refund.value : 0;
};

export const getRefundHistory = (refund: IRefund) => {
  return refund ? refund.statusHistory : [];
};

export const getRefundStatus = (refund: IRefund) => {
  return refund ? refund.currentStatus : IRefundStatus.ND;
};

export const refundStatus = [
  "Não definido",
  "N/D",
  "Aguarda cobrança",
  "Cobrado, aguarda transferência",
  "Transferido",
  "Recusa pagamento",
];
