import { observer } from "mobx-react-lite";
import { useContext, useEffect } from "react";
import { RootStoreContext } from "../../../../app/stores/rootStore";
import ListItemPlaceholder from "../../../../app/common/placeholder/ListItemPlaceholder";
import RouteListElement from "./RouteListElement";
import ListItemNotFound from "../../../../app/common/placeholder/ListItemNotFound";
import { Grid, Pagination, Segment, Table } from "semantic-ui-react";

const RouteList: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { getRoutesSliced, loading, loadRoutes, clearRoute, setPredicate, routeCount, totalPages, page, setPage } =
    rootStore.routeStore;
  useEffect(() => {
    clearRoute();
    setPredicate("detailed", "true")
    loadRoutes();
    return function cleanup() {
      setPredicate("all", "all");
    }
  }, [loadRoutes, clearRoute, routeCount]);
  if (loading) return <ListItemPlaceholder />;
  return (
    <>
      {getRoutesSliced.length > 0 ? (
        <Segment>
          <h2 style={{ paddingBottom: 6, paddingTop: 25 }}>Rotas</h2>
          <Table>
            <Table.Header>
              <Table.Row >
                <Table.HeaderCell width={4}>Nome</Table.HeaderCell>
                <Table.HeaderCell width={4}>Armazem</Table.HeaderCell>
                <Table.HeaderCell width={4}>Estafeta</Table.HeaderCell>
                <Table.HeaderCell width={4}></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {getRoutesSliced.map((route) => (
                <RouteListElement key={route.id} route={route} />
              ))}
            </Table.Body>
          </Table>
        </Segment>
      ) : (
        <div style={{ paddingTop: 80 }}>
          <ListItemNotFound content="Não existem rotas!" />
        </div>
      )}
      {/* <Pagination
        totalPages={totalPages}
        activePage={page + 1}
        onPageChange={(_, { activePage }) => setPage(activePage!)}
      /> */}
    </>
  );
};

export default observer(RouteList);
