export const serviceOpt = [
  { key: "express", text: "Express", value: "express" },
  { key: "9h", text: "9h", value: "9h" },
  { key: "13h", text: "13h", value: "13h" },
];

export const volumeType = [
  { key: "standard", text: "Embalagem", value: "standard" },
  { key: "windshield", text: "Parabrisas", value: "windshield" },
  { key: "oilField", text: "Tambor de Óleo", value: "oilField" },
  { key: "pallet", text: "Europalete", value: "pallet" },
  { key: "lateral_door", text: "Porta e vidro lateral", value: "lateral_door" },
  { key: "oculus_glass", text: "Vidros de Óculo", value: "oculus_glass" },
  { key: "hood", text: "Capôt", value: "hood" },
  { key: "bumpers", text: "Parachoques", value: "bumpers" },
  { key: "mudguard", text: "Guarda-lamas", value: "mudguard" },
  { key: "battery", text: "Bateria", value: "battery" },
];

interface BoolMap { [key: string]: boolean; }

export const weightBool: BoolMap = {
    "standard": false,
    "windshield": true,
    "oilField": false,
    "pallet": false,
    "lateral_door": true,
    "oculus_glass": true,
    "hood": true,
    "bumpers": true,
    "mudguard": true,
    "battery": false,
}