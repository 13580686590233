import { IZone } from "../models/zone";
import Agent from "./agent";

export const createZone = async (zone: IZone) => {
    try {
        await Agent.Zones.create(zone);
    } catch (error) {
        console.log(error);
    }
}

export const getZones = async () => {
    try {
        return await Agent.Zones.list();
    } catch (error) {
        console.log(error)
        return [];
    }
}