import { IAddress } from "./address";
import { ISupplier } from "./supplier";
import { IUser } from "./user";

export interface ICompany {
    guid: string;
    taxName: string;
    commercialName: string;
    vatNumber: string;
    hasAgreement: boolean;
    monthQuantity: number;
    groupVolumes: number;
    appUsers: IUser[];
    addresses: IAddress[];
    isSupplier: boolean;
    supplier: ISupplier | null;
}

export interface ICompanySummary extends Partial<ICompany> {
    guid : string;
    taxName : string;
    commercialName : string;
    vatNumber : string;
}

export interface ICompanySummaryAddresses extends ICompanySummary {
    addresses : IAddress[];
}

export interface ICompanySummaryEnvelope {
    companies : ICompanySummaryAddresses[];
    companyCount : number;
}


export interface ICompanyFormValues extends Partial<ICompany> {
    
}

export class CompanyFormValues implements ICompanyFormValues {
    guid?: string = undefined;
    taxName: string = "";
    commercialName: string = "";
    vatNumber: string = '';
    hasAgreement: boolean = true;
    monthQuantity: number = 50;
    isSupplier: boolean = false;
    groupVolumes: number = 1;


    constructor(init?: ICompanyFormValues) {
        Object.assign(this, init);
    }
}

export interface ICompaniesEnvelope {
    companies: ICompany[],
    companyCount: number;
}