
import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Form as FinalForm } from "react-final-form";
import { Button, Checkbox, Form, Grid, Header, Segment } from "semantic-ui-react";
import { ICourier } from "../../../../app/models/user";
import { RootStoreContext } from "../../../../app/stores/rootStore";

interface IProps {
    routeId: number;
    currentCourierId: string;
}

const RouteSetCourier: React.FC<IProps> = ({ routeId, currentCourierId }) => {
    const rootStore = useContext(RootStoreContext);
    const { closeModal } = rootStore.modalStore;
    const { couriers, loadCouriers, loading, updateRouteCourier, loadingInitial, submitting } = rootStore.courierStore;
    const {updateCurrentRouteCourier } = rootStore.routeStore;
    const [selectedCourier, setSelectedCourier] = useState(currentCourierId ?? "")


    useEffect(() => {
        loadCouriers()
        return () => {
        }
    }, [loadCouriers]);

    return (
        <FinalForm
            onSubmit={async () => {
                try {
                    if (selectedCourier.length > 0) {
                        updateRouteCourier(routeId, selectedCourier);
                        updateCurrentRouteCourier(selectedCourier, couriers.filter(x => x.id === selectedCourier)[0].displayname)
                    }
                    closeModal()
                } catch (error) {

                }
            }}
            loading={loading}
            render={({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                    <Segment>
                        <Header size="small" content="Selecione o(s) estafeta(s)*" />
                        {couriers.length ?
                            couriers.map((c, i) => (
                                <Segment.Group horizontal key={i}>
                                    <Segment>
                                        <Checkbox radio checked={selectedCourier === c.id} onChange={() => setSelectedCourier(c.id)} label={c.displayname} key={c.id} />
                                    </Segment>
                                    <Segment color={c.warehouseName ? "red" : "green"}>
                                        Armazem: {c.warehouseName ?? "Não definida"}
                                    </Segment>
                                </Segment.Group>
                            ))
                            :
                            "Não há estafetas para apresentar."
                        }
                    </Segment>
                    <Grid>
                        <Grid.Column>
                            <Button
                                type="submit"
                                color="green"
                                floated="right"
                                loading={submitting}
                                disabled={submitting}
                                positive
                            >
                                Salvar
                            </Button>
                            <Button floated="right" onClick={() => closeModal()}>
                                Voltar
                            </Button>
                        </Grid.Column>
                    </Grid>
                </Form>
            )}
        ></FinalForm>
    );
};

export default observer(RouteSetCourier);
