import { action, makeObservable, observable, runInAction } from "mobx";
import Agent from "../api/agent";
import { RootStore } from "./rootStore";
import { IAddress, IAddressFormValues } from "../models/address";
import { createCompanyAddress } from "../api/CompanyRequest";
import { v4 as uuid } from "uuid";
import { createCustomerAddress } from "../api/CustomerRequest";

const createAddressTypeHandler: {
  [key: string]: (address: IAddress, guid: string) => any;
} = {
  company: (address: IAddress, companyGuid: string) =>
    createCompanyAddress(address, companyGuid),
  customer: (address: IAddress, companyGuid: string) =>
    createCustomerAddress(address, companyGuid),
};

export default class AddressStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @observable addressRegistry = new Map();
  @observable address: IAddress | null = null;
  @observable submitting = false;

  @action loadAddress = async (guid: string) => {};

  @action createAddress = async (address: IAddressFormValues) => {
    try {
      await Agent.Address.create(address);
    } catch (error: any) {
      console.log(error.config.data);
    }
  };

  @action addressHandler = async (
    address: IAddress,
    entityType: string,
    guid: string
  ) => {
    this.submitting = true;
    try {
      if (address.addressGuid) {
        // Edit mode
        entityType = await Agent.Address.edit(address);
      } else {
        // Create mode
        address.addressGuid = uuid();
        await createAddressTypeHandler[entityType](address, guid);
      }
      runInAction(() => {
        if (entityType === "company") {
          this.rootStore.companyStore.updateCompanyAddress(guid);
        } else if (entityType === "customer") {
          this.rootStore.customerStore.addAddress(address);
        }
        this.submitting = false;
      });
    } catch (error) {
      console.log(error);
      this.submitting = false;
    }
    return address;
  };
}
