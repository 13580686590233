import { observer } from 'mobx-react-lite'
import React, { useContext, useState } from 'react'
import { Form as FinalForm, Field } from 'react-final-form';
import { Button, Checkbox, Form, Grid, Header, Segment, SegmentGroup } from 'semantic-ui-react';
import TextInput from '../../../app/common/form/TextInput';
import { composeValidators, maxLength, minLength, required } from '../../../app/common/form/Validators';
import { CourierEditFormValues, ICourier, ICourierEditQuery } from '../../../app/models/user'
import { RootStoreContext } from '../../../app/stores/rootStore';

interface IProps {
    courier: ICourier;
}

interface IErrors {
    passwordConfirm?: string;
}

const EditCourier: React.FC<IProps> = ({ courier }) => {
    const rootStore = useContext(RootStoreContext);
    const { updateCourier, submitting } = rootStore.courierStore;
    const { closeModal } = rootStore.modalStore;

    const [transhipment, setTranshipment] = useState(courier.transhipment);
    const [active, setActive] = useState(courier.active);

    return (
        <FinalForm
            onSubmit={async (values: ICourierEditQuery) => {
                values.transhipment = transhipment;
                values.active = active;
                await updateCourier(values);
                closeModal();
            }}
            validate={(values) => {
                const errors: IErrors = {};
                if (values.password !== values.passwordConfirm)
                    errors.passwordConfirm = "Não coincidem!";
                return errors;
            }}
            initialValues={new CourierEditFormValues(courier)}
            render={({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                    <Segment.Group>
                        <Segment>
                            <Header size="small" content="Nome do estafeta*" />
                            <Field
                                name="displayname"
                                placeholder="Zé"
                                maxLength={40}
                                validate={composeValidators(
                                    required,
                                    maxLength(40),
                                    minLength(3)
                                )}
                            >
                                {(props) => <TextInput {...props} />}
                            </Field>
                        </Segment>
                        <Segment>
                            <Header size="small" content="Nome do utilizador*" />
                            <Field
                                name="username"
                                placeholder="estafeta.braga"
                                disabled
                            >
                                {(props) => <TextInput {...props} />}
                            </Field>
                        </Segment>
                        <Segment.Group>
                            <Segment>
                                <Header size="small" content="Palavra-Passe*" />
                                <Field
                                    name="password"
                                    placeholder="***********"
                                    type="password"
                                    validate={composeValidators(maxLength(30))}
                                >
                                    {(props) => <TextInput {...props} />}
                                </Field>
                            </Segment>
                            <Segment>
                                <Header size="small" content="Confirma a palavra-Passe*" />
                                <Field
                                    name="passwordConfirm"
                                    placeholder="***********"
                                    type="password"
                                    validate={composeValidators(maxLength(30))}
                                >
                                    {(props) => <TextInput {...props} />}
                                </Field>
                            </Segment>
                        </Segment.Group>
                        <SegmentGroup horizontal>
                            <Segment>
                                <Checkbox
                                    name="transhipment"
                                    label="Permissões de transbordo?"
                                    checked={transhipment === true}
                                    onChange={(e, data) => {
                                        setTranshipment(prevState => !prevState)
                                    }}
                                />
                            </Segment>
                            <Segment>
                                <Checkbox
                                    name="active"
                                    label="Ativo?"
                                    checked={active === true}
                                    onChange={(e, data) => {
                                        setActive(prevState => !prevState)
                                    }}
                                />
                            </Segment>

                        </SegmentGroup>
                    </Segment.Group>
                    <Grid>
                        <Grid.Column>
                            <Button
                                type="submit"
                                color="green"
                                floated="right"
                                loading={submitting}
                                disabled={submitting}
                                positive
                            >
                                Salvar
                            </Button>
                            <Button floated="right" onClick={() => closeModal()}>
                                Voltar
                            </Button>
                        </Grid.Column>
                    </Grid>
                </Form>
            )} >

        </FinalForm>
    )
}

export default observer(EditCourier);