import React from "react";
import { Link } from "react-router-dom";
import { Button, Header, Icon, Item, Segment, Table } from "semantic-ui-react";
import { format } from "date-fns/esm";
import { ITransport, ITransportSummary } from "../../../app/models/transport";
import { statusListName, statusName } from "../../../app/dataAccess/TransportData";

const TransportListItem: React.FC<{ transport: ITransportSummary }> = ({
  transport,
}) => {
  return (
    // <Table.Row onClick={() => {window.location.href = `/transport/${transport.transportGuid}`}}>
    //   <Table.Cell>{transport.id}</Table.Cell>
    //   <Table.Cell>{transport.addressToDisplayName}</Table.Cell>
    //   <Table.Cell>{transport.addressToLeftPostCode}-{transport.addressToRightPostCode} {transport.addressToLocation}</Table.Cell>
    //   <Table.Cell>{transport.numberOfVolumes}</Table.Cell>
    // </Table.Row>
    <Segment.Group>
      <Segment>
        <Item.Group>
          <Item>
            <Item.Content>
              <Item.Header
                as={Link}
                to={`/transport/${transport.transportGuid}`}
              >
                {transport.addressToDisplayName}
              </Item.Header>
            </Item.Content>
          </Item>
        </Item.Group>
      </Segment>
      <Segment>
        <Icon name="marker" /> {transport.addressToPostcodeToString}</Segment>
      <Segment secondary>
        <Table basic="very" celled attached columns={2}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Data</Table.HeaderCell>
              <Table.HeaderCell>Estado</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {transport.status.history.map((state) => (
              <Table.Row key={state.statusName}>
                <Table.Cell>
                  <Header as="h5">
                    {format(new Date(state.modificationDate), "dd/MM/y H:mm")}
                  </Header>
                </Table.Cell>
                <Table.Cell>{statusListName[state.status]}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment>
      <Segment clearing>
        <Icon name="box" />
        <span> {transport.numberOfVolumes} </span>
        <Button
          as={Link}
          to={`/transport/${transport.transportGuid}`}
          floated="right"
          content="Detalhes"
          color="blue"
        />
      </Segment>
    </Segment.Group>
  );
};

export default TransportListItem;
