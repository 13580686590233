import { action, makeObservable, observable } from "mobx";
import { createContext } from "react";
import { IAddress, IAddressApp } from "../models/address";
import { ICompany } from "../models/company";
import { ICustomer } from "../models/customer";

class CreateTransportStore {

    constructor() {
        makeObservable(this)
    }

    @observable company: ICompany | undefined = undefined;
    @observable customer: ICustomer | undefined = undefined;
    @observable addressFrom: IAddressApp | undefined = undefined;
    @observable addressTo: IAddress | undefined = undefined;

    @action setCompany = (company: ICompany) => {
        this.company = company;
    }

    @action clearCompany = () => {
        this.company = undefined;
    }

    @action setAddressFrom = (address: IAddressApp) => {
        this.addressFrom = address;
    }

    @action clearAddressFrom = () => {
        this.addressTo = undefined;
    }

    @action setCustomer = (customer: ICustomer) => {
        this.customer = customer;
    }
}

export const CreateTransportContext = createContext(new CreateTransportStore())