import React, { useContext, useEffect } from "react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import ReportFilters from "../adminDashboard/ReportFilters";

const CompanyReportDashboard = () => {
  const rootStore = useContext(RootStoreContext);
  const { insertCompany, resetCompany } = rootStore.reportStore;
  const { company } = rootStore.companyStore;
  useEffect(() => {
    resetCompany();
    if (company) insertCompany(company.guid);
  }, [company, insertCompany, resetCompany]);
  return <ReportFilters search={false} />;
};

export default CompanyReportDashboard;
