import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { Field, Form as FinalForm } from "react-final-form";
import {
  Button,
  Checkbox,
  Form,
  Grid,
  Header,
  Segment,
} from "semantic-ui-react";
import TextInput from "../../../app/common/form/TextInput";
import {
  composeValidators,
  maxLength,
  minLength,
  required,
} from "../../../app/common/form/Validators";
import { CourierFormValues } from "../../../app/models/user";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { FORM_ERROR } from "final-form";

interface IErrors {
  passwordConfirm?: string;
}

const CourierCreateModal: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { closeModal } = rootStore.modalStore;
  const { registerCourier, submitting } = rootStore.userStore;
  const [checkbox, setCheckbox] = useState(false);

  const [initialValues] = useState(new CourierFormValues());

  return (
    <FinalForm
      onSubmit={async (values: CourierFormValues) => {
        values.transhipment = checkbox;
        await registerCourier(values)
        closeModal();
      }}
      validate={(values) => {
        const errors: IErrors = {};
        if (values.password !== values.passwordConfirm)
          errors.passwordConfirm = "Não coincidem!";
        return errors;
      }}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Segment.Group>
            <Segment>
              <Header size="small" content="Nome do estafeta*" />
              <Field
                name="displayname"
                placeholder="Zé"
                maxLength={40}
                validate={composeValidators(
                  required,
                  maxLength(40),
                  minLength(3)
                )}
              >
                {(props) => <TextInput {...props} />}
              </Field>
            </Segment>
            <Segment>
              <Header size="small" content="Nome do utilizador*" />
              <Field
                name="username"
                placeholder="estafeta.braga"
                maxLength={40}
                validate={composeValidators(
                  required,
                  maxLength(40),
                  minLength(3)
                )}
              >
                {(props) => <TextInput {...props} />}
              </Field>
            </Segment>
            <Segment.Group>
              <Segment>
                <Header size="small" content="Palavra-Passe*" />
                <Field
                  name="password"
                  placeholder="***********"
                  type="password"
                  validate={composeValidators(required, maxLength(30))}
                >
                  {(props) => <TextInput {...props} />}
                </Field>
              </Segment>
              <Segment>
                <Header size="small" content="Confirma a palavra-Passe*" />
                <Field
                  name="passwordConfirm"
                  placeholder="***********"
                  type="password"
                  validate={composeValidators(required, maxLength(30))}
                >
                  {(props) => <TextInput {...props} />}
                </Field>
              </Segment>
            </Segment.Group>
            <Segment>
              <Checkbox
                name="transhipment"
                label="Permissões de transbordo?"
                checked={checkbox === true}
                onChange={(e, data) => {
                  if (checkbox === true) {
                    setCheckbox(false);
                  } else {
                    setCheckbox(true);
                  }
                }}
              />
            </Segment>
          </Segment.Group>
          <Grid>
            <Grid.Column>
              <Button
                type="submit"
                color="green"
                floated="right"
                loading={submitting}
                disabled={submitting}
                positive
              >
                Salvar
              </Button>
              <Button floated="right" onClick={() => closeModal()}>
                Voltar
              </Button>
            </Grid.Column>
          </Grid>
        </Form>
      )}
    ></FinalForm>
  );
};

export default observer(CourierCreateModal);
