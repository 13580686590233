import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import {
  Button,
  Grid,
  List,
  PlaceholderParagraph,
  Segment,
} from "semantic-ui-react";
import { RootStoreContext } from "../../../../app/stores/rootStore";
import AddPostalRange from "./AddPostalRange";
import AddPostalLocale from "./AddPostalLocale";

const PostalDetails: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { openModal } = rootStore.modalStore;
  const { route } = rootStore.routeStore;

  return (
    <>
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Segment style={{overflow: 'auto', maxHeight: 200}}>
              <List divided verticalAlign="middle">
                {route && route.postcodes ? (
                  <>
                    {route.postcodes.map((postCode) => (
                      <List.Item key={postCode.id}>
                        <List.Content>{postCode.numCode}-{("000"+postCode.extCode).slice(-3)} {postCode.localeName}</List.Content>
                      </List.Item>
                    ))}
                  </>
                ) : (
                  <PlaceholderParagraph />
                )}
              </List>
            </Segment>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Grid.Row>
              <div style={{ padding: 5 }}>
                <Button
                  fluid
                  color="green"
                  content="Adicionar CP por intervalo"
                  positive
                  onClick={() => openModal(<AddPostalRange />)}
                />
              </div>
            </Grid.Row>
            <Grid.Row>
              <div style={{ padding: 5 }}>
                <Button
                  fluid
                  color="green"
                  content="Adicionar CP por localidade"
                  positive
                  onClick={() => openModal(<AddPostalLocale />)}
                />
              </div>
            </Grid.Row>
            {/* <Grid.Row>
              <div style={{ padding: 5 }}>
                <Button
                  fluid
                  color="green"
                  content="Adicionar CP por concelho"
                  positive
                  onClick={() => openModal(<AddPostalCounty />)}
                />
              </div>
            </Grid.Row>
            <Grid.Row>
              <div style={{ padding: 5 }}>
                <Button
                  fluid
                  color="green"
                  content="Adicionar CP por distrito"
                  positive
                  onClick={() => openModal(<AddPostalDistrict />)}
                />
              </div>
            </Grid.Row> */}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default observer(PostalDetails);
