import React, { useContext } from "react";
import { Header, Segment } from "semantic-ui-react";
import { ICustomer } from "../../../app/models/customer";
import { RootStoreContext } from "../../../app/stores/rootStore";

interface IProps {
  customer: ICustomer;
}

const CustomerDetails: React.FC<IProps> = ({ customer }) => {
  const rootStore = useContext(RootStoreContext);
  const {company} = rootStore.companyStore;

  return (
    <Segment>
      <Header>
        <Header.Content>{customer ? customer.companyName : ""}</Header.Content>
        <Header.Subheader>{customer ? customer.vatNumber : company?.vatNumber }</Header.Subheader>
        <Header.Subheader>{customer ? customer.displayName : "" }</Header.Subheader>
        <Header.Subheader>{customer ? customer.email : "" }</Header.Subheader>
        <Header.Subheader>{customer ? customer.phoneNumber : "" }</Header.Subheader>
      </Header>
    </Segment>
  );
};

export default CustomerDetails;
