import { observer } from "mobx-react-lite";
import { useContext, useEffect } from "react";
import { Table } from "semantic-ui-react";
import { IRoute } from "../../../../app/models/route";
import { RootStoreContext } from "../../../../app/stores/rootStore";

interface IProps {
  route: IRoute;
}

const RouteListElement: React.FC<IProps> = ({ route }) => {
  const rootstore = useContext(RootStoreContext);
  const { getRouteLocals } = rootstore.geoStore;

  useEffect(() => {
    getRouteLocals(route.id);
  }, [getRouteLocals, route.id]);

  return (
    <Table.Row key={route.id}>
      <Table.Cell width={4}>
        {route.id} - {route.name}
      </Table.Cell>
      <Table.Cell width={4}>
        {route.warehouseName ?? "n/d"}
      </Table.Cell>
      <Table.Cell width={4}>
        {route.courierDisplayname ?? "n/d"}
      </Table.Cell>
      <Table.Cell width={4}  textAlign="center" verticalAlign="bottom" selectable positive >
        <a href={`/route/${route.id}`}>
          Detalhes
        </a>
      </Table.Cell>
    </Table.Row>
  );
};

export default observer(RouteListElement);
