import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import Agent from "../api/agent";
import { ISupplier } from "../models/supplier";
import { RootStore } from "./rootStore";
import { ICompany, ICompanySummaryAddresses } from "../models/company";
import { LIMIT_BY_PAGE } from "../common/util/globals";

export default class SupplierStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    reaction(
      () => this.predicate.keys(),
      () => {
        this.page = 0;
        this.supplierCompaniesRegistry.clear();
        this.loadSupplier();
      }
    )
  }

  @observable supplierRegistry = new Map();
    @observable supplier: ISupplier | null = null;
  @observable supplierCompanies: ICompany[] = [];
  @observable supplierCompaniesRegistry= new Map();
  @observable loading = false;
  @observable predicate = new Map();
  @observable submitting = false;
  @observable page = 0;
  @observable supplierCompanyCount = 0;

  @computed get getCompanies(): ICompany[] {
    const companies = Array.from(this.supplierCompaniesRegistry.values())
      .slice(
        this.page * LIMIT_BY_PAGE,
        this.page * LIMIT_BY_PAGE + LIMIT_BY_PAGE
      ) ?? [];
    return companies;
  }

  @computed get getCompaniesSummary(): ICompanySummaryAddresses[] {
    const companies = Array.from(this.supplierCompaniesRegistry.values())
      .slice(
        this.page * LIMIT_BY_PAGE,
        this.page * LIMIT_BY_PAGE + LIMIT_BY_PAGE
      ) ?? [];
    return companies;
  }

  @computed get totalPages() {
    return Math.ceil(this.supplierCompanyCount / LIMIT_BY_PAGE);
  }

  @action setPage = (page: number | string) => {
    this.page = Number(page) - 1;
  };

  @computed get axiosParams() {
    const params = new URLSearchParams();
    params.append("limit", LIMIT_BY_PAGE.toString());
    params.append("offset", `${this.page ? this.page * LIMIT_BY_PAGE : 0}`);
    this.predicate.forEach((value, key) => {
      params.append(key, value);
    });
    return params;
  }

  @action setPredicate = (predicate: string, value: string | Date) => {
    this.predicate.clear();
    if (predicate !== "all") {
      this.predicate.set(predicate, value);
    }
  };


  @action loadSuppliers = async () => {
    this.loading = true;
    try {
      let params = this.axiosParams;
      const companiesEnvelope = await Agent.Supplier.list("", params);
      const { companies, companyCount } = companiesEnvelope;
      runInAction(() => {
        companies.forEach((company) => {
          this.rootStore.companyStore.companyRegistry.set(
            company.guid,
            company
          );
        });
        this.rootStore.companyStore.companyCount = companyCount;
        this.loading = false;
      });
    } catch (error) {
      this.loading = false;
    }
  };

  getSupplier = (vatNumber: string) => {
    return this.supplierRegistry.get(vatNumber);
  };

  @action loadSupplier = async () => {
    this.loading = true;
    const supplierVatNumber = 
      this.rootStore.companyStore.company!.vatNumber
    let axiosParams = this.axiosParams;
    try {
      const supplierCompaniesEnvelope = await Agent.Supplier.listResumed(supplierVatNumber, axiosParams);
      const { companies, companyCount } = supplierCompaniesEnvelope;
      runInAction(() => {
        const supplier = {
          vatNumber: supplierVatNumber,
          companiesSummary: companies,
          transports: [],
          companies: []
        };
        // this.supplierCompanies = companies;
        companies.forEach(value => {
          this.supplierCompaniesRegistry.set(value.guid, value);
        })
        this.supplier = supplier;
        this.supplierCompanyCount = companyCount;
        this.loading = false;
      });
    } catch (error) {
      this.loading = false;
    }
  };

  @action addCompany = async (companyGuid: string) => {
    try {
      const company = await Agent.Supplier.addCompany(
        this.rootStore.companyStore.company!.vatNumber,
        companyGuid
      );
      runInAction(() => {
        this.rootStore.companyStore.company!.supplier!.companies.push(company);
        this.rootStore.companyStore.companyRegistry.set(
          companyGuid,
          this.rootStore.companyStore.company!
        );
        //this.rootStore.modalStore.closeModal();
      });
    } catch (error) {
    }
  };

  @action removeCompany = async (companyGuid: string) => {
    this.submitting = true;
    try {
      await Agent.Supplier.removeCompany(
        this.rootStore.companyStore.company!.vatNumber,
        companyGuid
      );
      runInAction(() => {
        this.rootStore.companyStore.company!.supplier!.companies = this.rootStore.companyStore.company!.supplier!.companies.filter(
          (x) => x.guid !== companyGuid
        );
        this.rootStore.companyStore.companyRegistry.set(
          this.rootStore.companyStore.company!.guid,
          this.rootStore.companyStore.company!
        );
        this.submitting = false;
        this.rootStore.modalStore.closeModal();
      });
    } catch (error) {
      this.submitting = false;
    }
  };
}
