import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { List, Button, Icon } from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";

const SupplierCompanyList = () => {
  const rootStore = useContext(RootStoreContext);
  const { removeCompany, submitting } = rootStore.supplierStore;
  const { company } = rootStore.companyStore;
  return (
    <List divided verticalAlign="middle">
      { company && company.supplier != null ?  company.supplier.companies.map((company) => (
        <List.Item key={company.guid}>
          <List.Content floated="right">
            <Button animated color='red' loading={submitting} disabled={submitting} onClick={() => removeCompany(company.guid)} >
              <Button.Content visible>
                <Icon name="delete" />
              </Button.Content>
              <Button.Content hidden>Remover</Button.Content>
            </Button>
          </List.Content>

          <List.Content>
            <h4>
              <Icon name="chevron right" />
              {`${company.commercialName} - ${company.taxName}`}
            </h4>
          </List.Content>
        </List.Item>
      )) : 
      null}
    </List>
  );
};

export default observer(SupplierCompanyList);
