import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { Field, Form as FinalForm } from "react-final-form";
import { Button, Form, Header, Radio, Segment } from "semantic-ui-react";
import { TransportFormValues } from "../../../app/models/transport";
import { RootStoreContext } from "../../../app/stores/rootStore";
import CreateTransportDetails from "./CreateTransportDetails";
import arrayMutators from "final-form-arrays";
import { FORM_ERROR } from "final-form";
import SelectInput from "../../../app/common/form/SelectInput";
import { serviceOpt } from "../../../app/common/options/ServiceType";
import { history } from "../../..";
import { ICustomerFormValues } from "../../../app/models/customer";
import { IAddress } from "../../../app/models/address";
import CreateTransportSend from "./CreateTransportSend";
import CreateTransportReceive from "./CreateTransportReceive";
import { toNumber } from "lodash";

const CreateTransport = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    loading,
    createTransport,
    clearTransport,
    setDebit,
  } = rootStore.transportStore;
  const { customerSelectType, selectType } = rootStore.customerStore;
  const { company } = rootStore.companyStore;

  const [transport] = useState(new TransportFormValues());
  const [transportType, setTransportType] = useState("send");
  useEffect(() => {
    if (company) setDebit(company.vatNumber);
    return function cleanup() {
      clearTransport();
    }
  }, [setDebit, company, clearTransport]);

  return (
    <FinalForm
      mutators={{
        ...arrayMutators,
        setValues: (args: ICustomerFormValues[], state, utils) => {
          for (const [key, value] of Object.entries(args[0])) {
            utils.changeValue(state, key, () => value);
          }
        },
        setAddress: (address: IAddress[], state, utils) => {
          utils.changeValue(
            state,
            selectType
              ? selectType
              : transportType === "send"
                ? "addressFrom"
                : "addressTo",
            () => address[0]
          );
          customerSelectType(undefined);
        },
        setValue: (args: string[], state, utils) => {
          utils.changeValue(state, args[0], () => args[1])
        }
      }}
      onSubmit={(values) => {
        createTransport(values)
          .then((transport) => {
            //clearTransport();
            history.push(`/transport/${transport?.transportGuid}`);
          })
          .catch((error) => ({
            [FORM_ERROR]: error,
          }))
      }
      }
      initialValues={transport}
      render={({
        handleSubmit,
        form: {
          reset,
          mutators: { push, setValues, setAddress, setValue },
        },
        values,

      }) => {
        const { isRefund, addressFrom, addressTo } = values;
        return (
          <Form onSubmit={handleSubmit}>
            <Segment.Group horizontal>
              <Segment>
                <Form.Field>
                  <Radio
                    label="Enviar"
                    name="radioGroup"
                    value="send"
                    checked={transportType === "send"}
                    onChange={(_, { value }) => {
                      clearTransport();
                      reset();
                      setTransportType("send");
                    }}
                  />
                </Form.Field>
              </Segment>
              <Segment>
                <Form.Field>
                  <Radio
                    label="Receber"
                    name="radioGroup"
                    value="receive"
                    checked={transportType === "receive"}
                    onChange={(_, { value }) => {
                      clearTransport();
                      reset();
                      setTransportType("receive");
                    }}
                  />
                </Form.Field>
              </Segment>
            </Segment.Group>
            {transportType === "send" ? (
              <CreateTransportSend
                addressFrom={addressFrom}
                addressTo={addressTo}
                setAddress={setAddress}
              />
            ) : (
              <CreateTransportReceive
                addressFrom={addressFrom}
                addressTo={addressTo}
                setAddress={setAddress}
              />
            )}
            <Segment>
              <CreateTransportDetails
                pop={setValues}
                push={push}
                isRefund={isRefund}
                setValue={setValue}
              />
            </Segment>
            <Segment>
              <Header content="Serviço" />
              <Field
                name="serviceType"
                component={SelectInput}
                options={serviceOpt}
              />
            </Segment>
            <Segment attached="bottom" clearing>
              <Button
                floated="right"
                type="submit"
                positive
                fluid
                loading={loading}
                disabled={loading}
                content="Criar transporte"
                color="teal"
              />
            </Segment>
          </Form>
        );
      }}
    ></FinalForm>
  );
};

export default observer(CreateTransport);
