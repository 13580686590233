import React from "react";
import { Table } from "semantic-ui-react";
import { IZone } from "../../../app/models/zone";

interface IProps {
  zone: IZone;
}

const ZoneListItem: React.FC<IProps> = ({ zone }) => {
  return (
    <Table.Row>
      <Table.Cell>{zone.displayName}</Table.Cell>
      <Table.Cell>{zone.leftPostCodeStart.toString().padStart(4, "0")}</Table.Cell>
      <Table.Cell>{zone.rightPostCodeStart.toString().padStart(3, "0")}</Table.Cell>
      <Table.Cell>{zone.rightPostCodeEnd.toString().padStart(3, "0")}</Table.Cell>
    </Table.Row>
  );
};

export default ZoneListItem;
