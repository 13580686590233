import React, { Fragment, useContext } from "react";
import { Button, Icon, Select } from "semantic-ui-react";
import {
  generateOptions,
  getAddress,
  getMainAddress,
} from "../../../app/dataAccess/AddressData";
import { RootStoreContext } from "../../../app/stores/rootStore";
import CustomerAddAddress from "../../customer/address/CustomerAddAddress";

interface IProps {
  addressType: string;
  setAddress: (...args: any) => any;
}

const CreateTransportSelectAddress: React.FC<IProps> = ({
  addressType,
  setAddress,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { getCustomerType } = rootStore.transportStore;
  const { customerSelectType } = rootStore.customerStore;
  const { openModal } = rootStore.modalStore;

  let selected = getCustomerType(addressType);
  if (selected) {
    let mainAddress = getMainAddress(selected.addresses);

    return (
      <Fragment>
        {"customerGuid" in selected ? (
          <Button
            type="button"
            positive
            onClick={() => {
              openModal(
                <CustomerAddAddress
                  addressPrefix={addressType}
                  onSubmit={setAddress}
                />
              );
            }}
          >
            <Icon name="thumbtack" />
            Adicionar endereço
          </Button>
        ) : null}
        <Select
          placeholder={mainAddress?.addressDisplayName}
          defaultValue={mainAddress?.addressGuid}
          options={generateOptions(selected.addresses)}
          onChange={(_, data) => {
            setAddress(getAddress(selected.addresses, data.value!.toString()));
          }}
          onClick={() => customerSelectType(addressType)}
          onBlur={() => customerSelectType(undefined)}
        />
      </Fragment>
    );
  } else return null;
};

export default CreateTransportSelectAddress;
