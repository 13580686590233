import { observer } from "mobx-react-lite";
import React, { Fragment, useContext } from "react";
import { Button, Segment } from "semantic-ui-react";
import IsAuthorized from "../../../app/common/authorization/IsAuthorized";
import { AuthorizationRoles } from "../../../app/models/user";
import { RootStoreContext } from "../../../app/stores/rootStore";
import CompanyList from "../../company/list/CompanyList";
import SupplierCompanyList from "../create/SupplierCompanyList";

const SupplierCompanyDetails = () => {
  const rootStore = useContext(RootStoreContext);
  const { openModal } = rootStore.modalStore;
  const { company } = rootStore.companyStore;

  const { addCompany } = rootStore.supplierStore;
  return company && company.isSupplier ? (
    <Segment.Group>
      <Segment clearing>
        <IsAuthorized
          component={
            <Button
              content="Adicionar empresa"
              floated="right"
              onClick={() => openModal(<CompanyList _onClick={addCompany} />)}
              positive
            />
          }
          roles={[AuthorizationRoles.Admin]}
        />
      </Segment>
      <Segment>
        <SupplierCompanyList />
      </Segment>
    </Segment.Group>
  ) : (
    <Fragment> Esta empresa não é fornecedor. </Fragment>
  );
};

export default observer(SupplierCompanyDetails);
