import { floor } from "lodash";
import { action, computed, makeObservable, observable, runInAction } from "mobx";
import Agent from "../api/agent";
import { County, District, Locale } from "../models/geo";
import { RootStore } from "./rootStore";

export class GeoStore {
  rootStore: RootStore;

  LIMIT_BY_PAGE = 20

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @observable current_locals = new Map<number, Locale[]>();
  @observable current_counties = new Map<number, County[]>();
  @observable current_districts = new Map<number, District[]>();
  @observable rest_locals: Locale[] = [];
  @observable rest_counties: County[] = [];
  @observable rest_districts: District[] = [];
  @observable totalLocales: Locale[] = [];
  @observable loading = false;


  @observable currentPage: number = 0;
  @observable totalPages: number = 1;
  @observable totalElems: number = 0;
  @observable filter: string = "";

  @action setPage = (page: number | string) => {
    this.currentPage = Number(page) - 1;
  }

  @computed get localesByPage(): Locale[] {
    let locales = this.totalLocales;
    if (this.filter.length > 0) {
      locales = locales.filter(x => x.name.includes(this.filter))
    }
    this.setPages(locales.length)
    return locales.slice(
      this.currentPage * this.LIMIT_BY_PAGE,
      this.currentPage * this.LIMIT_BY_PAGE + this.LIMIT_BY_PAGE
    )
  }

  @action setFilter = (filter: string) => {
    this.filter = filter;
  }

  @action addRouteLocal = async (
    local: string,
    country: string,
    route: number
  ) => {
    this.loading = true;
    try {
      let locale = await Agent.Geo.getLocale(local, country);
      runInAction(() => {
        let locals = this.current_locals.get(route);
        if (locals && !this.hasLocal(local, locals)) {
          locals.push(locale);
          this.current_locals.set(route, locals);
        }
      });
      this.loading = false;
    } catch (error) {
      console.log(error);
      this.loading = false;
    }
  };

  @action addRouteCounty = async (
    county: string,
    country: string,
    route: number
  ) => {
    this.loading = true;
    try {
      let new_county = await Agent.Geo.getCounty(county, country);
      runInAction(() => {
        let counties = this.current_counties.get(route);
        if (counties && !this.hasCounty(county, counties)) {
          for (let index = 0; index < counties.length; index++) {
            const element = counties[index];
            for (let index = 0; index < element.locales.length; index++) {
              const local = element.locales[index];
              this.addRouteLocal(local.name, country, route);
            }
          }
          counties.push(new_county);
          this.current_counties.set(route, counties);
        }
      });
      this.loading = false;
    } catch (error) {
      console.log(error);
      this.loading = false;
    }
  };

  @action addRouteDistrict = async (
    district: string,
    country: string,
    route: number
  ) => {
    this.loading = true;
    try {
      let new_district = await Agent.Geo.getDistrict(district, country);
      runInAction(() => {
        let districts = this.current_districts.get(route);
        if (districts && !this.hasDistrict(district, districts)) {
          for (let index = 0; index < districts.length; index++) {
            const element = districts[index];
            for (let index = 0; index < element.counties.length; index++) {
              const county = element.counties[index];
              this.addRouteCounty(county.name, country, route);
            }
          }
          districts.push(new_district);
          this.current_districts.set(route, districts);
        }
      });
      this.loading = false;
    } catch (error) {
      console.log(error);
      this.loading = false;
    }
  };
  @action clearLocales = () => {
    this.totalLocales = [];
    this.filter = "";
  }
  @action loadLocalesNoRoute = async (routeID: number) => {
    try {
      this.loading = true;
      if (this.totalLocales.length > 0) return;
      let all_locals = await Agent.Geo.listLocalesNoRoute(routeID);
      runInAction(() => {
        const { localesDTO } = all_locals;
        this.totalLocales.push(...localesDTO);
        this.setPages(localesDTO.length)
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  };

  @action loadLocales = async () => {
    try {
      this.loading = true;
      if (this.totalLocales.length > 0) return;
      let all_locals = await Agent.Geo.listLocales();
      runInAction(() => {
        const { localesDTO } = all_locals;
        this.totalLocales.push(...localesDTO);
        this.setPages(localesDTO.length)
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  }

  @action setPages = (totalElems: number) => {
    this.totalElems = totalElems;
    this.totalPages = floor(this.totalElems / this.LIMIT_BY_PAGE);
    if (this.totalElems % this.LIMIT_BY_PAGE !== 0)
      this.totalPages++;
  }

  @action filterCounties = async (routeID: number) => {
    this.loading = true;
    try {
      let all_counties = await Agent.Geo.listCounties();
      runInAction(() => {
        let counties = this.current_counties.get(routeID);
        all_counties.forEach((county) => {
          if (counties) {
            for (let index = 0; index < counties.length; index++) {
              const element = counties[index];
              if (county.name !== element.name) {
                this.rest_counties.push(element);
              }
            }
          }
        });
      });
      this.loading = false;
    } catch (error) {
      console.log(error);
      this.loading = false;
    }
  };

  @action filterDistricts = async (routeID: number) => {
    this.loading = true;
    try {
      let all_districts = await Agent.Geo.listDistricts();
      runInAction(() => {
        let districts = this.current_districts.get(routeID);
        all_districts.forEach((district) => {
          if (districts) {
            for (let index = 0; index < districts.length; index++) {
              const element = districts[index];
              if (district.name !== element.name) {
                this.rest_districts.push(element);
              }
            }
          }
        });
      });
      this.loading = false;
    } catch (error) {
      console.log(error);
      this.loading = false;
    }
  };

  @action hasLocal = (local: string, locales: Locale[]) => {
    try {
      for (let index = 0; index < locales.length; index++) {
        const element = locales[index];

        if (element.name === local) return true;
      }
      return false;
    } catch (error) {
      console.log(error);
    }
  };

  @action hasCounty = (county: string, counties: County[]) => {
    try {
      for (let index = 0; index < counties.length; index++) {
        const element = counties[index];
        if (county === element.name) return true;
      }
      return false;
    } catch (error) {
      console.log(error);
    }
  };

  @action hasDistrict = (district: string, districts: District[]) => {
    try {
      for (let index = 0; index < districts.length; index++) {
        const element = districts[index];
        if (element.name === district) return true;
      }
      return false;
    } catch (error) {
      console.log(error);
    }
  };

  @action getRouteLocals = (routeId: number) => {
    try {
      return this.current_locals.get(routeId);
    } catch (error) {
      console.log(error);
    }
  };

  @action getRouteCounties = (countyId: number) => {
    try {
      return this.current_counties.get(countyId);
    } catch (error) {
      console.log(error);
    }
  };

  @action getRouteDistricts = (districtId: number) => {
    try {
      return this.current_districts.get(districtId);
    } catch (error) {
      console.log(error);
    }
  };
}
