import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { Button, Input, Segment } from "semantic-ui-react";
import { RootStoreContext } from "../../app/stores/rootStore";

const FuelFeeManager = () => {
  const rootStore = useContext(RootStoreContext);
  const { addFuelFee, loading } = rootStore.fuelFeeStore;
  const [newValue, setNewValue] = useState("");
  return (
    <Segment>
      <Input
        fluid
        type="number"
        label={{ basic: true, content: "€/L" }}
        labelPosition="right"
        placeholder="Valor"
        onChange={(_, data) => setNewValue(data.value)}
      />
      <Button
        color="red"
        fluid
        content="Atualizar"
        onClick={() => {
          addFuelFee(newValue);
        }}
        loading={loading}
        disabled={newValue.length === 0 || newValue === "0"}
      />
    </Segment>
  );
};

export default observer(FuelFeeManager);
