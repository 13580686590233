import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Pagination, Search, Table } from 'semantic-ui-react'
import Agent from '../../../../app/api/agent'
import { ICompany } from '../../../../app/models/company'
import { ICustomer } from '../../../../app/models/customer';
import { CreateTransportContext } from '../../../../app/stores/createTransportStore';
import { RootStoreContext } from '../../../../app/stores/rootStore';

interface IProps {
    setCustomer: React.Dispatch<React.SetStateAction<ICustomer | undefined>>;
}

const CreateTransportSelectCustomer: React.FC<IProps> = ({
    setCustomer: setCustomer,
}) => {
    const rootStore = useContext(RootStoreContext)
    const { company, setCustomer: setCustomerContext } = useContext(CreateTransportContext)
    const { closeModal } = rootStore.modalStore;
    const [customers, setCustomers] = useState<ICustomer[]>([])
    const [currentCustomers, setCurrentCustomers] = useState<ICustomer[]>([])
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1)

    useEffect(() => {
        setCurrentCustomers(customers.slice(
            page * 10,
            page * 10 + 10
        ))
        return () => {
        }
    }, [page, customers])

    const load = useCallback(
        async () => {
            if (company !== undefined) {
                let customers = await Agent.Company.customers(company?.guid)
                if (customers) {
                    setCustomers(customers.customers)
                    setTotalPages(Math.ceil(customers.customers.length / 10))
                }
            }
        },
        [],
    )

    const handleSelectCustomer = (customer: ICustomer) => {
        setCustomer(customer);
        setCustomerContext(customer);
        closeModal();
    }

    useEffect(() => {
        load() // load all companies summary list
        return () => {
            setPage(0)
            setCustomers([])
        }
    }, [])

    return (
        <>
            <Search onSearchChange={(p, c) => {
                let regex = new RegExp(`\w*${c.value}\w*`, 'i')
                setCurrentCustomers(customers.filter(
                    x =>
                        x.vatNumber.match(regex) !== null ||
                        x.companyName.match(regex) !== null ||
                        x.displayName.match(regex) !== null
                ))
            }}
                showNoResults={false}
            />
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Nome Comercial</Table.HeaderCell>
                        <Table.HeaderCell>Nome Fiscal</Table.HeaderCell>
                        <Table.HeaderCell>Contirbuinte</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {currentCustomers ? currentCustomers.map(x =>
                        <Table.Row key={x.customerGuid}>
                            <Table.Cell selectable>
                                <a onClick={() => handleSelectCustomer(x)}>
                                    {x.displayName}
                                </a>
                            </Table.Cell>
                            <Table.Cell>
                                {x.companyName}
                            </Table.Cell>
                            <Table.Cell>
                                {x.vatNumber}
                            </Table.Cell>
                        </Table.Row>
                    )
                        : <div>Nada</div>}
                </Table.Body>
            </Table>
            <Pagination
                totalPages={totalPages}
                activePage={page + 1}
                onPageChange={(_, { activePage }) => setPage(Number(activePage!))}
            />
        </>
    )
}

export default CreateTransportSelectCustomer