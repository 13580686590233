import React, { Fragment } from "react";
import { Field } from "react-final-form";
import { Button, Grid, Header, Icon, Input, Table } from "semantic-ui-react";
import { FieldArray } from "react-final-form-arrays";
import {
  composeValidators,
  isNumber,
  required,
  greaterThanOrEqual,
} from "../../../app/common/form/Validators";
import SelectInput from "../../../app/common/form/SelectInput";
import { volumeType, weightBool } from "../../../app/common/options/ServiceType";
import TextInput from "../../../app/common/form/TextInput";

interface IProps {
  push: (...args: any) => any;
}

const CreateTransportDetailsVolume: React.FC<IProps> = ({ push }) => {
  return (
    <Fragment>
      <Grid.Row columns={2}>
        <Grid.Column textAlign="right">
          <Header as="h1">Volumes</Header>
        </Grid.Column>
        <Grid.Column textAlign="left">
          <Button
            type="button"
            onClick={() =>
              push("volumes", { width: "0", height: "0", length: "0", weight: "0" })
            }
            positive
          >
            <Icon name="first order" />
            Adicionar volume
          </Button>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Table attached celled padded>
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell width={3}>Tipo de Volume</Table.HeaderCell>
              <Table.HeaderCell width={3}>Comprimento (cm)</Table.HeaderCell>
              <Table.HeaderCell width={3}>Largura (cm)</Table.HeaderCell>
              <Table.HeaderCell width={3}>Altura (cm)</Table.HeaderCell>
              <Table.HeaderCell width={3}>Peso (kg)</Table.HeaderCell>
              <Table.HeaderCell width={1}>Apagar</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <FieldArray name="volumes">
              {({ fields }) =>
                fields.map((name, index) => (
                  <Table.Row key={name}>
                    <Table.Cell>
                      <Field
                        name={`${name}.volumeType`}
                        component={SelectInput}
                        options={volumeType}
                        defaultValue={volumeType[0].value}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name={`${name}.width`}
                        type="number"
                        disabled
                      // {
                      //   fields.value[index]
                      //     ? !(fields.value[index].volumeType === "standard")
                      //     : true
                      // }
                      // {
                      //   fields.value[index] &&
                      //   !(fields.value[index].volumeType === "standard")
                      //     ? "0"
                      //     : undefined
                      // }
                      >
                        {(props) => <TextInput {...props} />}
                      </Field>
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name={`${name}.length`}
                        type="number"
                        disabled
                      // {
                      //   fields.value[index]
                      //     ? !(fields.value[index].volumeType === "standard")
                      //     : true
                      // }
                      // {
                      //   fields.value[index] &&
                      //   !(fields.value[index].volumeType === "standard")
                      //     ? "0"
                      //     : undefined
                      // }
                      >
                        {(props) => <TextInput {...props} />}
                      </Field>
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name={`${name}.height`}
                        type="number"
                        disabled
                      // {
                      //   fields.value[index]
                      //     ? !(fields.value[index].volumeType === "standard")
                      //     : true
                      // }
                      // {
                      //   fields.value[index] &&
                      //   !(fields.value[index].volumeType === "standard")
                      //     ? "0"
                      //     : undefined
                      // }
                      >
                        {(props) => <TextInput {...props} />}
                      </Field>
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name={`${name}.weight`}
                        type="number"
                        disabled={weightBool[fields.value[index].volumeType]}
                        defaultValue={
                          fields.value[index] &&
                            weightBool[fields.value[index].volumeType]
                            ? "0"
                            : undefined
                        }
                        validate={composeValidators(
                          required,
                          isNumber,
                          greaterThanOrEqual(0)
                        )}
                      >
                        {(props) => (
                          <Input
                            fluid
                            {...props}
                            label={{ basic: true, content: "kg" }}
                            labelPosition="right"
                          />
                        )}
                      </Field>
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        type="button"
                        name="delete"
                        icon="delete"
                        onClick={() => fields.remove(index)}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))
              }
            </FieldArray>
          </Table.Body>
        </Table>
      </Grid.Row>
    </Fragment>
  );
};

export default CreateTransportDetailsVolume;
