import { configure } from "mobx";
import { createContext } from "react";
import ActivityStore from "./activityStore";
import UserStore from "./userStore";
import CommonStore from "./commonStore";
import ModalStore from "./modalStore";
import CompanyStore from "./companyStore";
import AddressStore from "./addressStore";
import TransportStore from "./transportStore";
import CustomerStore from "./customerStore";
import SupplierStore from "./supplierStore";
import ReportStore from "./reportStore";
import ZoneStore from "./zoneStore";
import FuelFeeStore from "./fuelfeeStore";
import RouteStore from "./routeStore";
import WarehouseStore from "./warehouseStore";
import CourierStore from "./courierStore";
import { GeoStore } from "./geoStore";

configure({ enforceActions: "always" });

export class RootStore {
  activityStore: ActivityStore;
  userStore: UserStore;
  commonStore: CommonStore;
  modalStore: ModalStore;
  transportStore: TransportStore;
  companyStore: CompanyStore;
  addressStore: AddressStore;
  customerStore: CustomerStore;
  supplierStore: SupplierStore;
  reportStore: ReportStore;
  zoneStore: ZoneStore;
  fuelFeeStore: FuelFeeStore;
  routeStore: RouteStore;
  warehouseStore: WarehouseStore;
  courierStore: CourierStore;
  geoStore: GeoStore;

  constructor() {
    this.activityStore = new ActivityStore(this);
    this.userStore = new UserStore(this);
    this.commonStore = new CommonStore(this);
    this.modalStore = new ModalStore(this);
    this.transportStore = new TransportStore(this);
    this.companyStore = new CompanyStore(this);
    this.addressStore = new AddressStore(this);
    this.customerStore = new CustomerStore(this);
    this.supplierStore = new SupplierStore(this);
    this.reportStore = new ReportStore(this);
    this.zoneStore = new ZoneStore(this);
    this.fuelFeeStore = new FuelFeeStore(this);
    this.routeStore = new RouteStore(this);
    this.warehouseStore = new WarehouseStore(this);
    this.courierStore = new CourierStore(this);
    this.geoStore = new GeoStore(this);
  }
}

export const RootStoreContext = createContext(new RootStore());
