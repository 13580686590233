import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Pagination, Search, Table } from 'semantic-ui-react'
import Agent from '../../../../app/api/agent'
import { ICompany } from '../../../../app/models/company'
import { CreateTransportContext } from '../../../../app/stores/createTransportStore';
import { RootStoreContext } from '../../../../app/stores/rootStore';

interface IProps {
    setCompanyGuid: React.Dispatch<React.SetStateAction<ICompany | undefined>>;
}

const CreateTransportSelectCompany: React.FC<IProps> = ({ setCompanyGuid: setCompany }) => {
    const rootStore = useContext(RootStoreContext)
    const { setCompany: setCompanyContext } = useContext(CreateTransportContext)
    const { closeModal } = rootStore.modalStore;
    const [companies, setCompanies] = useState<ICompany[]>([])
    const [currentCompanies, setCurrentCompanies] = useState<ICompany[]>([])
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1)

    useEffect(() => {
        setCurrentCompanies(companies.slice(
            page * 10,
            page * 10 + 10
        ))
        return () => {
        }
    }, [page, companies])

    const load = useCallback(
        async () => {
            let companies = await Agent.Company.all()
            if (companies) {
                setCompanies(companies.companies)
                setTotalPages(Math.ceil(companies.companies.length / 10))
            }
        },
        [],
    )

    const handleSelectCompany = (company: ICompany) => {
        setCompany(company);
        setCompanyContext(company)
        closeModal();
    }

    useEffect(() => {
        load() // load all companies summary list
        return () => {
            setPage(0)
            setCompanies([])
        }
    }, [])

    return (
        <>
            <Search onSearchChange={(p, c) => {
                let regex = new RegExp(`\w*${c.value}\w*`, 'i')
                setCurrentCompanies(companies.filter(
                    x =>
                        x.vatNumber.match(regex) !== null ||
                        x.commercialName.match(regex) !== null ||
                        x.taxName.match(regex) !== null
                ))
            }}
                showNoResults={false}
            />
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Nome Comercial</Table.HeaderCell>
                        <Table.HeaderCell>Nome Fiscal</Table.HeaderCell>
                        <Table.HeaderCell>Contirbuinte</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {currentCompanies ? currentCompanies.map(x =>
                        <Table.Row key={x.guid}>
                            <Table.Cell selectable>
                                <a onClick={() => handleSelectCompany(x)}>
                                    {x.commercialName}
                                </a>
                            </Table.Cell>
                            <Table.Cell>
                                {x.taxName}
                            </Table.Cell>
                            <Table.Cell>
                                {x.vatNumber}
                            </Table.Cell>
                        </Table.Row>
                    )
                        : <div>Nada</div>}
                </Table.Body>
            </Table>
            <Pagination
                totalPages={totalPages}
                activePage={page + 1}
                onPageChange={(_, { activePage }) => setPage(Number(activePage!))}
            />
        </>
    )
}

export default observer(CreateTransportSelectCompany)