
import { observer } from 'mobx-react-lite';
import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react'
import { UseFormRegister, FieldValues, FieldErrorsImpl, UseFormSetValue } from 'react-hook-form';
import { Button, Card, Grid, Header, Icon, Label, Loader, Segment, SemanticWIDTHS } from 'semantic-ui-react';
import Agent from '../../../../app/api/agent';
import { AddressFormValues, IAddress } from '../../../../app/models/address';
import { ICustomer } from '../../../../app/models/customer';
import { CreateTransportContext } from '../../../../app/stores/createTransportStore';
import { RootStoreContext } from '../../../../app/stores/rootStore';
import CustomerDetails from '../../../customer/details/CustomerDetails';
import CreateTransportSelectCustomer from './CreateTransportSelectCustomer';

interface IProps {
    errors: Partial<FieldErrorsImpl<{
        [x: string]: any;
    }>>;
    setValue: UseFormSetValue<FieldValues>
    register: UseFormRegister<FieldValues>;
}

const CreateTransportTo: React.FC<IProps> = ({
    register,
    errors,
    setValue,
}) => {
    const rootStore = useContext(RootStoreContext)
    const { company, customer } = useContext(CreateTransportContext)
    const { openModal } = rootStore.modalStore;

    const [selectedCustomer, setselectedCustomer] = useState<ICustomer | undefined>(undefined)
    const [addresses, setAddresses] = useState<IAddress[]>([])
    const [loadingAddresses, setloadingAddresses] = useState(false)
    const [selectedAddress, setSelectedAddress] = useState<string | undefined>(undefined)
    const loadAddresses = useCallback(
        async () => {
            try {
                setloadingAddresses(true)
                if (selectedCustomer !== undefined) {
                    let customer = await Agent.Customer.details(selectedCustomer.customerGuid)
                    console.log(customer)
                    setAddresses(customer.addresses)
                    let address = customer.addresses.filter(x => x.isMain)[0]
                    setValue('addressTo', address)
                    setSelectedAddress(address.addressGuid)
                }
            } catch (error) {
            } finally {
                setloadingAddresses(false)
            }
        },
        [selectedCustomer, setAddresses],
    )

    useEffect(() => {
        setselectedCustomer(undefined)
        setValue('addressTo', new AddressFormValues())
    }, [company])


    useEffect(() => {
        setAddresses([])
        loadAddresses();
        if (company === undefined) console.log('company', company)
        return () => {

        }
    }, [customer, selectedCustomer])

    return (
        <Fragment>
            <Grid>
                <Grid.Row columns={1}>
                    <Grid.Column textAlign='center'>
                        <Header as="h1">
                            Remetente
                        </Header>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Segment>
                <Grid>
                    <Grid.Row columns={4}>
                        <Grid.Column textAlign='left'>
                            {company === undefined ?
                                <div><b>Falta selecionar a empresa</b></div>
                                :
                                <Button
                                    onClick={() => {
                                        openModal(<CreateTransportSelectCustomer setCustomer={setselectedCustomer} />)
                                    }}
                                    secondary
                                    content="Selecionar cliente"
                                />
                            }
                        </Grid.Column>
                        <Grid.Column width={12} verticalAlign='top'>
                            {company === undefined ?
                                null
                                :
                                selectedCustomer !== undefined ?
                                    <CustomerDetails customer={selectedCustomer} />
                                    :
                                    <p>
                                        Falta selecionar cliente
                                    </p>
                            }

                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <Loader active={loadingAddresses} />
                            <Card.Group itemsPerRow={Math.min(4, Math.max(addresses.length, 1)) as SemanticWIDTHS}>
                                {addresses && addresses.length > 0 &&
                                    addresses && addresses.length > 0 ?
                                    addresses.map(x =>
                                        <Card key={x.addressGuid}
                                            color={selectedAddress === x.addressGuid ? 'red' : 'grey'}
                                            fluid
                                            onClick={() => {
                                                setValue('addressTo.addressGuid', x.addressGuid)
                                                setSelectedAddress(x.addressGuid)
                                            }}
                                        >
                                            <Card.Content>

                                                <Card.Header>
                                                    {/* {selectedAddress === x.addressGuid ? <Icon name='truck' color='red' /> : null} */}
                                                    <b>
                                                        {x.addressDisplayName}
                                                    </b>
                                                </Card.Header>
                                                <Card.Meta>
                                                    {x.contactName} : {x.phone}
                                                </Card.Meta>
                                                <Card.Description>
                                                    <p>
                                                        {x.addressFirst}
                                                        <br />{x.addressSecond}
                                                    </p>
                                                    {x.PostcodeLocaleName}
                                                </Card.Description>
                                                {selectedAddress === x.addressGuid ? <Label ribbon='right' color='green' ><Icon name='paper plane' /> </Label> : null}
                                            </Card.Content>
                                        </Card>
                                    ) :
                                    null}
                            </Card.Group>



                        </Grid.Column>

                    </Grid.Row>
                </Grid>
            </Segment>
        </Fragment>
    )
}

export default observer(CreateTransportTo)