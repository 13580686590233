export interface IAddress {
    addressGuid: string;
    addressDisplayName: string;
    contactName: string;
    phone: string;
    addressFirst: string;
    addressSecond: string;
    leftPostCode: string;
    rightPostCode: string;
    location: string;
    country: string;
    isMain: boolean;
    postcode: string;
    PostcodeNumcode: number;
    PostcodeExtcode: number;
    PostcodeLocaleName: string;

}

export interface IAddressApp {
    addressGuid: string
    displayName: string;
    contactName: string
    phone: string
    addressFirst: string
    addressSecond: string
    postcodeToString: string
    postcodeLocaleName: string
    postcodeRouteId: string

}

export interface IAddressFormValues extends Partial<IAddress> {
    customerGuid?: string;
}

export class AddressFormValues implements IAddressFormValues {
    addressGuid?: string = undefined;
    customerGuid?: string = undefined;
    addressDisplayName: string = '';
    contactName: string = '';
    phone: string = '';
    addressFirst: string = '';
    addressSecond: string = '';
    postcode: string = '';
    leftPostCode: string = '';
    rightPostCode: string = '';
    location: string = '';
    country: string = 'Portugal';
    isMain: boolean = true;

    constructor(init?: IAddressFormValues) {
        Object.assign(this, init);
    }
}