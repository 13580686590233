import React from "react";
import { ICompany } from "../../../app/models/company";
import { Table } from "semantic-ui-react";
import CompanyTableRow from "./CompanyTableRow";
import CompanyTableHeader from "./CompanyTableHeader";
import TableItemPlaceholder from "../../../app/common/placeholder/TableItemPlaceholder";
import TableItemNotFound from "../../../app/common/placeholder/TableItemNotFound";

interface IProps {
  companies: ICompany[];
  loading: boolean;
}
const CompanyTable: React.FC<IProps> = ({ companies, loading }) => {
  return (
    <Table compact celled>
      <CompanyTableHeader companies={companies} />
      <Table.Body>
        {loading ? (
          <TableItemPlaceholder colSpan="6" />
        ) : companies.length > 0 ? (
          companies.map((company) => (
            <CompanyTableRow key={company.guid} company={company} />
          ))
        ) : (
          <TableItemNotFound
            content="Não existem empresas com os parâmetros inseridos."
            colSpan="6"
          />
        )}
      </Table.Body>
    </Table>
  );
};

export default CompanyTable;
