import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Segment,
  SegmentGroup,
} from "semantic-ui-react";
import { Field, Form as FinalForm } from "react-final-form";
import { RootStoreContext } from "../../../app/stores/rootStore";
import {
  composeValidators,
  exactLength,
  greaterThanOrEqual,
  isNumber,
  isPostCode,
  maxLength,
  minLength,
  required,
} from "../../../app/common/form/Validators";
import TextInput from "../../../app/common/form/TextInput";
import { WarehouseFormValues } from "../../../app/models/warehouse";
import { FORM_ERROR } from "final-form";
import { Console } from "console";

const WarehouseCreateModal: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { closeModal } = rootStore.modalStore;
  const { createWarehouse, submitting, loading } = rootStore.warehouseStore;
  const [initialValues] = useState(new WarehouseFormValues());

  return (
    <FinalForm
      onSubmit={async (values: WarehouseFormValues) => {
        try {
          await createWarehouse(values);
          closeModal()
          // window.location.reload()
          
        } catch (error) {
          return { [FORM_ERROR]: error }
        }
      }}
      loading={loading}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Segment.Group>
            <SegmentGroup horizontal>
              <Segment>
                <Header size="small" content="Nome do armazém*" />
                <Field
                  name="name"
                  placeholder="Armazém #1"
                  maxLength={40}
                  validate={composeValidators(
                    required,
                    maxLength(40),
                    minLength(3)
                  )}
                >
                  {(props) => <TextInput {...props} />}
                </Field>
              </Segment>
              <Segment>
                <Header size="small" content="Abreviatura do Armazém*" />
                <Field
                  name="abbreviation"
                  placeholder="..."
                  maxLength={10}
                  validate={composeValidators(required, maxLength(10))}
                >
                  {(props) => <TextInput {...props} />}
                </Field>
              </Segment>
            </SegmentGroup>
            <Segment>
              <Header size="small" content="Endereço*" />
              <Field
                name="address"
                placeholder="Endereço #1"
                maxLength={40}
                validate={composeValidators(required, maxLength(40))}
              >
                {(props) => <TextInput {...props} />}
              </Field>
            </Segment>
            <Segment>
              <Header size="small" content="Código postal" />
              <Field
                name="postcode"
                placeholder="XXXX-XXX"
                maxLength={8}
                validate={composeValidators(
                  isPostCode
                )}
              >
                {(props) => <TextInput {...props} />}
              </Field>
            </Segment>
          </Segment.Group>
          <Grid>
            <Grid.Column>
              <Button
                type="submit"
                loading={submitting}
                disabled={submitting}
                color="green"
                floated="right"
                positive
              >
                Salvar
              </Button>
              <Button floated="right" onClick={() => closeModal()}>
                Voltar
              </Button>
            </Grid.Column>
          </Grid>
        </Form>
      )}
    ></FinalForm>
  );
};

export default observer(WarehouseCreateModal);
