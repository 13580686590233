import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Table } from "semantic-ui-react";
import { getMainAddress } from "../../../app/dataAccess/AddressData";
import { getCompanyAddressList } from "../../../app/dataAccess/CompanyData";
import { ICompany } from "../../../app/models/company";
import { RootStoreContext } from "../../../app/stores/rootStore";

interface IProps {
  selectCustomer: (...args: any[]) => any;
  company: ICompany;
}

const SupplierCompanyListItem: React.FC<IProps> = ({
  selectCustomer,
  company,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { addCustomer, setDebit } = rootStore.transportStore;
  const { closeModal } = rootStore.modalStore;
  const { loading } = rootStore.supplierStore;

  const handleSelect = (company: ICompany) => {
    const addresses = getCompanyAddressList(company);
    addCustomer(company);
    setDebit(company.vatNumber);
    selectCustomer({ ...getMainAddress(addresses) });
    closeModal();
  };
  return (
    <Table.Row
      disabled={loading}
      key={company.guid}
      onClick={() => handleSelect(company)}
    >
      <Table.Cell>{company.commercialName}</Table.Cell>
      <Table.Cell>{company.taxName}</Table.Cell>
      <Table.Cell>{company.vatNumber}</Table.Cell>
    </Table.Row>
  );
};

export default observer(SupplierCompanyListItem);
