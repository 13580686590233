import _ from "lodash";
import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction,
} from "mobx";
import { reportAPI, transportDocAPI } from "../api/ReportRequest";
import { openUriPdf } from "../common/util/exportPdf";
import { ReportType, TransportDocument } from "../models/report";
import { RootStore } from "./rootStore";

export default class ReportStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @observable selectedCompanies: string[] = []; //store selected company guid
  @observable predicate = new Map<string, string>();
  @observable loading = new Map<ReportType | TransportDocument, boolean>();

  @computed get axiosParams() {
    const params = new URLSearchParams();
    params.append("limit", "-1");
    this.predicate.forEach((value, key) =>
      params.append(key, value.toString())
    );
    return params;
  }

  @computed get getSelectedCompanies() {
    return this.selectedCompanies;
  }
  @action setPredicate = (key: string, value: string) => {
    this.predicate.set(key, value);
  };
  /**
   *
   * @param guid companyGuid do search
   * @returns true if find; false otherwise
   */
  @action searchCompany = (guid: string) => {
    return (
      this.selectedCompanies.find((company) => company === guid) !== undefined
    );
  };

  @action resetCompany = () => {
    this.selectedCompanies = [];
  };

  @action insertCompany = (guid: string) => {
    if (!this.searchCompany(guid)) this.selectedCompanies.push(guid);
  };

  @action removeCompany = (guid: string) => {
    this.selectedCompanies = _.remove(
      this.selectedCompanies,
      (each) => each !== guid
    );
  };

  @action generateReport = async (
    companyGuids: string[],
    docType: ReportType
  ) => {
    this.loading.set(docType, true);
    const params = this.axiosParams;
    //companyGuids.forEach((guid) => params.append("companyGuid", guid));
    try {
      let uriFile = await reportAPI[docType](params, companyGuids);
      runInAction(() => {
        openUriPdf(uriFile);
      });
      this.loading.set(docType, false);
    } catch (error) {
      this.loading.set(docType, false);
    }
  };

  @action generateTransportDoc = async (
    transportGuid: string[],
    docType: TransportDocument
  ) => {
    this.loading.set(docType, true);
    try {
      const uriFile = await transportDocAPI[docType](transportGuid);
      runInAction(() => {
        openUriPdf(uriFile);
        this.loading.set(docType, false);
      });
    } catch (error) {
      this.loading.set(docType, false);
    }
  };
}
