export const PT_pt = {
  translate: {
    welcome: "Bem vindo",
    login: "Entrar",
    vatNumber: "Contribuinte",
    hasAgreement: "Tem contrato?",
    monthQuantity: "Quantidade mensal",
    groupVolumes: "Agrupar volumes",
    username: "utilizador",
    email: "Email",
    phone: "Contacto fixo ou móvel",
    isSupplier: "É fornecedor?"
  },
  errors: {
    submitting: "Erro ao enviar dados.",
  },
  address:{
    address: "endereço",
    
  },
  company: {
    vatNumber: "contribuinte",
    taxName: "nome fiscal",
    commercialName: "nome comercial",
    main: "sede",
    
  }
};
