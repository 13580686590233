export interface PostCode {
  id: number;
  numCode: number;
  extCode: number;
  localeName: string;
}

export interface IPostcodeRoute extends PostCode {
  hasRoute: boolean;
}

export interface Locale {
  id: number;
  name: string;
}
export interface LocaleDetailed extends Locale {
  postcodes: PostCode[];
}

export interface County {
  name: string;
  locales: Locale[];
}

export interface District {
  name: string;
  counties: County[];
}


export class PostCodeRangeFormValues {
  all : string = "";
  NumCode: string = "";
  ExtCodeBegin: string = "";
  ExtCodeEnd: string = "";

  constructor(init?: PostCodeRangeFormValues) {
    Object.assign(this, init);
  }
}

export interface IPostCodeEnvelope {
  postCodes: PostCode[];
  postCodeCount: number;
}

export interface ILocalEnvelope {
  locals: Locale[];
  localCount: number;
}

export interface ICountyEnvelope {
  counties: County[];
  countyCount: number;
}

export interface IDistrictEnvelope {
  districts: District[];
  districtCount: number;
}
