import { useContext, useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form';
import { Button, Form } from 'semantic-ui-react';
import { KVolumeType, TransportFormValues } from '../../../../app/models/transport';
import { RootStoreContext } from '../../../../app/stores/rootStore';
import CreateTransportAdminVolume from './CreateTransportAdminVolume';
import CreateTransportFrom from './CreateTransportFrom';
import CreateTransportSelectCompany from './CreateTransportSelectCompany';
import CreateTransportTo from './CreateTransportTo';

const CreateTransportAdmin = () => {
  const [formValues, setFormValues] = useState(new TransportFormValues())

  const { control, watch, handleSubmit, reset, register, setValue, formState: { errors } } = useForm({ mode: 'all' });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'volumes',
    rules: { minLength: 1 }
  });
  const volumesArray = watch("volumes");
  const volumesFields = fields.map((field, index) => {
    return {
      ...field,
      ...volumesArray[index]
    };
  });

  const changeVolumeType = (index: number, value: KVolumeType) => {
    setValue(`volumes.${index}.volumeType` as any, value)
  }

  useEffect(() => {
    reset(formValues)
  }, [])


  const submitHandler = (data: any) => {
    console.log(data)
  }
  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <CreateTransportFrom
        errors={errors}
        register={register}
        setValue={setValue}
      />
      <CreateTransportTo
        errors={errors}
        register={register}
        setValue={setValue}
      />
      <CreateTransportAdminVolume fields={volumesFields}
        append={append}
        remove={remove}
        register={register}
        errors={errors}
        changeVolumeType={changeVolumeType}
      />
    </Form>
  )
}

export default CreateTransportAdmin