import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { Button, Checkbox, Table } from "semantic-ui-react";
import { ICompany } from "../../../app/models/company";
import { RootStoreContext } from "../../../app/stores/rootStore";

const CompanyTableRow: React.FC<{ company: ICompany }> = ({ company }) => {
  const rootStore = useContext(RootStoreContext);
  const {
    insertCompany,
    removeCompany,
    searchCompany,
    selectedCompanies,
    generateReport,
    loading,
  } = rootStore.reportStore;

  const [loadIndividual, setLoadIndividual] = useState(false);

  const handleClick = (guid: string) => {
    if (searchCompany(guid)) removeCompany(guid);
    else insertCompany(guid);
  };

  return (
    <Table.Row>
      <Table.Cell collapsing>
        <Checkbox
          checked={
            selectedCompanies.find((guid) => guid === company.guid) !==
            undefined
          }
          onClick={() => handleClick(company.guid)}
        />
      </Table.Cell>
      <Table.Cell>{company.vatNumber}</Table.Cell>
      <Table.Cell>{company.taxName}</Table.Cell>
      <Table.Cell>{company.commercialName}</Table.Cell>
      <Table.Cell>
        <Button
          circular
          inverted
          loading={
            loading.get("detailedDebit") &&
            (searchCompany(company.guid) || loadIndividual)
          }
          color="red"
          size="small"
          icon="briefcase"
          onClick={async () => {
            setLoadIndividual(true);
            await generateReport([company.guid], "detailedDebit");
            setLoadIndividual(false);
          }}
        />
      </Table.Cell>
      <Table.Cell>
        <Button
          circular
          loading={
            loading.get("detailedTransport") &&
            (searchCompany(company.guid) || loadIndividual)
          }
          color="grey"
          size="small"
          icon="car"
          onClick={async () => {
            setLoadIndividual(true);
            await generateReport([company.guid], "detailedTransport");
            setLoadIndividual(false);
          }}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default observer(CompanyTableRow);
