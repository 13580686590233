import React, { useContext } from 'react'
import { RootStoreContext } from '../../../app/stores/rootStore';
import { Field, Form as FinalForm } from 'react-final-form'
import { Button, Form, Segment } from 'semantic-ui-react';
import { IEditRouteQuery, RouteEditFormValues } from '../../../app/models/route';
import TextInput from '../../../app/common/form/TextInput';
import { composeValidators, required } from '../../../app/common/form/Validators';
import { observer } from 'mobx-react-lite';

interface IProps {
    oldName: string;
}

const EditRoute: React.FC<IProps> = ({ oldName }) => {
    const rootStore = useContext(RootStoreContext)
    const { editRoute, submitting } = rootStore.routeStore;
    const { closeModal } = rootStore.modalStore;
    return (
        <FinalForm
            onSubmit={async (values: IEditRouteQuery) => {
                await editRoute(values);
                closeModal();
            }}
            initialValues={new RouteEditFormValues(oldName)}
            render={({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                    <Segment.Group>
                        <Segment>
                            <Field
                                name={'newName'}
                                placeholder='Novo nome rota'
                                validate={composeValidators(
                                    required
                                )}
                            >
                                {(props) => <TextInput {...props} />}
                            </Field>
                        </Segment>
                        <Segment>

                            <Button
                                type="submit"
                                color="green"
                                loading={submitting}
                                disabled={submitting}
                                positive
                            >
                                Salvar
                            </Button>
                            <Button onClick={closeModal}>
                                Voltar
                            </Button>
                        </Segment>

                    </Segment.Group>

                </Form>
            )} >

        </FinalForm>
    )
}

export default observer(EditRoute)