import { observer } from "mobx-react-lite";
import React, { Fragment, useContext } from "react";
import { Button, List, Message, Segment } from "semantic-ui-react";
import { IRoute } from "../../../../app/models/route";
import { RootStoreContext } from "../../../../app/stores/rootStore";
import WarehouseAddRoutes from "./WarehouseAddRoutes";

interface IProps {
  warehouseId: number;
  routes?: IRoute[];
}

const WarehouseRouteList: React.FC<IProps> = ({ warehouseId, routes }) => {
  const root = useContext(RootStoreContext)
  const { openModal } = root.modalStore
  return (
    <Fragment>
      <Segment.Group>
        <Segment>
          <List divided verticalAlign="middle">
            {routes && routes.length > 0 ? (
              routes.map((route) => (
                <List.Item key={route.id}>
                  <List.Content><a href={`/route/${route.id}`}>{route.name}</a></List.Content>
                </List.Item>
              ))
            ) : (
              <Message error>
                Sem rotas associadas!
              </Message>
            )}
          </List>
        </Segment>
        <Segment>
          <Button
            fluid
            color="yellow"
            onClick={() => openModal(<WarehouseAddRoutes warehouseId={warehouseId} />)} >
            Adicionar rotas
          </Button>
        </Segment>
      </Segment.Group>
    </Fragment>
  );
};

export default observer(WarehouseRouteList);
