import React from "react";
import { Segment, Header, Icon } from "semantic-ui-react";

const ListItemNotFound: React.FC<{ content: string }> = ({ content }) => {
  return (
    <Segment placeholder>
      <Header icon>
        <Icon name="search" />
        {content}
      </Header>
    </Segment>
  );
};

export default ListItemNotFound;
