import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Divider, Form, Grid, Header, Segment } from "semantic-ui-react";
import { CustomerFormValues } from "../../../app/models/customer";
import { RootStoreContext } from "../../../app/stores/rootStore";
import Agent from "../../../app/api/agent";
import { composeValidators, exactLength, isEmail, isNumber, isNumberLength, isPostCode, isVatNumber, maxLength, minLength, required } from "../../../app/common/form/Validators";
import { useForm } from 'react-hook-form'
import countryOpt from "../../../app/common/options/CountryOptions";
import FormTextInput from "../../../app/common/form/FormTextInput";
import { ErrorMessage } from '@hookform/error-message';


const isMainOpt = [
  { key: "true", text: "Sim", value: true },
  { key: "false", text: "Não", value: false },
]
interface IProps {
  addressPrefix?: string;
  onSubmit: (...args: any[]) => any;
}

const CreateCustomer: React.FC<IProps> = ({ onSubmit, addressPrefix }) => {
  const rootStore = useContext(RootStoreContext);
  const {
    createCustomer,
    customerSelectType,
    loading,
  } = rootStore.customerStore;
  const { addCustomer } = rootStore.transportStore;
  const { closeModal } = rootStore.modalStore;

  const [load, setload] = useState(false)
  const [formValues, setformValues] = useState(new CustomerFormValues())

  const { register, reset, handleSubmit, watch, setError, setValue, getValues, formState: { isDirty, errors }, control } = useForm();
  const postcode = watch('address.postcode')

  useEffect(() => {
    setformValues(new CustomerFormValues())
  }, [])


  useEffect(() => {
    return function cleanup() {
      customerSelectType(undefined);
      setformValues(new CustomerFormValues())
    };
  }, [customerSelectType]);

  async function submitHandler(data: any) {
    console.log(data)
    try {
      let customer = await createCustomer(data);

      if (addressPrefix && customer) {
        addCustomer(customer);
        onSubmit(customer.addresses[0]);
      }
    } catch (error) {
      setError('submit', { type: 'manual', message: JSON.stringify(error) });
    }

  }

  useEffect(() => {
    reset(formValues)
  }, [formValues])

  useEffect(() => {
    updateLocale(postcode)
  }, [postcode])

  const updateLocale = async (postcode: string) => {
    if (postcode && postcode.match(/\d{4}-?\d{3}/) !== null) {
      console.log(getValues())
      try {
        setload(true)
        let postcodeApi = await Agent.Geo.getPostCode(postcode, 'pt')
        if (!postcodeApi.hasRoute) {
          setError('address.postcode', { type: 'manual', message: 'O código postal não está abrangido. Contacte a empresa.' })
        } else {
          setValue('address.location', postcodeApi.localeName);
          // setformValues(prevstate => {
          //   prevstate['address']['location'] = postcodeApi.localeName;
          //   return { ...prevstate }
          // })
        }
      } catch (error) {
        setError('address.postcode', { type: 'manual', message: 'O código postal não existe. Contacte a empresa!' })
      }
      finally {
        setload(false)
      }
    } else {
      setValue('address.location', '');
      // setformValues(prevstate => {
      //   let s = getValues();
      //   s['address']['location'] = "";
      //   return { ...prevstate }
      // })

    }
  }

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <Segment.Group>
        <Segment>
          <Header>Dados do cliente</Header>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Header size="small" content="Nome cliente" />
                <FormTextInput
                  maxLength={200}
                  name="displayName"
                  placeholder="Nome do cliente"
                  validation={composeValidators(required, maxLength(200))}
                  errors={errors}
                  register={register}
                />


                <Header size="small" content="Empresa - Nome fiscal" />
                <FormTextInput
                  maxLength={200}
                  name="companyName"
                  placeholder="Nome contacto"
                  validation={composeValidators(required, maxLength(200))}
                  errors={errors}
                  register={register}
                />

                <Header size="small" content="Contribuinte" />
                <FormTextInput
                  name="vatNumber"
                  placeholder="Contribuinte"
                  validation={composeValidators(required, isVatNumber)}
                  errors={errors}
                  register={register}
                  maxLength={9}
                />
              </Grid.Column>
              <Grid.Column>
                <Header size="small" content="Telefone" />
                <FormTextInput
                  name="phoneNumber"
                  placeholder="Telefone"
                  validation={composeValidators(required, isNumberLength(9))}
                  errors={errors}
                  register={register}
                  maxLength={9}
                />

                <Header size="small" content="Telemóvel" />
                <FormTextInput
                  name="mobileNumber"
                  placeholder="Telemóvel"
                  validation={composeValidators(isNumberLength(9))}
                  errors={errors}
                  register={register}
                />

                <Header size="small" content="Email" />
                <FormTextInput
                  name="email"
                  placeholder="Email"
                  validation={composeValidators(required, isEmail)}
                  errors={errors}
                  register={register}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Divider horizontal />
        <Segment>
          <Header>Dados da Loja</Header>
          <Grid>
            <Grid.Column width={8}>
              <Header size="small" content="Nome da Empresa/ Destinatário" />
              <FormTextInput
                name="address.addressDisplayName"
                placeholder="Nome do destinatário (LOJA X)"
                validation={composeValidators(required, minLength(2), maxLength(45))}
                errors={errors}
                register={register}
                maxLength={45}
              />
              <Header size="small" content="Nome contacto" />
              <FormTextInput
                name="address.contactName"
                placeholder="Nome contacto"
                validation={composeValidators(required, minLength(3), maxLength(50))}
                errors={errors}
                register={register}
              />
              <Header size="small" content="Contacto telefónico" />
              <FormTextInput
                name="address.phone"
                placeholder="Contacto telefónico"
                validation={composeValidators(required, isNumber, exactLength(9))}
                errors={errors}
                register={register}
                maxLength={9}
              />
              <Header size="small" content="Endereço linha 1:" />
              <FormTextInput
                name="address.addressFirst"
                placeholder="Endereço linha 1"
                validation={composeValidators(required, maxLength(50))}
                errors={errors}
                register={register}
              />
              <Header size="small" content="Endereço linha 2 - opcional" />
              <FormTextInput
                name="address.addressSecond"
                placeholder="Endereço linha 2 - opcional"
                validation={composeValidators(maxLength(50))}
                errors={errors}
                register={register}
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Header size="small" content="Código Postal:" />
                    <FormTextInput
                      name="address.postcode"
                      placeholder="XXXX-XXX"
                      validation={composeValidators(required, isPostCode)}
                      errors={errors}
                      register={register}
                    />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Header size="small" content="Localidade:" />
                    <FormTextInput
                      name="address.location"
                      placeholder="Localização"
                      validation={() => undefined}
                      errors={errors}
                      register={register}
                      loading={load}
                      disabled
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Header size="small" content="País:" />
              <select
                {...register('address.country')}
                // component={}
                defaultValue="Portugal"

                placeholder={countryOpt[0].text}
              // validate={required}
              >
                {countryOpt.map(x => <option key={x.key} selected={x.key === 'pt_PT'} value={x.key}>{x.text}</option>)}

              </select>
              <Header size="small" content="Endereço principal?" />
              <input
                type="checkbox"
                {...register('address.isMain')}
                placeholder={isMainOpt[0].text}
              />
            </Grid.Column>
          </Grid>
          {/* <AddressFields updateLocale={updateLocale} prefix={addressPrefix || "address"} /> */}
        </Segment>
        <Segment>
          <Button.Group fluid>
            <Button type="button" onClick={closeModal} content="Cancelar" />
            <Button.Or text="ou" />
            <Button
              floated="right"
              type="submit"
              positive
              loading={loading}
              disabled={loading || isDirty}
              content="Criar cliente"
              color="teal"
            />
          </Button.Group>
        </Segment>
      </Segment.Group>
    </Form>
  )
};

export default observer(CreateCustomer);
