import React, { useContext } from 'react'
import { RootStoreContext } from '../../../../app/stores/rootStore';
import WarehouseSelectableList from '../../../warehouse/list/WarehouseSelectableList';

interface IProps {
    courierId: string;
    courierWarehouseId: number | null;
}

const CourierSetWarehouse: React.FC<IProps> = ({ courierId, courierWarehouseId }) => {
    const rootStore = useContext(RootStoreContext);
    const { addCouriers } = rootStore.warehouseStore;
    const updateWarehouse = (courierId: string) => async (warehouseId: number): Promise<any> => {
        console.log("entrei")
        await addCouriers(warehouseId, [courierId])
    }
    return (
        <WarehouseSelectableList updateFunc={updateWarehouse(courierId)} currentWarehouse={courierWarehouseId} />
    )
}

export default CourierSetWarehouse