import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Field, Form as FinalForm } from "react-final-form";
import { Button, Form, Header, Segment } from "semantic-ui-react";
import TextInput from "../../../app/common/form/TextInput";
import {
  composeValidators,
  exactLength,
  greaterThanOrEqual,
  isNumber,
  maxLength,
  minLength,
  required,
} from "../../../app/common/form/Validators";
import { IZone } from "../../../app/models/zone";
import { RootStoreContext } from "../../../app/stores/rootStore";

const CreateZone: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { createZone } = rootStore.zoneStore;

  return (
    <FinalForm
      onSubmit={(values: IZone) => createZone(values)}
      render={({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Segment.Group>
            <Segment>
              <Header size="small" content="Nome da zona*" />
              <Field
                name="displayName"
                parse={(value) => (value ? value.toUpperCase() : "")}
                placeholder="Zona A - 1"
                maxLength={40}
                validate={composeValidators(required, maxLength(40), minLength(3))}
              >
                {(props) => <TextInput {...props} />}
              </Field>
            </Segment>
            <Segment.Group horizontal>
              <Segment>
                <Header size="small" content="Código postal esquerda*" />
                <Field
                  name="leftPostCodeStart"
                  placeholder="XXXX"
                  maxLength={4}
                  validate={composeValidators(required, exactLength(4), isNumber, greaterThanOrEqual(0))}
                >
                  {(props) => <TextInput {...props} />}
                </Field>
              </Segment>
              <Segment>
                <Header
                  size="small"
                  content="Código postal direita - início*"
                />
                <Field
                  name="rightPostCodeStart"
                  placeholder="XXX"
                  maxLength={3}
                  validate={composeValidators(required, exactLength(3), isNumber, greaterThanOrEqual(0))}
                >
                  {(props) => <TextInput {...props} />}
                </Field>
                <Header size="small" content="Código postal direita - fim*" />
                <Field
                  name="rightPostCodeEnd"
                  placeholder="XXX"
                  maxLength={3}
                  validate={composeValidators(required, exactLength(3), isNumber, greaterThanOrEqual(0))}
                >
                  {(props) => <TextInput {...props} />}
                </Field>
              </Segment>
            </Segment.Group>
            <Segment>
              <Header size="small" content="Localização*" />
              <Field
                name="location"
                placeholder="Localidade"
                maxLength={50}
                parse={(value) => (value ? value.toUpperCase() : "")}
                validate={composeValidators(required, maxLength(50))}
              >
                {(props) => <TextInput {...props} />}
              </Field>
            </Segment>
          </Segment.Group>
          <Button fluid color="green">
            <Button.Content>Criar</Button.Content>
          </Button>
        </Form>
      )}
    ></FinalForm>
  );
};

export default observer(CreateZone);
