import { observer } from "mobx-react-lite";
import React from "react";
import { Field } from "react-final-form";
import { FieldErrorsImpl, FieldValues, UseFormRegister } from "react-hook-form";
import { Form, Grid, Header, Input } from "semantic-ui-react";
import { countryOpt } from "../options/CountryOptions";
import FormTextInput from "./FormTextInput";
import SelectInput from "./SelectInput";
import TextInput from "./TextInput";
import {
  composeValidators,
  exactLength,
  isNumber,
  isPostCode,
  maxLength,
  minLength,
  required,
} from "./Validators";

interface IProps {
  prefix: string;
  updateLocale?: (...args: any) => any
  localeName?: string;
  register: UseFormRegister<FieldValues>;
  errors: Partial<FieldErrorsImpl<{ [x: string]: any; }>>;
  loadLocale: boolean;
}

const isMainOpt = [
  { key: "true", text: "Sim", value: true },
  { key: "false", text: "Não", value: false },
]

const AddressFields: React.FC<IProps> = ({ prefix, errors, register, loadLocale }) => {
  return (
    <Grid>
      <Grid.Column width={8}>
        <Header size="small" content="Nome da Empresa/ Destinatário" />
        <FormTextInput
          name={`${prefix}.addressDisplayName`}
          placeholder="Nome do destinatário (LOJA X)"
          validation={composeValidators(required, minLength(2), maxLength(45))}
          errors={errors}
          register={register}
          maxLength={45}
        />
        <Header size="small" content="Nome contacto" />
        <FormTextInput
          name={`${prefix}.contactName`}
          placeholder="Nome contacto"
          validation={composeValidators(required, minLength(3), maxLength(50))}
          errors={errors}
          register={register}
        />
        <Header size="small" content="Contacto telefónico" />
        <FormTextInput
          name={`${prefix}.phone`}
          placeholder="Contacto telefónico"
          validation={composeValidators(required, isNumber, exactLength(9))}
          errors={errors}
          register={register}
          maxLength={9}
        />
        <Header size="small" content="Endereço linha 1:" />
        <FormTextInput
          name={`${prefix}.addressFirst`}
          placeholder="Endereço linha 1"
          validation={composeValidators(required, maxLength(50))}
          errors={errors}
          register={register}
        />
        <Header size="small" content="Endereço linha 2 - opcional" />
        <FormTextInput
          name={`${prefix}.addressSecond`}
          placeholder="Endereço linha 2 - opcional"
          validation={composeValidators(maxLength(50))}
          errors={errors}
          register={register}
        />
      </Grid.Column>
      <Grid.Column width={8}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <Header size="small" content="Código Postal:" />
              <FormTextInput
                name={`${prefix}.postcode`}
                placeholder="XXXX-XXX"
                validation={composeValidators(required, isPostCode)}
                errors={errors}
                register={register}
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <Header size="small" content="Localidade:" />
              <FormTextInput
                name={`${prefix}.location`}
                placeholder="Localização"
                validation={() => undefined}
                errors={errors}
                register={register}
                loading={loadLocale}
                disabled
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Header size="small" content="País:" />
        <select
          {...register(`${prefix+'.country'}` as const)}
          // component={}
          defaultValue="pt_PT"
          placeholder={countryOpt[0].text}
        // validate={required}
        >
          {countryOpt.map(x => <option key={x.key} value={x.text}>{x.text}</option>)}

        </select>
        <Header size="small" content="Endereço principal?" />
        <input
          type="checkbox"
          {...register(`${prefix+'.isMain'}` as const)}
          placeholder={isMainOpt[0].text}
        />
      </Grid.Column>
    </Grid>
  );
};

export default observer(AddressFields);
