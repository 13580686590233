import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Button, Icon, Table } from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import TransportsBody from "./TransportsBody";
import TransportsHeader from "./TransportsHeader";

const TransportsTable = () => {
  return (
    <Table>
      <TransportsHeader />
      <TransportsBody />
    </Table>
  );
};

export default TransportsTable;
