import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react'
import { UseFormRegister, FieldValues, FieldErrorsImpl, UseFormSetValue } from 'react-hook-form';
import { Button, Card, Divider, Grid, Header, Icon, Label, Loader, Segment, SemanticWIDTHS } from 'semantic-ui-react';
import Agent from '../../../../app/api/agent';
import { IAddressApp } from '../../../../app/models/address';
import { ICompany } from '../../../../app/models/company';
import { RootStoreContext } from '../../../../app/stores/rootStore';
import CompanyInfoDetails from '../../../company/common/CompanyInfoDetails';
import CreateTransportSelectCompany from './CreateTransportSelectCompany'

interface IProps {
    errors: Partial<FieldErrorsImpl<{
        [x: string]: any;
    }>>;
    setValue: UseFormSetValue<FieldValues>
    register: UseFormRegister<FieldValues>;
}

const CreateTransportFrom: React.FC<IProps> = ({
    register,
    errors,
    setValue
}) => {
    const rootStore = useContext(RootStoreContext)
    const { openModal } = rootStore.modalStore;

    const [selectedCompany, setselectedCompany] = useState<ICompany | undefined>(undefined)
    const [addresses, setAddresses] = useState<IAddressApp[]>([])
    const [loadingAddresses, setloadingAddresses] = useState(false)
    const [selectedAddress, setSelectedAddress] = useState<string | undefined>(undefined)

    const loadAddresses = useCallback(
        async () => {
            try {
                setloadingAddresses(true)
                if (selectedCompany !== undefined) {
                    let addresses = await Agent.Company.addresses(selectedCompany.guid)
                    console.log(addresses)
                    setAddresses(addresses.addresses)
                    setValue('addressFrom', addresses.addresses[0])
                    setSelectedAddress(addresses.addresses[0].addressGuid)
                }

            } catch (error) {
            } finally {
                setloadingAddresses(false)
            }
        },
        [selectedCompany, setAddresses],
    )


    useEffect(() => {
        loadAddresses();
    }, [selectedCompany])

    return (
        <Fragment>
            <Grid>
                <Grid.Row columns={1}>
                    <Grid.Column textAlign='center'>
                        <Header as="h1">
                            Remetente
                        </Header>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Segment>
                <Grid>
                    <Grid.Row columns={4}>
                        <Grid.Column textAlign='left'>
                            <Button
                                onClick={() => {
                                    openModal(<CreateTransportSelectCompany setCompanyGuid={setselectedCompany} />)
                                }}
                                primary
                                content="Selecionar Empresa"
                            />
                        </Grid.Column>
                        <Grid.Column width={12} verticalAlign='top'>
                            {selectedCompany !== undefined ?
                                <CompanyInfoDetails company={selectedCompany} />
                                :
                                <p>
                                    Falta selecionar empresa
                                </p>
                            }

                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column verticalAlign='top'>
                            <Loader active={loadingAddresses} />
                            <Card.Group itemsPerRow={Math.min(4, Math.max(addresses.length, 1)) as SemanticWIDTHS}>
                                {addresses && addresses.length > 0 &&
                                    addresses && addresses.length > 0 ?
                                    addresses.map(x =>
                                        <Card key={x.addressGuid}
                                            fluid
                                            color={selectedAddress === x.addressGuid ? 'red' : 'grey'}
                                            onClick={() => {
                                                setValue('addressFrom.addressGuid', x.addressGuid)
                                                setSelectedAddress(x.addressGuid)
                                            }}
                                        >
                                            <Card.Content>
                                                <Card.Header>
                                                    <Grid padded>
                                                        <Grid.Row textAlign='center'>
                                                        </Grid.Row>
                                                        <Grid.Row>
                                                            <b>
                                                                {x.displayName}
                                                            </b>
                                                        </Grid.Row>
                                                    </Grid>
                                                </Card.Header>
                                                <Card.Meta>
                                                    {x.contactName} : {x.phone}
                                                </Card.Meta>
                                                <Card.Description>
                                                    <p>
                                                        {x.addressFirst}
                                                        <br />{x.addressSecond}
                                                    </p>
                                                    {x.postcodeToString}
                                                </Card.Description>
                                                {selectedAddress === x.addressGuid ? <Label ribbon='right' color='green' ><Icon name='paper plane' /> </Label> : null}
                                            </Card.Content>
                                        </Card>
                                    ) :
                                    null}
                            </Card.Group>



                        </Grid.Column>

                    </Grid.Row>
                </Grid>
            </Segment>
        </Fragment>
    )
}

export default CreateTransportFrom