import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { Grid, GridColumn } from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import TransportFilters from "./TransportFilters";
import TransportList from "./TransportList";
import ListItemPlaceholder from "../../../app/common/placeholder/ListItemPlaceholder";

const TransportDashboard: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    loadTransports,
    loadingInitial,
    setPage,
    totalPages,
    page,
  } = rootStore.transportStore;
  const { loading } = rootStore.companyStore;

  const [loadingNext, setLoadingNext] = useState(false);

  const handleGetNext = () => {
    setLoadingNext(true);
    setPage(page + 1);
    loadTransports().then(() => setLoadingNext(false));
  };

  useEffect(() => {
    if (!loading) loadTransports();
  }, [loadTransports, loading]);

  // if (loadingInitial || loading)
  //   return <LoadingComponent content="A carregar transportes" />;

  return (
    <Grid>
      <GridColumn width={12}>
        {loadingInitial || (loading && page === 0) ? (
          <ListItemPlaceholder />
        ) : (
          <InfiniteScroll
            pageStart={0}
            loadMore={handleGetNext}
            hasMore={!loadingNext && page + 1 < totalPages}
            initialLoad={false}
          >
            <TransportList />
          </InfiniteScroll>
        )}
      </GridColumn>
      <GridColumn width={4}>
        <TransportFilters />
      </GridColumn>
    </Grid>
  );
};

export default observer(TransportDashboard);
