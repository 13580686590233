import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Header, Segment } from "semantic-ui-react";
import Agent from "../../../app/api/agent";
import AddressFields from "../../../app/common/form/AddressFields";
import { AddressFormValues } from "../../../app/models/address";
import { RootStoreContext } from "../../../app/stores/rootStore";

interface IProps {
  addressPrefix?: string;
  onSubmit: (...args: any[]) => any;
}

const CustomerAddAddress: React.FC<IProps> = ({ addressPrefix, onSubmit }) => {
  const rootStore = useContext(RootStoreContext);
  const {
    submitting,
    customerSelectType,
    getCustomerGuid
  } = rootStore.customerStore;
  const {
    addressHandler
  } = rootStore.addressStore;
  const { closeModal } = rootStore.modalStore;

  useEffect(() => {
    customerSelectType(addressPrefix);
    return () => {
      customerSelectType(undefined);
      setformValues(new AddressFormValues())
    };
  }, [customerSelectType, addressPrefix]);

  const [load, setload] = useState(false)
  const [formValues, setformValues] = useState(new AddressFormValues())

  const { register, reset, handleSubmit, watch, setError, setValue, clearErrors, getValues, formState: { isDirty, errors }, control } = useForm();
  const postcode = watch(`${addressPrefix + '.postcode'}` as const)

  useEffect(() => {
    setformValues(new AddressFormValues())
  }, [])


  async function submitHandler(data: any) {
    try {
      data[addressPrefix!].postcode = data[addressPrefix!].postcode.replace(/(\d{4})-?(\d{3})/, "$1-$2 ") + data[addressPrefix!].location
      const address = await addressHandler(data[addressPrefix!], "customer", getCustomerGuid!);
      onSubmit(address);
      closeModal();
    } catch (error) {
      setError('submit', { type: 'manual', message: JSON.stringify(error) });
    }
  }

  useEffect(() => {
    reset(formValues)
  }, [formValues])

  useEffect(() => {
    updateLocale(postcode)
  }, [postcode])

  const updateLocale = async (postcode: string) => {
    if (postcode && postcode.match(/\d{4}-?\d{3}/) !== null) {
      try {
        setload(true)
        let postcodeApi = await Agent.Geo.getPostCode(postcode, 'pt')
        if (!postcodeApi.hasRoute) {
          setError(`${(addressPrefix || "address") + '.postcode'}` as const, { type: 'manual', message: 'O código postal não está abrangido. Contacte a empresa.' })
        } else {
          setValue(`${addressPrefix + '.location'}` as const, postcodeApi.localeName);
          clearErrors(`${(addressPrefix || "address") + '.postcode'}` as const)
          // setformValues(prevstate => {
          //   prevstate['address']['location'] = postcodeApi.localeName;
          //   return { ...prevstate }
          // })
        }
      } catch (error) {
        setError(`${(addressPrefix || "address") + '.postcode'}` as const, { type: 'manual', message: 'O código postal não existe. Contacte a empresa!' })
      }
      finally {
        setload(false)
      }
    } else {
      setValue(`${addressPrefix + '.location'}` as const, '');
      // setformValues(prevstate => {
      //   let s = getValues();
      //   s['address']['location'] = "";
      //   return { ...prevstate }
      // })

    }
  }
  return (

    <Form onSubmit={handleSubmit(submitHandler)}>
      <Segment.Group>
        <Segment clearing>
          <Header>Adicionar novo endereço</Header>
          <AddressFields
            prefix={addressPrefix || "address"}
            register={register}
            loadLocale={load}
            errors={errors}
          />
        </Segment>
        <Segment basic clearing>
          <Button.Group fluid>
            <Button type="button" onClick={closeModal} content="Cancelar" />
            <Button.Or text="ou" />
            <Button
              floated="right"
              type="submit"
              positive
              loading={submitting}
              disabled={submitting}
              content="Adicionar endereço"
              color="teal"
            />
          </Button.Group>
        </Segment>
      </Segment.Group>
    </Form>
  );
};

export default CustomerAddAddress;
