import {
  IStatus,
  KTransportStatus,
  TransportStatus,
} from "../models/transport";
import Agent from "./agent";

export const changeStatus = async (
  transportGuid: string,
  statusName: KTransportStatus
) => {
  const newStatus: IStatus = new TransportStatus();
  newStatus.transportGuid = transportGuid;
  newStatus.statusName = statusName;
  try {
    await Agent.Transport.changeStatus(newStatus);
  } catch (error) {
    console.log(error);
  }
};

export const deleteTransport = async (transportGuid: string) => {
  try {
    await Agent.Transport.delete(transportGuid);
  } catch (error) {
    console.log(error);
  }
};