import React, { useContext, useState } from "react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { Form as FinalForm } from "react-final-form";
import { CompanyFormValues, ICompany } from "../../../app/models/company";
import { Button, Form, Segment } from "semantic-ui-react";
import CompanyFields from "../../../app/common/form/CompanyFields";
import { observer } from "mobx-react-lite";
import { FORM_ERROR } from "final-form";

interface IProps {
  companyEdit?: ICompany;
}

const CreateCompany: React.FC<IProps> = ({ companyEdit }) => {
  const rootStore = useContext(RootStoreContext);
  const { createCompany, loading, editCompany, submitting } = rootStore.companyStore;
  const [company] = useState(
    companyEdit ? companyEdit : new CompanyFormValues()
  );

  return (
    <Segment padded clearing>
      <FinalForm
        onSubmit={(values: ICompany) => {
          values.guid
            ? editCompany(values).catch((error) => ({
                [FORM_ERROR]: error,
              }))
            : createCompany(values).catch((error) => ({
                [FORM_ERROR]: error,
              }));
        }}
        loading={loading}
        initialValues={company}
        render={({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <CompanyFields company={companyEdit} />
            <Button
              floated="right"
              type="submit"
              color='teal'
              loading={submitting}
              disabled={submitting}
              content={companyEdit ? "Atualizar" : "Criar empresa"}
            />
          </Form>
        )}
      ></FinalForm>
    </Segment>
  );
};

export default observer(CreateCompany);
