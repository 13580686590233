import { FORM_ERROR } from "final-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useContext } from "react";
import { Form as FinalForm } from "react-final-form";
import { Button, Form, Header, Segment, Select } from "semantic-ui-react";
import ErrorMessage from "../../app/common/form/ErrorMessage";
import UserFields from "../../app/common/form/UserFields";
import NegativeMessage from "../../app/common/messages/NegativeMessage";
import {
  generateOptions,
  getDisplayName,
  getLocation,
  getMainAddress,
} from "../../app/dataAccess/AddressData";
import { IUser, IUserFormValues, UserFormValues } from "../../app/models/user";
import { RootStoreContext } from "../../app/stores/rootStore";

interface IProps {
  registerType: string;
  user?: IUser;
}

interface IErrors {
  passwordConfirm?: string;
}

const RegisterForm: React.FC<IProps> = ({ registerType, user }) => {
  const rootStore = useContext(RootStoreContext);
  const { register, submitting } = rootStore.userStore;
  const { company } = rootStore.companyStore;
  const mainAddress = user && user.address ? user.address : getMainAddress(company!.addresses);
  const [initialValues] = useState(
    new UserFormValues(
      user
        ? {
            ...user,
            addressGuid: mainAddress.addressGuid,
            password: "",
            passwordConfirm: "",
          }
        : {
            addressGuid: mainAddress.addressGuid,
            password: "",
            passwordConfirm: "",
          }
    )
  );
  return (
    <FinalForm
      onSubmit={(values: IUserFormValues) => {
        register(values, registerType, !!user)
        .catch((error) => ({
          [FORM_ERROR]: error,
        }));
      }}
      validate={(values) => {
        const errors: IErrors = {};
        if (values.password !== values.passwordConfirm)
          errors.passwordConfirm = "Não coincidem!";

        return errors;
      }}
      mutators={{
        setAddressGuid: (addressGuid: string[], state, utils) => {
          utils.changeValue(state, "addressGuid", () => addressGuid[0]);
        },
      }}
      initialValues={initialValues}
      render={({
        handleSubmit,
        submitError,
        invalid,
        dirtySinceLastSubmit,
        form: {
          mutators: { setAddressGuid },
        },
      }) => (
        <Form onSubmit={handleSubmit} error>
          <Segment>
            <UserFields create={!user} />
          </Segment>
          <Segment>
            <Header size="small" content="Endereço predefinido:" />
            {company && company.addresses.length > 0 ? (
              <Select
                fluid
                placeholder={`${getDisplayName(mainAddress)} - ${getLocation(
                  mainAddress
                )}`}
                defaultValue={mainAddress.addressGuid}
                options={generateOptions(company!.addresses)}
                onChange={(_, data) => {
                  setAddressGuid(data.value);
                }}
              />
            ) : (
              <NegativeMessage header="Ainda não existem endereços associados a esta empresa!" />
            )}
          </Segment>
          {submitError && !dirtySinceLastSubmit && (
            <ErrorMessage error={submitError} />
          )}
          <Button
            type="submit"
            disabled={invalid && !dirtySinceLastSubmit}
            loading={submitting}
            color="teal"
            content={user ? "Editar" : "Registar"}
            fluid
            positive
          />
        </Form>
      )}
    />
  );
};

export default observer(RegisterForm);
