import React from "react";
import { Message } from "semantic-ui-react";

interface IProps {
  header: string;
  message?: string;
}

const NegativeMessage: React.FC<IProps> = ({ header, message }) => {
  return (
    <Message negative>
      <Message.Header>{header}</Message.Header>
      {message}
    </Message>
  );
};

export default NegativeMessage;
