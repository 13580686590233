import React, { useContext } from "react";
import { Header, Icon, Label, Segment } from "semantic-ui-react";
import AddressForm from "../../../features/address/AddressForm";
import { IAddress, IAddressFormValues } from "../../models/address";
import { AuthorizationRoles } from "../../models/user";
import { RootStoreContext } from "../../stores/rootStore";
import IsAuthorized from "../authorization/IsAuthorized";

interface IProps {
  address: IAddress | IAddressFormValues | undefined;
  canEdit?: boolean;
}

const AddressInfo: React.FC<IProps> = ({ address, canEdit }) => {
  const rootStore = useContext(RootStoreContext);
  const { openModal } = rootStore.modalStore;
  return (
    <Segment>
      {address?.isMain ? (
        <Label color="green" ribbon>
          Endereço Principal
        </Label>
      ) : null}
      <Header as="h2">
        <Header.Content>{address?.addressDisplayName}</Header.Content>
        <Header.Subheader>{address?.contactName}</Header.Subheader>
      </Header>
      <Header as="h2">
        <Header.Subheader>{address?.addressFirst}</Header.Subheader>
        {address?.addressSecond && (
          <Header.Subheader>{address?.addressSecond}</Header.Subheader>
        )}
        <Header.Subheader>
          {address?.postcode}
          {/* {address?.leftPostCode}-{address?.rightPostCode} {address?.location} */}
        </Header.Subheader>
        <Header.Subheader>{address?.country}</Header.Subheader>
      </Header>
      {/* TODO: Solve this issue: if only admin, can't edit customer address */}
      <IsAuthorized
        roles={canEdit ? [AuthorizationRoles.Admin, AuthorizationRoles.Company] : []}
        component={
          <Label
            as="a"
            onClick={() =>
              openModal(<AddressForm address={address} entityType="" guid="" />)
            }
            color="yellow"
            ribbon="right"
          >
            <Icon name="edit" />
          </Label>
        }
      />
    </Segment>
  );
};

export default AddressInfo;
