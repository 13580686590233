import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useContext } from "react";
import { Icon, Step } from "semantic-ui-react";
import IsAuthorized from "../../../app/common/authorization/IsAuthorized";
import {
  getRefundStatus,
  getRefundValue,
} from "../../../app/dataAccess/RefundData";
import { IRefund, IRefundStatus } from "../../../app/models/refund";
import { AuthorizationRoles } from "../../../app/models/user";
import { RootStoreContext } from "../../../app/stores/rootStore";
import TransportRefundConfirm from "./TransportRefundConfirm";

const TransportRefundInfo: React.FC<{ refund: IRefund; vertical?: boolean }> =
  ({ refund, vertical }) => {
    const [refundState] = useState(getRefundStatus(refund));
    const rootStore = useContext(RootStoreContext);
    const { openModal } = rootStore.modalStore;
    return (
      <div style={{ alignContent: "center" }}>
        {refundState === IRefundStatus.NOREFUND ||
          refundState === IRefundStatus.ND ? null : (
          <Step.Group vertical={vertical ?? false}>
            <Step
              completed={IRefundStatus.INBOUND < refundState}
              active={IRefundStatus.INBOUND === refundState}
            >
              <Icon name="truck" />
              <Step.Content>
                <Step.Title>Aguarda cobrança</Step.Title>
                <Step.Description>
                  O transporte ainda não chegou ao destino
                </Step.Description>
              </Step.Content>
            </Step>

            <Step
              completed={IRefundStatus.OUTBOUND < refundState}
              active={IRefundStatus.OUTBOUND === refundState}
            >
              <Icon name="payment" />
              <Step.Content>
                <Step.Title>Cobrado, aguarda pagamento</Step.Title>
                <Step.Description>
                  Destino já pagou. A aguardar devolução.
                </Step.Description>
              </Step.Content>
            </Step>

            <IsAuthorized
              roles={[AuthorizationRoles.Admin]}
              component={
                <Step
                  onClick={() => {
                    if (refundState === IRefundStatus.OUTBOUND || refundState === IRefundStatus.INBOUND) {
                      openModal(
                        <TransportRefundConfirm
                          refund={refund}
                          company="cenas"
                        />
                      );

                    }
                  }}
                  completed={IRefundStatus.PAID <= refundState}
                >
                  <Icon name="payment" />
                  <Step.Content>
                    <Step.Title>Pago</Step.Title>
                    <Step.Description>
                      Valor: {getRefundValue(refund).toFixed(2)} €
                    </Step.Description>
                  </Step.Content>
                </Step>
              }
            />
            <IsAuthorized
              roles={[AuthorizationRoles.Supplier, AuthorizationRoles.Company]}
              component={
                <Step
                  completed={IRefundStatus.PAID <= refundState}
                >
                  <Icon name="payment" />
                  <Step.Content>
                    <Step.Title>Pago</Step.Title>
                    <Step.Description>
                      Valor: {getRefundValue(refund).toFixed(2)} €
                    </Step.Description>
                  </Step.Content>
                </Step>
              }
            />
          </Step.Group>
        )}
      </div>
    );
  };

export default observer(TransportRefundInfo);
