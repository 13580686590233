import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import Agent from "../api/agent";
import { FuelFee, FuelFeeEnvelope } from "../models/fuelfee";
import { RootStore } from "./rootStore";

export default class FuelFeeStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @observable fuelFees: FuelFee[] = [];
  @observable loading = false;
  @observable firstLoad = false;

  @computed get getCurrentFee() {
    return this.fuelFees.length === 0 ? 0 : this.fuelFees[0].fuelFeeValue;
  }

  @action loadFuelFees = async () => {
    this.loading = true;
    let envelope = await Agent.FuelFee.get();
    runInAction(() => {
      this.fuelFees = envelope.fuelFees;
      this.loading = false;
      this.firstLoad = true;
    });
  };

  @action addFuelFee = async (value: string) => {
    this.loading = true;
    await Agent.FuelFee.add(value);
    await this.loadFuelFees();
    this.loading = false;
  };
}
