import React, { Fragment } from "react";
import { Field } from "react-final-form";
import { Header } from "semantic-ui-react";
import { ICompany } from "../../models/company";
import { agreementOpt } from "../options/CompanyOptions";
import { PT_pt } from "../util/expressions";
import SelectInput from "./SelectInput";
import TextInput from "./TextInput";
import {
  composeValidators,
  exactLength,
  greaterThanOrEqual,
  isNumber,
  isVatNumber,
  maxLength,
  minLength,
  required,
} from "./Validators";

const CompanyFields: React.FC<{ company?: ICompany }> = ({ company }) => {
  return (
    <Fragment>
      <Header size="small" content="Nome Fiscal" />
      <Field
        maxLength={50}
        name="taxName"
        validate={composeValidators(required, minLength(3), maxLength(50))}
      >
        {(props) => <TextInput {...props} />}
      </Field>
      <Header size="small" content="Nome Comercial" />
      <Field
        maxLength={50}
        name="commercialName"
        validate={composeValidators(required, minLength(3), maxLength(50))}
      >
        {(props) => <TextInput {...props} />}
      </Field>
      <Header size="small" content={`${PT_pt.translate.vatNumber}:`} />
      <Field
        name="vatNumber"
        disabled={company !== undefined}
        maxLength={9}
        placeholder={PT_pt.translate.vatNumber}
        validate={composeValidators(
          required,
          isNumber,
          exactLength(9),
          isVatNumber
        )}
      >
        {(props) => <TextInput {...props} />}
      </Field>
      <Header size="small" content={`${PT_pt.translate.isSupplier}`} />
      <Field
        name="isSupplier"
        disabled={company?.isSupplier}
        component={SelectInput}
        options={agreementOpt}
        placeholder={agreementOpt[1].text}
      />
      <Header size="small" content={`${PT_pt.translate.hasAgreement}`} />
      <Field
        component={SelectInput}
        options={agreementOpt}
        name="hasAgreement"
        placeholder={agreementOpt[0].text}
      />
      <Header size="small" content="Quantidade Mensal" />
      <Field
        name="monthQuantity"
        type="number"
        placeholder={PT_pt.translate.monthQuantity}
        validate={greaterThanOrEqual(0)}
      >
        {(props) => <TextInput {...props} />}
      </Field>
      <Header size="small" content="Agrupar embalagens" />
      <Field
        name="groupVolumes"
        type="number"
        placeholder={PT_pt.translate.groupVolumes}
        validate={greaterThanOrEqual(0)}
      >
        {(props) => <TextInput {...props} />}
      </Field>
    </Fragment>
  );
};

export default CompanyFields;
