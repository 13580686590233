import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { Pagination, Segment } from "semantic-ui-react";
import SearchInput from "../../../app/common/search/SearchForm";
import { RootStoreContext } from "../../../app/stores/rootStore";
import CustomerList from "./CustomerList";

interface IProps {
  setValues: (...args: any[]) => any;
  addressType?: string;
}

const CustomerDashboard: React.FC<IProps> = ({ setValues, addressType }) => {
  const rootStore = useContext(RootStoreContext);
  const {
    loadCustomers,
    totalPages,
    setPage,
    page,
    setPredicate,
    loadingInitial,
    customerSelectType,
  } = rootStore.customerStore;

  useEffect(() => {
    customerSelectType(addressType)
    loadCustomers();
    return function cleanup() {
      setPredicate("all", "all"); // clear the predicate
      customerSelectType(undefined)
    };
  }, [loadCustomers, page, setPredicate, customerSelectType, addressType]);

  return (
    <Segment.Group>
      <SearchInput loading={loadingInitial} setPredicate={setPredicate} />
      <CustomerList selectCustomer={setValues} />
      <Pagination
        totalPages={totalPages}
        activePage={page + 1}
        onPageChange={(_, { activePage }) => setPage(activePage!)}
      />
    </Segment.Group>
  );
};

export default observer(CustomerDashboard);
