import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { Grid } from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import CompanyTable from "../../company/table/CompanyTable";
import ReportFilters from "./ReportFilters";

const ReportDashboard: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { getCompanies, setPredicate, loadingInitial } = rootStore.companyStore;

  useEffect(() => {
    setPredicate("limit", "-1");
  }, [setPredicate]);

  return (
    <Grid>
      <Grid.Column width="12">
          <CompanyTable companies={getCompanies} loading={loadingInitial} />
      </Grid.Column>
      <Grid.Column width="4">
        <ReportFilters search />
      </Grid.Column>
    </Grid>
  );
};

export default observer(ReportDashboard);
