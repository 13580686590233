import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect } from "react";
import { Button, Checkbox, Segment } from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import CourierCreateModal from "./CourierCreateModal";
import RouteCreateModal from "./RouteCreateModal";
import WarehouseCreateModal from "./WarehouseCreateModal";

interface IProps {
  type: string;
}

const RCWCreateButtons: React.FC<IProps> = ({ type }) => {
  const rootStore = useContext(RootStoreContext);
  const { openModal } = rootStore.modalStore;
  const { loadRoutes, routeCount } = rootStore.routeStore;
  const { loadWarehouses, whCount } = rootStore.warehouseStore;
  const { loadCouriers, courierCount, setPredicate: courierSetPredicate } = rootStore.courierStore;

  useEffect(() => {
    if (type === "warehouse") loadWarehouses();
    if (type === "routes") loadRoutes();
    if (type === "couriers") loadCouriers();
  }, [routeCount, whCount, courierCount]);

  return (
    <Fragment>
      <div>
        {type === "warehouses" && (
          <>
            <Button
              color="red"
              content="Criar Armazém"
              label={{
                basic: true,
                color: "red",
                pointing: "left",
                content: whCount,
              }}
              onClick={() => openModal(<WarehouseCreateModal />)}
            />
          </>
        )}
      </div>
      <div>
        {type === "routes" && (
          <>
            <Button
              color="red"
              content="Criar Rota"
              label={{
                basic: true,
                color: "red",
                pointing: "left",
                content: routeCount,
              }}
              onClick={() => openModal(<RouteCreateModal />)}
            />
          </>
        )}
      </div>
      <div>
        {type === "couriers" && (
          <Segment.Group>
            <Segment>
              <Checkbox
                defaultChecked
                onChange={(e, data) => {
                  courierSetPredicate("active", "" + data.checked)
                  console.log(data.checked)
                }}
                label="Ativos?"
              />
            </Segment>
            <Segment>
              <Button
                color="red"
                content="Criar Estafeta"
                label={{
                  basic: true,
                  color: "red",
                  pointing: "left",
                  content: courierCount,
                }}
                onClick={() => openModal(<CourierCreateModal />)}
              />
            </Segment>
          </Segment.Group>
        )}
      </div>
    </Fragment>
  );
};

export default observer(RCWCreateButtons);
