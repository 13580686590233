import { observer } from "mobx-react-lite";
import { Fragment, useContext, useEffect, useState } from "react";
import { Button, Dropdown, Header, Icon } from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { endOfDay, startOfDay } from "date-fns/esm";
import IsAuthorized from "../../../app/common/authorization/IsAuthorized";
import { AuthorizationRoles } from "../../../app/models/user";
import { resumedManifestOptions } from "../../../app/api/ReportRequest";
import SearchInput from "../../../app/common/search/SearchForm";

const TransportFilters = () => {
  const rootStore = useContext(RootStoreContext);
  const { predicate, setMultiplePredicate, setPredicate, loadingInitial } =
    rootStore.transportStore;
  const { generateTransportDoc, loading } = rootStore.reportStore;

  const [startDate, setStartDate] = useState<any>(
    predicate.get("startDate") || new Date()
  );
  const [endDate, setEndDate] = useState<any>(predicate.get("endDate") || null);
  const [manifestType, setManifestType] = useState(
    resumedManifestOptions[0].value
  );

  useEffect(() => {
    setPredicate("all", "all");
  }, [setPredicate]);

  const onDateChange = (dates: Date[] | null | Date) => {
    if (dates) {
      if (Array.isArray(dates)) {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if (end) {
          setMultiplePredicate(
            ["startDate", "endDate"],
            [startOfDay(start), endOfDay(end)]
          );
        }
      }
    }
  };

  return (
    <Fragment>
      <SearchInput
        setPredicate={setPredicate}
        loading={loadingInitial}
        placeholder="Pesquisar por guia..."
      />
      <IsAuthorized
        roles={[AuthorizationRoles.Company]}
        component={
          <Header as="h2">
            <Header.Subheader>
              <Dropdown
                placeholder={resumedManifestOptions[0].text}
                fluid
                selection
                value={manifestType}
                options={resumedManifestOptions}
                onChange={(_, data) => setManifestType(data.value!.toString())}
              />
              <Button
                fluid
                animated
                color="red"
                onClick={() =>
                  generateTransportDoc([manifestType], "resumedManifest")
                }
                loading={loading.get("resumedManifest")}
              >
                <Button.Content>
                  <Icon name="print" />
                  Imprimir
                </Button.Content>
              </Button>
            </Header.Subheader>
          </Header>
        }
      />
      <Header>
        <Header.Content>Escolher Datas</Header.Content>
        <Header.Subheader>
          <DatePicker
            selected={startDate}
            onChange={(date) => onDateChange(date)}
            selectsRange
            startDate={startDate}
            endDate={endDate}
            maxDate={new Date()}
            inline
          />
        </Header.Subheader>
      </Header>
    </Fragment>
  );
};

export default observer(TransportFilters);
