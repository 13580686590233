import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import { RootStore } from "./rootStore";
import Agent from "../api/agent";
import { toast } from "react-toastify";
import { PT_pt } from "../common/util/expressions";
import { v4 as uuid } from "uuid";
import { history } from "../..";
import { ICompany } from "../models/company";
import { IUser } from "../models/user";
import { LIMIT_BY_PAGE } from "../common/util/globals";

export default class CompanyStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    reaction(
      () => this.predicate.keys(),
      () => {
        this.page = 0;
        this.companyRegistry.clear();
        this.loadCompanies();
      }
    );
  }

  @observable companyRegistry = new Map<string, ICompany>();
  @observable company: ICompany | null = null;
  @observable submitting = false;
  @observable loading = false;
  @observable loadingInitial = false;
  @observable predicate = new Map();
  @observable page = 0;
  @observable companyCount = 0;

  @computed get axiosParams() {
    const params = new URLSearchParams();
    if (!this.predicate.has("limit"))
      params.append("limit", LIMIT_BY_PAGE.toString());
    params.append("offset", `${this.page ? this.page * LIMIT_BY_PAGE : 0}`);
    this.predicate.forEach((value, key) => {
      params.append(key, value);
    });
    return params;
  }

  @computed get totalPages() {
    return Math.ceil(this.companyCount / LIMIT_BY_PAGE);
  }

  @action clearCompany = () => {
    this.company = null;
  };
  @action setPage = (page: number | string) => {
    this.page = Number(page) - 1;
  };

  @action setInifinityPage = (page: number) => {
    this.page = page;
  };

  @action setPredicate = (predicate: string, value: string | Date) => {
    this.predicate.clear();
    if (predicate !== "all") {
      this.predicate.set(predicate, value);
    }
  };

  @action setMultiplePredicate = (
    predicates: string[],
    value: string[] | Date[]
  ) => {
    this.predicate.clear();
    predicates.map((predicate, i) => this.predicate.set(predicate, value[i]));
  };


  @computed get getCompanies(): ICompany[] {
    const fullCompanies = Array.from(this.companyRegistry.values());
    return fullCompanies;
  }
  @computed get getCompaniesSliced(): ICompany[] {
    let slicePage = Array.from(this.companyRegistry.values())
      .filter((x) => x.vatNumber !== this.company!.vatNumber)
      .slice(
        this.page * LIMIT_BY_PAGE,
        this.page * LIMIT_BY_PAGE + LIMIT_BY_PAGE
      );
    return slicePage;
  }

  @action loadCompany = async (guid: string) => {
    let company = this.getCompany(guid);
    //this.rootStore.transportStore.clearTransportRegistry();
    // if (company) {
    //   this.company = company;
    //   return company;
    // } else {
    this.loading = true;
    try {
      company = await Agent.Company.details(guid);
      runInAction(() => {
        if (company) {
          this.companyRegistry.set(company.guid, company);
          this.company = company;
          this.loading = false;
        }
      });
      return company;
    } catch (error) {
      toast.error(PT_pt.errors.submitting);
      runInAction(() => {
        this.loading = false;
      });
    }
    // }
  };

  @action loadCompanyUser = async () => {
    this.loadingInitial = true;
    try {
      const company = await Agent.Company.userCompany();
      runInAction(() => {
        this.companyRegistry.set(company.guid, company);
        this.company = company;
        this.loadingInitial = false;
      });
    } catch (error) {
      this.loadingInitial = false;
    }
  };

  @action addUser = (guid: string, user: IUser) => {
    const company = this.companyRegistry.get(guid);
    if (company && company.appUsers) company.appUsers.push(user);
    // console.log(company)
    this.company = company!;
  };

  @action editUser = (newUser: IUser) => {
    if (this.company && this.company.appUsers) {
      this.company.appUsers = this.company.appUsers.map((user) =>
        user.username === newUser.username ? newUser : user
      );
    }
  };

  @action loadCompanies = async () => {
    this.loadingInitial = true;
    try {
      let params = this.axiosParams;
      if (this.company!) params.append("companyGuid", this.company.guid);
      const companiesEnvelope = await Agent.Company.list(params);
      const { companies, companyCount } = companiesEnvelope;
      runInAction(() => {
        this.companyCount = companyCount;
        companies.forEach((company) => {
          this.companyRegistry.set(company.guid, company);
        });
        this.loadingInitial = false;
      });
    } catch (error) {
      console.log(error);
      this.loadingInitial = false;
    }
  };

  getCompany = (guid: string) => {
    return this.companyRegistry.get(guid);
  };

  @action createCompany = async (company: ICompany) => {
    this.submitting = true;
    company.guid = uuid();
    try {
      await Agent.Company.create(company);
      runInAction(() => {
        this.companyRegistry.set(company.guid, company);
        this.submitting = false;
      });
      history.push(`/company/${company.guid}`);
      this.rootStore.modalStore.closeModal();
    } catch (error) {
      runInAction(() => {
        console.log(error);
        this.submitting = false;
        return error;
      });
    }
  };

  @action editCompany = async (company: ICompany) => {
    this.submitting = true;
    try {
      await Agent.Company.edit(company);
      runInAction(() => {
        this.companyRegistry.delete(company.guid);
        this.loadCompany(company.guid);
        this.submitting = false;
      });
      this.rootStore.modalStore.closeModal();
    } catch (error) {
      this.submitting = false;
      return error;
    }
  };

  @action updateCompanyAddress = (companyGuid: string) => {
    if (companyGuid.length === 0) companyGuid = this.company!.guid;
    this.companyRegistry.delete(companyGuid);
    this.loadCompany(companyGuid);
    this.rootStore.modalStore.closeModal();
  };
}
