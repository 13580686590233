import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { Grid, GridColumn, Loader } from "semantic-ui-react";
import ListItemPlaceholder from "../../../app/common/placeholder/ListItemPlaceholder";
import { RootStoreContext } from "../../../app/stores/rootStore";
import CompanyList from "./CompanyList";
import CompanySideBar from "./CompanySideBar";

const CompanyDashboard: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    loadCompanies,
    loadingInitial,
    setInifinityPage,
    page,
    totalPages,
    setPredicate,
    clearCompany
  } = rootStore.companyStore;
  const [loadingNext, setLoadingNext] = useState(false);

  const handleGetNext = () => {
    setLoadingNext(true);
    setInifinityPage(page + 1);
    loadCompanies().then(() => setLoadingNext(false));
  };

  useEffect(() => {
    clearCompany();
    loadCompanies();
    return function cleanup() {
      setPredicate("all","all");
    }
  }, [loadCompanies, setPredicate, clearCompany]);

  return (
    <Grid>
      <GridColumn width={12}>
        {loadingInitial && page === 0 ? (
          <ListItemPlaceholder />
        ) : (
          <InfiniteScroll
          key={page}
            pageStart={0}
            loadMore={handleGetNext}
            
            hasMore={!loadingNext && page + 1 < totalPages}
            initialLoad={false}
            loader={
              <Loader key={page} active inline='centered' />
            }
          >
            <CompanyList />
          </InfiniteScroll>
        )}
      </GridColumn>
      <GridColumn width={4}>
        <CompanySideBar />
      </GridColumn>
    </Grid>
  );
};

export default observer(CompanyDashboard);
