import React, { useContext, useEffect } from "react";
import { Button, Divider, Icon, Label, Segment } from "semantic-ui-react";
import SearchInput from "../../../app/common/search/SearchForm";
import { RootStoreContext } from "../../../app/stores/rootStore";
import CreateCompany from "../create/CreateCompany";

const CompanySideBar = () => {
  const rootStore = useContext(RootStoreContext);
  const { openModal } = rootStore.modalStore;
  const {
    setPredicate,
    loadingInitial,
    companyCount,
  } = rootStore.companyStore;

  useEffect(() => {
    setPredicate("all", "all");
  }, [setPredicate]);


  return (
    <Segment basic textAlign="center">
      <SearchInput setPredicate={setPredicate} loading={loadingInitial} />
      <Divider horizontal>OU</Divider>
      <Button as="div" labelPosition="right">
        <Button color="teal" onClick={() => openModal(<CreateCompany />)}>
          <Icon name="add" />
          Criar Empresa
        </Button>
        <Label as="a" basic color="blue" pointing="left">
          {companyCount}
        </Label>
      </Button>
    </Segment>
  );
};

export default CompanySideBar;
