import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { Grid, GridColumn, Menu, Icon, SemanticICONS } from "semantic-ui-react";
import ListItemPlaceholder from "../../../app/common/placeholder/ListItemPlaceholder";
import { RootStoreContext } from "../../../app/stores/rootStore";
import CompanyReportDashboard from "../../report/company/CompanyReportDashboard";
import CompanyEmployee from "./CompanyEmployee";
import CompanyStore from "./CompanyStore";

const CompanyDetailsDashboard = () => {
  const rootStore = useContext(RootStoreContext);
  const { loading, loadingInitial } = rootStore.companyStore;
  const [selectedMenu, setSelectedMenu] = useState(0);

  const menuItem = [<CompanyStore />, <CompanyEmployee />, <CompanyReportDashboard />];

  const menuBuild: Array<{ icon: SemanticICONS; name: string }> = [
    { icon: "building outline", name: "Lojas" },
    { icon: "users", name: "Funcionários" },
    { icon: "file alternate", name: "Listagens"}
  ];

  return (
    <Grid>
      <GridColumn width={12}>
        {loading || loadingInitial ? (
          <ListItemPlaceholder />
        ) : (
          menuItem[selectedMenu]
        )}
      </GridColumn>
      <GridColumn width={4}>
        <Menu vertical fluid tabular="right">
          {menuBuild.map((item, i) => (
            <Menu.Item
              key={i}
              active={selectedMenu === i}
              onClick={() => setSelectedMenu(i)}
            >
              <Icon name={item.icon} />
              {item.name}
            </Menu.Item>
          ))}
        </Menu>
      </GridColumn>
    </Grid>
  );
};

export default observer(CompanyDetailsDashboard);
