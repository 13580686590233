import { observer } from "mobx-react-lite";
import { useCallback, useContext, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, ButtonOr, Header, Message, Segment } from "semantic-ui-react";
import { RootStoreContext } from "../../../../app/stores/rootStore";
import PostalDetails from "./PostalDetails";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import RouteSetWarehouse from "./RouteSetWarehouse";
import RouteSetCourier from "./RouteSetCourier";
import EditRoute from "../../../route/edit/EditRoute";

interface DetailsParams {
  id: string;
}

const RouteDetails: React.FC<RouteComponentProps<DetailsParams>> = ({
  match,
  history,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { loading, route, loadRoute, clearRoute } = rootStore.routeStore;
  const { openModal } = rootStore.modalStore;

  const load = useCallback(
    () => {
      loadRoute(parseInt(match.params.id)).then();
    },
    [loadRoute, match.params.id, route],
  )


  useEffect(() => {
    load();
    return () => {
      clearRoute();
    }
  }, [match.params.id]);

  useEffect(() => {


    return () => {

    }
  }, [route?.warehouseName])

  if (loading || !route)
    return <LoadingComponent content="A carregar a rota" />;

  return (
    <Segment>
      <Button
        icon="left arrow"
        content="Voltar"
        labelPosition="left"
        onClick={() => history.goBack()}
      />
      <Segment inverted>
        <h3>Rota: {route.name}</h3>
        <Button
          onClick={() => {
            openModal(<EditRoute oldName={route!.name} />)
          }}
          content='Editar nome'
        />
      </Segment>
      <Segment.Group>
        <Segment.Group horizontal>
          <Segment>
            <Header size="small" content="Estafeta" />
            {route.courierDisplayname ?
              <a href={`/courier/${route.courierId}`}>
                <Segment>{route.courierDisplayname}</Segment>
              </a>
              :
              <Message error>
                Não definido!
              </Message>
            }
            <Button fluid
              onClick={() => openModal(<RouteSetCourier routeId={route.id} currentCourierId={route.courierId} />)}
            >
              Atualizar estafeta
            </Button>
          </Segment>
          <Segment>
            <Header size="small" content="Armazém" />
            {route.warehouseName ?
              <a href={`/warehouse/${route.warehouseId}`}>
                <Segment>
                  {route.warehouseName} ({route.warehouseAbbreviation})
                </Segment>
              </a>
              :
              <Message error>
                Não definido!
              </Message>
            }
            <Button fluid
              onClick={() => openModal(<RouteSetWarehouse routeId={route.id} routeWarehouseId={route.warehouseId} />)}
            >
              Atualizar armazem
            </Button>
          </Segment>
        </Segment.Group>
        <Segment>
          <Header size="small" content="Códigos-Postais" />
          <PostalDetails />
        </Segment>
      </Segment.Group>
    </Segment>
  );
};

export default observer(RouteDetails);
