import { IActivity, IAttendee } from "../../models/activity";
import { IUser } from "../../models/user";

export const setActivityProps = (activity: IActivity, user: IUser) => {
  activity.date = new Date(activity.date);
  activity.isGoing = activity.attendees.some(
    (a) => a.username === user.username
  );
  activity.isHost = activity.attendees.some(
    (a) => a.username === user.username && a.isHost
  );
  return activity;
};


export const createAttendee = (user: IUser): IAttendee => {
  return {
    displayname: user.displayname,
    isHost: false,
    username: user.username,
    image: "here"
  }
} 

export const getIn = {
  "Admin" : "/companies",
  "Company" : "/transport/create",
  "Courier" : "/",
  "Supplier" : "/transport/create"
}