import Agent from "./agent";

export const addRoute = async (courierId: string, routeId: number) => {
  try {
    return await Agent.Couriers.addRoute(courierId, routeId);
  } catch (error) {
    console.log(error);
  }
};

export const listRoutes = async (id: string) => {
  try {
    return await Agent.Couriers.listRoutes(id);
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const updateWh = async (courierID: string, whId: number) => {
  try {
    return await Agent.Couriers.updateWh(courierID, whId);
  } catch (error) {
    console.log(error);
  }
};
