import { WarehouseFormValues } from "../models/warehouse";
import Agent from "./agent";

export const createWarehouse = async (warehouse: WarehouseFormValues) => {
    return await Agent.Warehouses.create(warehouse);
};

export const addCourier = async (whId: number, courierId: string[]) => {
  try {
    return await Agent.Warehouses.addCouriers(whId, courierId);
  } catch (error) {
    console.log(error);
  }
};

export const addRoutes = async (whId: number, routesIds: number[]) => {
  try {
    return await Agent.Warehouses.addRoutes(whId, routesIds);
  } catch (error) {
    console.log(error);
  }
};
