import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Pagination, Table } from "semantic-ui-react";
import IsAuthorized from "../../../../app/common/authorization/IsAuthorized";
import ListItemNotFound from "../../../../app/common/placeholder/ListItemNotFound";
import TableItemPlaceholder from "../../../../app/common/placeholder/TableItemPlaceholder";
import { AuthorizationRoles, ICourier } from "../../../../app/models/user";
import { RootStoreContext } from "../../../../app/stores/rootStore";

const CourierList: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { loadCouriers, couriers, getCouriersSliced, loading, totalPages, page, setPage } =
    rootStore.courierStore;

  useEffect(() => {
    loadCouriers();
    return () => {
    }
  }, [loadCouriers])

  useEffect(() => {
    return () => {
    }
  }, [couriers])
  


  if (loading) return <TableItemPlaceholder colSpan="4" />;

  return (
    <>
      {getCouriersSliced.length > 0 ? (
        <>
          <Table striped selectable textAlign="center">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <h3 style={{ paddingTop: 5, paddingBottom: 5 }}>
                    Estafeta
                  </h3>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <h3>#Rotas</h3>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <h3>Armazém</h3>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {getCouriersSliced.map((courier: ICourier) => (
                <Table.Row key={courier.id}>
                  <Table.Cell>
                    <div
                      style={{
                        paddingTop: 5,
                        paddingBottom: 5,
                        fontSize: 18,
                      }}
                    >
                      <a href={`/courier/${courier.id}`}>
                        {courier.displayname}
                      </a>
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <div style={{ fontSize: 18 }}>
                      {courier.routeNames ? (
                        <>{courier.routeNames.length}</>
                      ) : (
                        <>n/a</>
                      )}
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    {courier.warehouseName ? (
                      <IsAuthorized
                        component={
                          <div style={{ fontSize: 18 }}>
                            <a href={`/warehouse/${courier.warehouseId}`}>
                              {courier.warehouseName}
                            </a>
                          </div>
                        }
                        roles={[
                          AuthorizationRoles.Admin,
                          AuthorizationRoles.Company,
                        ]}
                      />
                    ) : (
                      <div style={{ fontSize: 18 }}>Não associado!</div>
                    )}
                  </Table.Cell>
                </Table.Row>
              )
              )
              }
            </Table.Body>
          </Table>
          <Pagination
            totalPages={totalPages}
            activePage={page + 1}
            onPageChange={(_, { activePage }) => setPage(activePage!)}
          />
        </>
      ) : (
        <ListItemNotFound content="Não existem estafetas!" />
      )}
    </>
  );
};

export default observer(CourierList);
