import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Checkbox, Table } from "semantic-ui-react";
import { ICompany } from "../../../app/models/company";
import { RootStoreContext } from "../../../app/stores/rootStore";

const CompanyTableHeader: React.FC<{ companies: ICompany[] }> = ({
  companies,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { insertCompany, resetCompany } = rootStore.reportStore;

  const [checkAll, setCheckAll] = useState(false);

  const handleCheckBoxAll = () => {
    if (checkAll) resetCompany();
    else companies.forEach((company) => insertCompany(company.guid));
  };

  useEffect(() => {
    if (checkAll) {
      setCheckAll(false);
      handleCheckBoxAll();
    }
    // eslint-disable-next-line
  }, [companies.length]);

  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>
          <Checkbox
            checked={checkAll}
            onChange={() => {
              setCheckAll(!checkAll);
              handleCheckBoxAll();
            }}
          />
        </Table.HeaderCell>
        <Table.HeaderCell>Contribuinte</Table.HeaderCell>
        <Table.HeaderCell>Nome Fiscal</Table.HeaderCell>
        <Table.HeaderCell>Nome Comercial</Table.HeaderCell>
        <Table.HeaderCell></Table.HeaderCell>
        <Table.HeaderCell></Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

export default observer(CompanyTableHeader);
