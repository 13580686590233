import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { Grid, Statistic } from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import CsvErrors from "./CsvErrors";

const CsvResumeStats = () => {
  const rootStore = useContext(RootStoreContext);
  const { csvTotals } = rootStore.transportStore;
  const { openModal } = rootStore.modalStore;
  const { totalTransports, totalErrors, totalVolumes, totalWeight } = csvTotals;
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Statistic.Group widths="4">
            <Statistic>
              <Statistic.Value>{totalTransports}</Statistic.Value>
              <Statistic.Label>Transportes</Statistic.Label>
            </Statistic>
            <Statistic>
              <Statistic.Value>{totalVolumes}</Statistic.Value>
              <Statistic.Label>Volumes</Statistic.Label>
            </Statistic>
            <Statistic>
              <Statistic.Value>{totalWeight}</Statistic.Value>
              <Statistic.Label>KG</Statistic.Label>
            </Statistic>
            <Statistic color="red">
              <Statistic.Value onClick={() => openModal(<CsvErrors />)}>
                {" "}
                {totalErrors}
              </Statistic.Value>
              <Statistic.Label>ERROS</Statistic.Label>
            </Statistic>
          </Statistic.Group>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default observer(CsvResumeStats);
