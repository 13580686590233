import { useState } from "react";
import { Icon, Menu, Segment, SemanticICONS } from "semantic-ui-react";
import FuelFeeHistory from "./FuelFeeHistory";
import FuelFeeManager from "./FuelFeeManager";

const WareHouseSidebar = () => {
  const [selectedMenu, setSelectedMenu] = useState(0);

  const menuItem = [<FuelFeeHistory />, <FuelFeeManager />];

  const menuBuild: Array<{ icon: SemanticICONS; name: string }> = [
    { icon: "list", name: "Histórico" },
    { icon: "euro", name: "Novo valor" },
  ];

  return (
    <Segment>
      <Menu pointing>
        {menuBuild.map((item, i) => (
          <Menu.Item
            key={i}
            active={selectedMenu === i}
            onClick={() => setSelectedMenu(i)}
          >
            <Icon name={item.icon} />
            {item.name}
          </Menu.Item>
        ))}
      </Menu>
      {menuItem[selectedMenu]}
    </Segment>
  );
};

export default WareHouseSidebar;
