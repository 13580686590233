import { action, makeObservable, observable, reaction } from "mobx";
import { RootStore } from "./rootStore";

export default class ComonStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;

    reaction(
      () => this.token,
      (token) => {
        if (token) {
          window.localStorage.setItem("jwt", token);
        } else {
          window.localStorage.removeItem("jwt");
        }
      }
    );
    reaction(
      () => this.roles,
      (roles) => {
        if (roles) {
          window.localStorage.setItem("roles", roles.toString());
        } else {
          window.localStorage.removeItem("roles");
        }
      }
    )
  }

  @observable token: string | null = window.localStorage.getItem('jwt');
  @observable roles: string | null = window.localStorage.getItem("roles");
  @observable appLoaded = false;

  @action setToken = (token: string | null) => {
    this.token = token;
  };

  @action setRoles = (roles: string | null) => {
    this.roles = roles;
  };



  @action setAppLoaded = () => {
          this.appLoaded = true;
  };
}
