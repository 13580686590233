import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Divider, Grid, Menu, Ref, Sticky } from "semantic-ui-react";
import { history } from "../../..";
import SearchInput from "../../../app/common/search/SearchForm";
import { RootStoreContext } from "../../../app/stores/rootStore";
import RCWCreateButtons from "../create/RCWCreateButtons";
import RCWList from "./List/RCWList";

const RCWDashboard: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const searchParams = new URLSearchParams(document.location.search)
  const {
    clearRoute,
    setPredicate: routeSetPredicate,
    loadingInitial: routeLoadingInitial,
    loadRoutes,
    page: pageRoute,
  } = rootStore.routeStore;
  const {
    clearWarehouse,
    whSetPredicate,
    loadingInitial: whLoadingInitial,
    loadWarehouses,
    page: pageWarehouse,
  } = rootStore.warehouseStore;
  const {
    clearCourier,
    setPredicate: cSetPredicate,
    loadingInitial: cLoadingInitial,
    loadCouriers,
    page: pageCourier,
  } = rootStore.courierStore;

  const stickyRef = useRef(null);
  const [bttnSelected, setBttnSelected] = useState<string>(searchParams.get('selected') ?? 'warehouses');

  function toRoutesWindowHandler() {
    history.push('rcs?selected=routes')
    setBttnSelected("routes");
  }
  function toCouriersWindowHandler() {
    history.push('rcs?selected=couriers')
    setBttnSelected("couriers");
  }
  function toWarehousesWindowHandler() {
    history.push('rcs?selected=warehouses')
    setBttnSelected("warehouses");
  }

  useEffect(() => {
    if (bttnSelected === "warehouses") {
      clearWarehouse();
      loadWarehouses();
      return function cleanup() {
        whSetPredicate("all", "all");
      };
    } 
  }, [
    bttnSelected,
    loadWarehouses,
    whSetPredicate,
    clearWarehouse,
    pageWarehouse,
  ]);

  return (
    <Grid celled="internally">
      <Grid.Row>
        <Ref innerRef={stickyRef}>
          <Grid.Column width={3}>
            <Menu fluid vertical tabular>
              <Menu.Item
                name="Armazéns"
                onClick={toWarehousesWindowHandler}
                active={bttnSelected === 'warehouses'}
              />
              <Menu.Item
                name="Rotas"
                onClick={toRoutesWindowHandler}
                active={bttnSelected === 'routes'}
              />
              <Menu.Item
                name="Estafetas"
                onClick={toCouriersWindowHandler}
                active={bttnSelected === 'couriers'}
              />
            </Menu>
          </Grid.Column>
        </Ref>
        <Grid.Column width={10}>
          <RCWList type={bttnSelected} />
        </Grid.Column>
        <Ref innerRef={stickyRef}>
          <Grid.Column width={3} textAlign="center">
            <div>
              <Sticky context={stickyRef}>
                <Grid.Row>
                  <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                    {bttnSelected === "warehouses" && (
                      <SearchInput
                        setPredicate={routeSetPredicate}
                        loading={routeLoadingInitial}
                      />
                    )}
                    {bttnSelected === "routes" && (
                      <SearchInput
                        setPredicate={whSetPredicate}
                        loading={whLoadingInitial}
                      />
                    )}
                    {bttnSelected === "couriers" && (
                      <SearchInput
                        setPredicate={cSetPredicate}
                        loading={cLoadingInitial}
                      />
                    )}
                  </div>
                </Grid.Row>
                <Divider horizontal>OU</Divider>
                <Grid.Row>
                  {bttnSelected === "warehouses" && (
                    <RCWCreateButtons type={bttnSelected} />
                  )}
                  {bttnSelected === "routes" && (
                    <RCWCreateButtons type={bttnSelected} />
                  )}
                  {bttnSelected === "couriers" && (
                    <RCWCreateButtons type={bttnSelected} />
                  )}
                </Grid.Row>
              </Sticky>
            </div>
          </Grid.Column>
        </Ref>
      </Grid.Row>
    </Grid>
  );
};

export default observer(RCWDashboard);
