import { endOfDay, startOfDay, startOfMonth } from "date-fns";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { DateTimePicker } from "react-widgets";
import { Header } from "semantic-ui-react";
import IsAuthorized from "../../../app/common/authorization/IsAuthorized";
import SearchInput from "../../../app/common/search/SearchForm";
import { AuthorizationRoles } from "../../../app/models/user";
import { RootStoreContext } from "../../../app/stores/rootStore";
import ReportSubmitButtons from "./ReportSubmitButtons";

const ReportFilters: React.FC<{search: boolean}> = ({search}) => {
  const rootStore = useContext(RootStoreContext);
  const {
    loadingInitial: loading,
    setMultiplePredicate,
  } = rootStore.companyStore;
  const { setPredicate: setReportPredicate } = rootStore.reportStore;

  const [startDate, setStartDate] = useState<Date>(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState<Date>(endOfDay(new Date()));

  useEffect(() => {
    setReportPredicate("startDate", startDate.toISOString());
    setReportPredicate("endDate", endDate.toISOString());
    // eslint-disable-next-line
  }, []);

  const onDateChange = (dateType: string, date: Date | undefined) => {
    if (date) {
      if (dateType === "start") {
        setStartDate(date);
        setReportPredicate("startDate", startOfDay(date).toISOString());
      } else if (dateType === "end") {
        setEndDate(date);
        setReportPredicate("endDate", endOfDay(date).toISOString());
      }
    }
  };

  return (
    <Fragment>
      {search ? 
      <IsAuthorized
        roles={[AuthorizationRoles.Admin]}
        component={
          <SearchInput
            setPredicate={setMultiplePredicate}
            loading={loading}
            limit="-1"
          />
        }
      />
      : null
      }
      <Header>
        <Header.Content>Desde</Header.Content>
        <Header.Subheader>
          <DateTimePicker
            value={startDate}
            format="dd/MM/yyyy"
            max={endDate}
            time={false}
            onChange={(value) => onDateChange("start", value)}
          />
        </Header.Subheader>
        <Header.Content>Até</Header.Content>
        <Header.Subheader>
          <DateTimePicker
            value={endDate}
            format="dd/MM/yyyy"
            min={startDate}
            max={new Date()}
            time={false}
            onChange={(value) => onDateChange("end", value)}
          />
        </Header.Subheader>
      </Header>
      <ReportSubmitButtons />
    </Fragment>
  );
};

export default ReportFilters;
