import { observer } from "mobx-react-lite";
import React, { Fragment, useEffect, useState } from "react";
import { useContext } from "react";
import { Button, Form, Header, Input, Segment } from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import TransportsTable from "../table/TransportsTable";
import CsvPrintButtons from "./CsvPrintButtons";
import CsvResumeStats from "./CsvResumeStats";

const CSVIntegration = () => {
  const rootStore = useContext(RootStoreContext);
  const { loading, createTransportCsv, cleanCsv, hasCsvTransports } =
    rootStore.transportStore;
  const [selectedFile, setSelectedFile] = useState<FileList>();

  useEffect(() => {
    return () => {
      cleanCsv();
    };
  }, [cleanCsv]);

  const onFileChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (ev.target.files) {
      setSelectedFile(ev.target.files);
    }
  };

  const onSubmit = async () => {
    if (selectedFile) {
      const fd = new FormData();
      Array.from(selectedFile).forEach((file) =>
        fd.append("fileIntegration.csv", file)
      );
      createTransportCsv(fd);
    }
  };

  return (
    <Fragment>
      <Segment>
        <CsvResumeStats />
        {hasCsvTransports ? (
          <Fragment>
            <TransportsTable />
            <CsvPrintButtons />
          </Fragment>
        ) : null}
      </Segment>
      <Segment>
        <Header>
          <Header.Content>Adicionar ficheiro(s)</Header.Content>
        </Header>
        <Form
          onSubmit={(_) => {
            onSubmit();
          }}
        >
          <Form.Input>
            <Input multiple type="file" onChange={onFileChange} />
          </Form.Input>
          <Button
            loading={loading}
            disabled={selectedFile === undefined}
            content="Carregar"
          />
        </Form>
      </Segment>
    </Fragment>
  );
};

export default observer(CSVIntegration);
