import { IStatus, ITransport, ITransportStatus, TStatus } from "../models/transport";

export const getCurrentStatus = (transport: ITransport): TStatus => {
  return transport.status.currentStatus;
};

export const isCanceled = (status: TStatus) => {
  return status === TStatus.CANCELED;
};

export const isCanceledStatus = (status: ITransportStatus) => {
  return status.currentStatus === TStatus.CANCELED;
};

export const isDelivered = (status: ITransportStatus) => {
  return status.currentStatus === TStatus.DELIVERED;
};

// export const fromTStatus = (status: TStatus): string => {
//   switch(status){
//     case TStatus.CREATED:
//       return "Criado";
//     case TStatus.CANCELED:
//       return "Cancelado";
//     case TStatus
//   }
// }

export const statusName = {
  created: "Criado",
  // picked_up: "Em trânsito",
  // warehouse: "Em armazém",
  // courier: "Em trânsito",
  delivered: "Entregue",
  in_transit: "Em trânsito",
  canceled: "Cancelado",
};

export const statusListName = [
  "Cancelado", "Criado", "Em trânsito", "Entregue"
]
