import React, { Fragment } from "react";
import { useContext } from "react";
import { Grid, Header, Segment } from "semantic-ui-react";
import WarningMessage from "../../../app/common/messages/WarningMessage";
import { RootStoreContext } from "../../../app/stores/rootStore";

const CsvErrors = () => {
  const rootStore = useContext(RootStoreContext);
  const { csvTransportEnvelope } = rootStore.transportStore;
  return csvTransportEnvelope && csvTransportEnvelope.totalErrors > 0 ? (
    <Grid columns="2">
      {csvTransportEnvelope.isSupplierError ? (
        <Grid.Row>
          "Não tem permissões para criar envios consignados! Contacte a
          XpressBraga."
        </Grid.Row>
      ) : null}
      {csvTransportEnvelope.notFoundAddress.length > 0 ? (
        <Grid.Row>
          {csvTransportEnvelope.notFoundAddress.map((address) => address)}
        </Grid.Row>
      ) : null}
      {csvTransportEnvelope.notConsignatedCompany.length > 0 ? (
        <Grid.Row>
          <Grid.Column>As empresas não lhe estão consignadas!</Grid.Column>
          <Grid.Column>
            {csvTransportEnvelope.notConsignatedCompany.map((address) => (
              <div key={address}>{address}</div>
            ))}
          </Grid.Column>
        </Grid.Row>
      ) : null}
      {csvTransportEnvelope.noCustomerError.length > 0 ? (
        <Grid.Row>
          <Grid.Column>
            <Header>Cliente não existe e não foi possível criar</Header>
          </Grid.Column>
          <Grid.Column>
            {csvTransportEnvelope.noCustomerError.map((customer) => (
              <div key={customer}>{customer}</div>
            ))}
          </Grid.Column>
        </Grid.Row>
      ) : null}
    </Grid>
  ) : (
    <WarningMessage header="Sem erros para apresentar" />
  );
};

export default CsvErrors;
