import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react'
import { RootStoreContext } from '../../../../app/stores/rootStore';
import WarehouseSelectableList from '../../../warehouse/list/WarehouseSelectableList';

interface IProps {
    routeId: number;
    routeWarehouseId: number | null;
}

const RouteSetWarehouse: React.FC<IProps> = ({ routeId, routeWarehouseId }) => {
    const rootStore = useContext(RootStoreContext);
    const { updateWarehouse: updateRouteWh } = rootStore.routeStore;
    const updateWarehouse = (routeId: number) => async (warehouseId: number): Promise<any> => {
        await updateRouteWh(routeId, warehouseId)
    }
    return (
        <WarehouseSelectableList updateFunc={updateWarehouse(routeId)} currentWarehouse={routeWarehouseId} />
    )
}

export default observer(RouteSetWarehouse)