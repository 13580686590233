import React from "react";
import { Grid } from "semantic-ui-react";
import CreateTransportDetailsDescription from "./CreateTransportDetailsDescription";
import CreateTransportDetailsExtra from "./CreateTransportDetailsExtra";
import CreateTransportDetailsVolume from "./CreateTransportDetailsVolume";

interface IProps {
  push: (...args: any) => any;
  pop: (...args: any) => any;
  isRefund: boolean;
  setValue: (...args: any) => any;
}

const CreateTransportDetails: React.FC<IProps> = ({ push, pop, isRefund, setValue }) => {
  return (
    <Grid divided="vertically">
      <CreateTransportDetailsVolume push={push} />
      <CreateTransportDetailsExtra isRefund={isRefund} />
      <CreateTransportDetailsDescription setValue={setValue} />
    </Grid>
  );
};

export default CreateTransportDetails;
