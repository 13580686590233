import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Button, Icon, Message, Table } from "semantic-ui-react";
import IsAuthorized from "../../../app/common/authorization/IsAuthorized";
import { AuthorizationRoles } from "../../../app/models/user";
import { RootStoreContext } from "../../../app/stores/rootStore";

interface IProps {
  _onClick: (...args: any) => any;
}

const CompanyListItem: React.FC<IProps> = ({ _onClick }) => {
  const rootStore = useContext(RootStoreContext);
  const { getCompaniesSliced, company: selfCompany } = rootStore.companyStore;
  const { addCompany } = rootStore.supplierStore;

  return (
    <Table.Body>
      {getCompaniesSliced.map((company) => (
        <Table.Row key={company.vatNumber}>
          <Table.Cell>{company.commercialName}</Table.Cell>
          <Table.Cell>{company.taxName}</Table.Cell>
          <Table.Cell>{company.vatNumber}</Table.Cell>
          <Table.Cell>
            <IsAuthorized
              component={
                selfCompany?.isSupplier &&
                selfCompany.supplier!.companies.findIndex(
                  (comp) => comp.guid === company.guid
                ) === -1 ? (
                  <Button
                    size="small"
                    animated
                    color="green"
                    onClick={() => addCompany(company.guid)}
                  >
                    <Button.Content visible>
                      <Icon name="add" />
                    </Button.Content>
                    <Button.Content hidden>Associar</Button.Content>
                  </Button>
                ) : (
                  <Message size="small" positive>
                    Associado
                  </Message>
                )
              }
              roles={[AuthorizationRoles.Admin]}
            />
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  );
};

export default observer(CompanyListItem);
