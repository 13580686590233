import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import { ICustomer, ICustomerFormValues } from "../models/customer";
import { RootStore } from "./rootStore";
import { v4 as uuid } from "uuid";
import agent from "../api/agent";
import { toast } from "react-toastify";
import { LIMIT_BY_PAGE } from "../common/util/globals";
import { IAddress } from "../models/address";

export default class CustomerStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;

    reaction(
      () => this.predicate.keys(),
      () => {
        this.page = 0;
        this.customerRegistry.clear();
        this.loadCustomers();
      }
    );
  }

  @observable loading: boolean = false;
  @observable customer: ICustomer | null = null;
  @observable customerRegistry = new Map<string, ICustomer>();
  @observable loadingInitial = false;
  @observable submitting = false;
  @observable predicate = new Map();
  @observable customerCount = 0;
  @observable page = 0;
  @observable selectType?: string = undefined;
  @observable loadingNext = false;

  @computed get getCustomerGuid() {
    return this.customer?.customerGuid;
  }

  @computed get axiosParams() {
    const params = new URLSearchParams();
    params.append("limit", LIMIT_BY_PAGE.toString());
    params.append("offset", `${this.page ? this.page * LIMIT_BY_PAGE : 0}`);
    this.predicate.forEach((value, key) => {
      params.append(key, value);
    });
    return params;
  }

  @action setPredicate = (predicate: string, value: string | Date) => {
    this.predicate.clear();
    if (predicate !== "all") {
      this.predicate.set(predicate, value);
    }
  };

  @computed get totalPages() {
    return Math.ceil(this.customerCount / LIMIT_BY_PAGE);
  }

  @computed get selectedType() {
    return this.selectType;
  }
  @computed get customerByName() {
    const slicePage = Array.from(this.customerRegistry.values()).slice(
      this.page * LIMIT_BY_PAGE,
      this.page * LIMIT_BY_PAGE + LIMIT_BY_PAGE
    );
    return this.orderCustomerByName(slicePage);
  }

  @action setPage = (page: number | string) => {
    this.page = Number(page) - 1;
  };

  @action customerSelectType = async (type: string | undefined) => {
    return (this.selectType = type);
  };

  orderCustomerByName(customers: ICustomer[]) {
    return customers.sort((a, b) =>
      a.companyName < b.companyName ? -1 : a.companyName > b.companyName ? 1 : 0
    );
  }

  @action loadCustomer = async (guid: string) => {
    let customer = this.getCustomer(guid);
    this.loading = true;
    if (customer) {
      this.customer = customer;
      this.loading = false;
      return customer;
    } else {
      return true;
    }
  };

  getCustomer = (id: string) => {
    return this.customerRegistry.get(id);
  };

  @action loadCustomers = async () => {
    this.loadingInitial = true;
    try {
      let params = this.axiosParams;
      const customerEnvelope = await agent.Customer.list(
        this.rootStore.companyStore.company!.guid,
        params
      );
      const { customers, customerCount } = customerEnvelope;
      runInAction(() => {
        customers.forEach((customer) => {
          this.customerRegistry.set(customer.customerGuid, customer);
        });
        this.customerCount = customerCount;
        this.loadingInitial = false;
      });
    } catch (error) {
      this.loadingInitial = false;
    }
  };

  @action createCustomer = async (customer: ICustomerFormValues) => {
    this.submitting = true;
    customer.customerGuid = uuid(); // generate Guid customer
    customer.address.addressGuid = uuid(); // generate Guid Address
    customer.address.location?.toUpperCase();
    try {
      const customerDto = await agent.Customer.create(
        customer,
        this.rootStore.companyStore.company!.guid
      );
      runInAction(() => {
        this.customer = customerDto;
        this.rootStore.modalStore.closeModal();
        this.submitting = false;
      });
      return customerDto;
    } catch (error) {
      // const { data } = error;
      // toast.error(Object.values<string>(data.errors)[0]);
      runInAction(() => {
        this.submitting = false;
      });
    }
  };

  @action addAddress = (address: IAddress) => {
    this.customer!.addresses = this.customer!.addresses.filter(
      (x) => x.addressGuid !== address.addressGuid
    );
    this.customer?.addresses.push(address);
    this.customerRegistry.set(this.customer!.customerGuid, this.customer!);
    this.submitting = false;
    this.rootStore.transportStore.addCustomer(this.customer!);
    return address;
  };
}
