import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Button, Grid, Icon } from "semantic-ui-react";
import IsAuthorized from "../../../app/common/authorization/IsAuthorized";
import { AuthorizationRoles } from "../../../app/models/user";
import { RootStoreContext } from "../../../app/stores/rootStore";
import CreateCustomer from "../../customer/create/CreateCustomer";
import CustomerDashboard from "../../customer/dashboard/CustomerDashboard";
import SupplierDashboard from "../../supplier/dashboard/SupplierDashboard";
import CreateTransportSelectAddress from "./CreateTransportSelectAddress";

interface IProps {
  setAddress: (...args: any) => any;
  addressType: string;
}

const CreateTransportCustomer: React.FC<IProps> = ({
  addressType,
  setAddress,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { customerSelectType } = rootStore.customerStore;
  const { openModal } = rootStore.modalStore;
  return (
    <Grid padded>
      <Grid.Row>
        <Button.Group fluid>
          <Button
            type="button"
            color="teal"
            onClick={() => {
              customerSelectType(addressType);
              openModal(
                <CustomerDashboard
                  setValues={setAddress}
                  addressType={addressType}
                />
              );
            }}
          >
            <Icon name="address book" />
            Selecionar da lista de destinatários
          </Button>
          <IsAuthorized
            roles={[AuthorizationRoles.Supplier]}
            component={
              <Button
                type="button"
                color="red"
                onClick={() => {
                  customerSelectType(addressType);
                  openModal(
                    <SupplierDashboard
                      setValues={setAddress}
                      addressType={addressType}
                    />
                  );
                }}
              >
                <Icon name="address card" />
                Empresas consignadas
              </Button>
            }
          />
          <Button
            type="button"
            positive
            onClick={() => {
              customerSelectType(addressType);
              openModal(
                <CreateCustomer
                  onSubmit={setAddress}
                  addressPrefix={addressType}
                />
              );
            }}
          >
            <Icon name="add" />
            Criar novo cliente
          </Button>
        </Button.Group>
      </Grid.Row>
      <Grid.Row>
        <CreateTransportSelectAddress
          addressType={addressType}
          setAddress={setAddress}
        />
      </Grid.Row>
    </Grid>
  );
};

export default observer(CreateTransportCustomer);
