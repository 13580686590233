import _ from "lodash";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Button, Dropdown, Grid, Header, List, ListItem, Segment } from "semantic-ui-react";
import Agent from "../../app/api/agent";
import { openUriPdf } from "../../app/common/util/exportPdf";
import { ShiftType } from "../../app/models/shift";
import { RootStoreContext } from "../../app/stores/rootStore";
import WarehouseCourierVolumes from "./WarehouseCourierVolumes";
import WareHouseSidebar from "./WareHouseSidebar";
import WarehouseVolumes from "./WarehouseVolumes";

const shiftDefinitions = {
  shiftName: ["Manhã", "Tarde"],
  shiftType: [ShiftType.MORNING, ShiftType.AFTERNOON],
};
const shiftOptions = _.map(shiftDefinitions.shiftName, (state, index) => ({
  key: shiftDefinitions.shiftType[index],
  text: state,
  value: shiftDefinitions.shiftType[index],
}));

const WareHouseDashboard: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { loadFuelFees } = rootStore.fuelFeeStore;
  const { loadCouriers, couriers } = rootStore.courierStore;
  const [shift, setShift] = useState<ShiftType>(ShiftType.MORNING);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    loadFuelFees();
  }, [loadFuelFees]);

  return (
    <Grid>
      <Grid.Column width="12">
        <Segment>
          <Header as="h4">Escolher o turno a distribuir:</Header>
          <Dropdown
            placeholder="Turno"
            selection
            options={shiftOptions}
            onChange={(_, data) =>
              setShift(
                data.value === 0 ? ShiftType.MORNING : ShiftType.AFTERNOON
              )
            }
          />
          <Button
            content="Distribuir por cliente"
            primary
            loading={loading}
            disabled={loading}
            onClick={async () => {
              setLoading(true);
              let uri = await Agent.Report.transportByCourier(shift);
              openUriPdf(uri);
              setLoading(false);
            }}
          />
        </Segment>
        <WarehouseCourierVolumes />
        <WarehouseVolumes />
      </Grid.Column>
      <Grid.Column width="4">
        <WareHouseSidebar />
      </Grid.Column>
    </Grid>
  );
};

export default observer(WareHouseDashboard);
