import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { Pagination, Segment, Table } from "semantic-ui-react";
import SearchInput from "../../../app/common/search/SearchForm";
import { RootStoreContext } from "../../../app/stores/rootStore";
import CompanyListItem from "./CompanyListItem";

interface IProps {
  _onClick: (...args: any) => any;
}

const CompanyList: React.FC<IProps> = ({ _onClick }) => {
  const rootStore = useContext(RootStoreContext);
  const {
    setPage,
    page,
    totalPages,
    loadCompanies,
    loadingInitial,
    setPredicate,
  } = rootStore.companyStore;

  useEffect(() => {
    loadCompanies();
    return function cleanup() {
      setPredicate("all","all");
    }
  }, [loadCompanies, page, setPredicate]);

  return (
    <Segment.Group>
      <SearchInput loading={loadingInitial} setPredicate={setPredicate} />
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Nome Comercial</Table.HeaderCell>
            <Table.HeaderCell>Nome Fiscal</Table.HeaderCell>
            <Table.HeaderCell>Contribuinte</Table.HeaderCell>
            <Table.HeaderCell width={2}>Adicionar</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <CompanyListItem _onClick={_onClick} />
      </Table>
      <Pagination
        totalPages={totalPages}
        activePage={page + 1}
        onPageChange={(_, { activePage }) => setPage(activePage!)}
      />
    </Segment.Group>
  );
};

export default observer(CompanyList);
