import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react'
import { Button, Checkbox, Header, List, ListItem, Segment, SegmentGroup } from 'semantic-ui-react'
import Agent from '../../app/api/agent';
import { openUriPdf } from '../../app/common/util/exportPdf';
import { RootStoreContext } from '../../app/stores/rootStore';

const WarehouseCourierVolumes = () => {
    const rootStore = useContext(RootStoreContext);
    const { loadCouriers, couriers } = rootStore.courierStore;
    const [selectedCouriers, setSelectedCouriers] = useState<{ [key: string]: boolean }>({})
    useEffect(() => {
        loadCouriers();
        return () => {
        }
    }, [loadCouriers])


    const handleSelect = (courierId: string) => {
        setSelectedCouriers(prevValue => {
            prevValue[courierId] = !prevValue[courierId] ?? true;
            return { ...prevValue }
        })
    }

    const submit = async () => {
        let uri = await Agent.Report.courierDeliveryVolumes(Object.entries(selectedCouriers).filter(x => x[1]).map(x => x[0]))
        openUriPdf(uri);
    }
    return (
        <>
            <Header as='h3'>Volumes por estafeta</Header>
            <SegmentGroup horizontal>
                <Segment>
                    <List>
                        {couriers.map(x => (
                            <ListItem key={x.id}>
                                <Checkbox label={x.displayname}
                                    onChange={() => handleSelect(x.id)} />
                            </ListItem>
                        ))}
                    </List>
                </Segment>
                <Segment textAlign='center'>
                    <Button
                        onClick={submit}
                        content="Obter listagem"
                    />
                </Segment>
            </SegmentGroup>
        </>
    )
}

export default observer(WarehouseCourierVolumes)