import React, { Fragment } from 'react'
import { IAddress } from '../../models/address'
interface IProps {
    address: IAddress;
}
const AddressInfoInline: React.FC<IProps> = ({address}) => {

    return (
        <Fragment>
            {address.addressFirst ? address.addressFirst : "" }, {address.addressSecond ? address.addressSecond : "" },
            {address.leftPostCode ? address.leftPostCode : "" } - {address.rightPostCode ? address.rightPostCode : "" } {address.location ? address.location : "" },
            {address.country ? address.country : "" }
        </Fragment>
    )
}

export default AddressInfoInline
