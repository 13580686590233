import React from "react";
import { Placeholder, Table } from "semantic-ui-react";

const TableItemPlaceholder: React.FC<{ colSpan: string }> = ({ colSpan }) => {
  return (
    <Table.Row>
      <Table.Cell colSpan={colSpan}>
        <Placeholder>
          <Placeholder.Header>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
        </Placeholder>
      </Table.Cell>
    </Table.Row>
  );
};

export default TableItemPlaceholder;
