import { PostCode } from "./geo";
export interface IRouteSummary {
  id: number;
  name: string;
}
export interface IRoute {
  id: number;
  name: string;
  courierId: string
  courierDisplayname: string
  warehouseId: number;
  warehouseName: string;
  warehouseAbbreviation: string;
  postcodes?: PostCode[]; // only in detailed mode!!!
}

export class RouteFormValues {
  routeName: string = "";

  constructor(init?: RouteFormValues) {
    Object.assign(this, init);
  }
}

export interface IEditRouteQuery {
  oldName: string;
  newName: string;
}
export class RouteEditFormValues implements IEditRouteQuery {
  oldName: string = "";
  newName: string = "";

  constructor(oldName : string) {
    this.oldName = oldName;
  }
}

export interface IRouteEnvelope {
  routesDTO: IRoute[];
}
