import React, { Fragment } from 'react'
import { get, FieldErrorsImpl, FieldValues, UseFieldArrayAppend, UseFieldArrayRemove, UseFormRegister } from 'react-hook-form';
import { composeValidators } from 'revalidate';
import { Grid, Header, Button, Icon, Table } from 'semantic-ui-react';
import FormTextInput from '../../../../app/common/form/FormTextInput';
import { required } from '../../../../app/common/form/Validators';
import { volumeType, weightBool } from '../../../../app/common/options/ServiceType';
import { Volume } from '../../../../app/models/transport';

interface IProps {
    fields: any[];
    append: UseFieldArrayAppend<FieldValues, "volumes">;
    remove: UseFieldArrayRemove;
    register: UseFormRegister<FieldValues>;
    errors: Partial<FieldErrorsImpl<{
        [x: string]: any;
    }>>;
    changeVolumeType: (...args: any) => any;
}

const CreateTransportAdminVolume: React.FC<IProps> = ({
    fields,
    append,
    remove,
    register,
    errors,
    changeVolumeType
}) => {

    return (
        <Fragment>
            <Grid>

                <Grid.Row columns={2}>
                    <Grid.Column textAlign="right">
                        <Header as="h1">Volumes</Header>
                    </Grid.Column>
                    <Grid.Column textAlign="left">
                        <Button
                            type="button"
                            onClick={() => append(new Volume({ width: "0", height: "0", weight: "0", length: "0", volumeType: "standard", volumeGuid: "" }))}
                            positive
                        >
                            <Icon name="first order" />
                            Adicionar volume
                        </Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Table attached celled padded>
                        <Table.Header>
                            <Table.Row textAlign="center">
                                <Table.HeaderCell width={3}>Tipo de Volume</Table.HeaderCell>
                                <Table.HeaderCell width={3}>Comprimento (cm)</Table.HeaderCell>
                                <Table.HeaderCell width={3}>Largura (cm)</Table.HeaderCell>
                                <Table.HeaderCell width={3}>Altura (cm)</Table.HeaderCell>
                                <Table.HeaderCell width={3}>Peso (kg)</Table.HeaderCell>
                                <Table.HeaderCell width={1}>Apagar</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {fields.map((x, index) => (
                                <Table.Row key={x.id}>
                                    <Table.Cell>
                                        <select
                                            {...register(`volumes.${index}.volumeType` as any, {
                                                onChange: (e) => changeVolumeType(index, e.target.value)
                                            })}
                                        >
                                            {volumeType.map(y => <option key={y.key} value={y.value}>{y.text}</option>)}
                                        </select>
                                        {/* <Field
                                            name={`${name}.volumeType`}
                                            component={SelectInput}
                                            options={volumeType}
                                            defaultValue={volumeType[0].value}
                                        /> */}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <FormTextInput
                                            placeholder='0'
                                            register={register}
                                            validation={required}
                                            errors={errors}
                                            name={`volumes.${index}.width`}
                                            type="number"
                                            disabled
                                        />
                                    </Table.Cell>
                                    <Table.Cell>
                                        <FormTextInput
                                            placeholder='0'
                                            register={register}
                                            validation={required}
                                            errors={errors}
                                            name={`volumes.${index}.length`}
                                            type="number"
                                            disabled
                                        />
                                    </Table.Cell>
                                    <Table.Cell>
                                        <FormTextInput
                                            placeholder='0'
                                            register={register}
                                            validation={required}
                                            errors={errors}
                                            name={`volumes.${index}.height`}
                                            type="number"
                                            disabled
                                        />
                                    </Table.Cell>
                                    <Table.Cell>
                                        <FormTextInput
                                            placeholder='0'
                                            register={register}
                                            validation={composeValidators(required)}
                                            errors={errors}
                                            name={`volumes.${index}.weight`}
                                            type="number"
                                            disabled={weightBool[x.volumeType]}
                                        />
                                        {/* <Field
                                            defaultValue={
                                                fields.value[index] &&
                                                    weightBool[fields.value[index].volumeType]
                                                    ? "0"
                                                    : undefined
                                            }
                                            name={`${x}.weight`}
                                            type="number"
                                            validate={composeValidators(
                                                required,
                                                isNumber,
                                                greaterThanOrEqual(0)
                                            )}
                                        >
                                            {(props) => (
                                                <Input
                                                    fluid
                                                    {...props}
                                                    label={{ basic: true, content: "kg" }}
                                                    labelPosition="right"
                                                />
                                            )}
                                        </Field> */}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Button
                                            type="button"
                                            name="delete"
                                            icon="delete"
                                            onClick={() => remove(index)}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            ))
                            }
                        </Table.Body>
                    </Table>
                </Grid.Row>
            </Grid>
        </Fragment >
    )
}

export default CreateTransportAdminVolume