import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useState } from "react";
import { Button, Header, Icon } from "semantic-ui-react";
import IsAuthorized from "../../../app/common/authorization/IsAuthorized";
import { AuthorizationRoles } from "../../../app/models/user";
import { RootStoreContext } from "../../../app/stores/rootStore";

const ReportSubmitButtons = () => {
  const rootStore = useContext(RootStoreContext);
  const { generateReport, getSelectedCompanies, loading } =
    rootStore.reportStore;
  const [load, setLoad] = useState(false);
  const [groupVolumes, setGroupVolumes] = useState<number>(1);
  return (
    <Fragment>
      <Header>
        <Header.Content>Débitos</Header.Content>
        <Button.Group fluid>
          <Button
            icon
            labelPosition="left"
            loading={loading.get("resumedDebit") && load}
            color="red"
            size="small"
            onClick={async () => {
              setLoad(true);
              await generateReport(getSelectedCompanies, "resumedDebit");
              setLoad(false);
            }}
          >
            <Icon name="user" /> Resumido
          </Button>
          <Button.Or text="-" />
          <Button
            icon
            loading={loading.get("detailedDebit") && load}
            labelPosition="right"
            color="grey"
            size="small"
            onClick={async () => {
              setLoad(true);
              await generateReport(getSelectedCompanies, "detailedDebit");
              setLoad(false);
            }}
          >
            <Icon name="user" /> Detalhado
          </Button>
        </Button.Group>
      </Header>
      <Header>
        <Header.Content>Transportes</Header.Content>
        <Button.Group fluid>
          <Button
            icon
            labelPosition="left"
            color="red"
            size="small"
            loading={loading.get("resumedTransport") && load}
            onClick={async () => {
              setLoad(true);
              await generateReport(getSelectedCompanies, "resumedTransport");
              setLoad(false);
            }}
          >
            <Icon name="user" /> Resumido
          </Button>
          <Button.Or text="-" />
          <Button
            icon
            labelPosition="right"
            size="small"
            color="grey"
            loading={loading.get("detailedTransport") && load}
            onClick={async () => {
              setLoad(true);
              await generateReport(getSelectedCompanies, "detailedTransport");
              setLoad(false);
            }}
          >
            <Icon name="user" /> Detalhado
          </Button>
        </Button.Group>
      </Header>
      <Header>
        <Header.Content>Outros</Header.Content>
        <Button.Group fluid>
          <Button
            primary
            color="grey"
            loading={loading.get("refunds") && load}
            onClick={async () => {
              setLoad(true);
              await generateReport(getSelectedCompanies, "refunds");
              setLoad(false);
            }}
          >
            Reembolsos
          </Button>
          <IsAuthorized
            roles={[AuthorizationRoles.Admin]}
            component={
              <Button
                color="grey"
                loading={loading.get("volumesShift") && load}
                onClick={async () => {
                  setLoad(true);
                  await generateReport(getSelectedCompanies, "volumesShift");
                  setLoad(false);
                }}
              >
                Volumes Agrupados
              </Button>
            }
          />
        </Button.Group>
      </Header>
    </Fragment>
  );
};

export default observer(ReportSubmitButtons);
