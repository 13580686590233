import {
    action,
    makeObservable,
    observable,
    runInAction,
  } from "mobx";
  import { RootStore } from "./rootStore";
import { IZone } from "../models/zone";
import { getZones } from "../api/ZoneRequest";
import Agent from "../api/agent";
  
  export default class ZoneStore {
    rootStore: RootStore;
  
    constructor(rootStore: RootStore) {
      makeObservable(this);
      this.rootStore = rootStore;
    }
  
    @observable zones: IZone[] = [];
    @observable loading = false;
    @observable submiting = false;

    @action loadZones = async () => {
        this.loading = true;
        const zones = await getZones();
        runInAction(() => {
            this.zones = zones;
            this.loading = false;
        })
    }

    @action createZone = async (zone : IZone) => {
        this.submiting = true;
        try {
            await Agent.Zones.create(zone);
            runInAction(() => {
                this.zones.push(zone);
                this.submiting = false;
                this.rootStore.modalStore.closeModal();
            })
        } catch (error) {
            console.log(error);
            this.submiting = false;
        }
    }
  
  }
  