import { IAddress } from "./address";
import { IRoute, IRouteSummary } from "./route";
import { IWarehouse } from "./warehouse";

export type role = "Admin" | "Company" | "Supplier" | "Courier";

interface Role {
  [key: string]: role;
}

export const AuthorizationRoles: Role = {
  Admin: "Admin",
  Company: "Company",
  Supplier: "Supplier",
  Courier: "Courier",
};
export type roles = role[];

export interface IUser {
  id: string;
  username: string;
  displayname: string;
  email: string;
  token: string;
  accounttype: string;
  roles: roles;
  address: IAddress;
  active: boolean;
}

export interface ICourier extends IUser {
  routes?: IRoute[];
  warehouse?: IWarehouse; // doesn't exist!
  warehouseId: number;
  warehouseName: string;
  routeNames: IRouteSummary[];
  transhipment: boolean;
}

export interface IUserFormValues extends Partial<IUser> {
  password: string;
  passwordConfirm: string;
  addressGuid: string;
}

export interface ICourierFormValues extends Partial<ICourier> {
  password: string;
  passwordConfirm: string;
}


export class UserFormValues implements IUserFormValues {
  email: string = "";
  username?: string = "";
  password: string = "";
  displayname?: string = "";
  accounttype: string = "";
  passwordConfirm: string = "";
  addressGuid: string = "";

  constructor(init?: IUserFormValues) {
    Object.assign(this, init);
  }
}

export interface ICourierEditQuery extends Partial<ICourierFormValues> {
  active: boolean;
  transhipment: boolean;
}
export class CourierEditFormValues implements ICourierEditQuery {
  username: string = "";
  displayname: string = "";
  password: string = "";
  passwordConfirm: string = "";
  transhipment: boolean = false;
  active: boolean =  true;

  constructor(init?: ICourier) {
    Object.assign(this, init);
  }
}

export class CourierFormValues implements ICourierFormValues {
  // email?: string = "";
  // accounttype?: string = "";
  username: string = "";
  displayname: string = "";
  password: string = "";
  passwordConfirm: string = "";
  transhipment: boolean = false;

  constructor(init?: ICourierFormValues) {
    Object.assign(this, init);
  }
}

export interface ICourierEnvelope {
  couriers: ICourier[];
  totalCount: number;
}
