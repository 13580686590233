import { observer } from "mobx-react-lite";
import React, { Fragment } from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Container,
  Header,
  Segment,
  Image,
  Grid,
  Divider,
} from "semantic-ui-react";
import { PT_pt } from "../../app/common/util/expressions";
import { getIn } from "../../app/common/util/util";
import { RootStoreContext } from "../../app/stores/rootStore";
import LoginForm from "../user/LoginForm";

const HomePage = () => {
  const rootStore = useContext(RootStoreContext);
  const { isLoggedIn, user } = rootStore.userStore;
  const { loading } = rootStore.companyStore;

  return (
    <Segment inverted textAlign="center" vertical className="masthead">
      <Container text fluid>
          <Image
          fluid
            src="/assets/logo.png"
            alt="logo"
            style={{ marginBottom: 12 }}
          />
        {isLoggedIn && user ? (
          <Fragment>
            <Header
              as="h2"
              inverted
              content={`${PT_pt.translate.welcome}, ${user.displayname}`}
            />
            <Button as={Link} loading={loading} to={getIn[user!.roles[0]]} size="huge" color='red' inverted>
              Entrar
            </Button>
          </Fragment>
        ) : (
          <Segment placeholder>
            <Grid columns={2} relaxed="very" stackable>
              <Grid.Column>
                <LoginForm />
              </Grid.Column>

              <Grid.Column verticalAlign="middle">
                <Button content="Contacte-nos" icon="inbox" size="big" as={Link} to={`/`} />
              </Grid.Column>
            </Grid>

            <Divider vertical>Ou</Divider>
          </Segment>
        )}
      </Container>
    </Segment>
  );
};

export default observer(HomePage);
