import { format } from "date-fns";
import React, { Fragment } from "react";
import {
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Label,
  Step,
  Table,
} from "semantic-ui-react";
import {
  isCanceledStatus,
  statusListName,
  statusName,
} from "../../../app/dataAccess/TransportData";
import { ITransportStatus, TStatus } from "../../../app/models/transport";
import { pt } from "date-fns/locale";

interface IProps {
  tStatus: ITransportStatus;
}
// steps:
// waiting_pickup -> picked_up -> warehouse -> courier -> delivered
const TransportStatus: React.FC<IProps> = ({ tStatus }) => {
  const currentStatus = tStatus.history[tStatus.history.length - 1];
  const { history : status } = tStatus;
  return (
    <Fragment>
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column textAlign="left">
            <Container>
              Data criação:
              <Header>
                <Header.Content>
                  {format(
                    new Date(status[0].modificationDate),
                    "dd/MM/y"
                  )}
                </Header.Content>
              </Header>
            </Container>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Container>
              Última atualização
              <Header>
                <Header.Content>
                  {format(new Date(currentStatus.modificationDate), "dd/MM/y", {locale: pt})}
                </Header.Content>
              </Header>
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider />
      <Container textAlign="center">
        {isCanceledStatus(tStatus) ? (
          <Step.Group active>
            <Step active>
              <Icon name="close" />
              <Step.Content>
                <Step.Title>Cancelado</Step.Title>
                <Step.Description>O transporte foi cancelado</Step.Description>
              </Step.Content>
            </Step>
          </Step.Group>
        ) : (
          <Step.Group ordered stackable="tablet">
            <Step
              active={currentStatus.status === TStatus.CREATED}
              completed={status.length == 1}
              disabled={status.length < 1}
            >
              {/* <Icon name='truck' /> */}
              <Step.Content>
                <Step.Title>Criado</Step.Title>
                <Step.Description>A aguardar recolha</Step.Description>
              </Step.Content>
            </Step>
            {/* <Step
              active={currentStatus.statusName === "picked_up"}
              completed={status.length > 2}
              disabled={status.length < 2}
            >
              <Step.Content>
                <Step.Title>Recolhido</Step.Title>
                <Step.Description>para o armazém</Step.Description>
              </Step.Content>
            </Step>
            <Step
              active={currentStatus.statusName === "warehouse"}
              completed={status.length > 3}
              disabled={status.length < 3}
            >
              <Step.Content>
                <Step.Title>Armazém</Step.Title>
                <Step.Description>Em processamento</Step.Description>
              </Step.Content>
            </Step>
            <Step
              active={currentStatus.statusName === "courier"}
              completed={status.length > 4}
              disabled={status.length < 4}
            >
              <Step.Content>
                <Step.Title>Em distribuição</Step.Title>
                <Step.Description>Será entregue hoje!</Step.Description>
              </Step.Content>
            </Step> */}
            <Step
              active={currentStatus.status === TStatus.DELIVERED}
              completed={status.length === 2}
              disabled={status.length < 2}
            >
              <Step.Content>
                <Step.Title>Entregue</Step.Title>
                <Step.Description>A encomenda já foi entregue!</Step.Description>
              </Step.Content>
            </Step>
          </Step.Group>
        )}
      </Container>
      <Divider />
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Data</Table.HeaderCell>
            <Table.HeaderCell>Estado</Table.HeaderCell>
            <Table.HeaderCell>Observações</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {status.map((eStatus, i) => (
            <Table.Row key={i}>
              <Table.Cell>
                {i === 0 ? (
                  <Label>
                    {" "}
                    {format(new Date(eStatus.modificationDate), "dd/MM/y HH:mm", {locale: pt})}{" "}
                  </Label>
                ) : (
                  format(new Date(eStatus.modificationDate), "dd/MM/y HH:mm", {locale: pt})
                )}
              </Table.Cell>
              <Table.Cell>{statusListName[eStatus.status]}</Table.Cell>
              <Table.Cell>Sem observações</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Fragment>
  );
};

export default TransportStatus;
