import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Table } from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import SupplierCompanyListItem from "./SupplierCompanyListItem";

interface IProps {
  selectCompanySupplier: (...args: any[]) => any;
}

const SupplierCompanyList: React.FC<IProps> = ({ selectCompanySupplier }) => {
  const rootStore = useContext(RootStoreContext);
  const {
    getCompanies,
    supplierCompanyCount
  } = rootStore.supplierStore;

  return (
    <Table celled selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Nome </Table.HeaderCell>
          <Table.HeaderCell>Empresa</Table.HeaderCell>
          <Table.HeaderCell>Contribuinte</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {supplierCompanyCount ? (
          getCompanies.map((company) => (
            <SupplierCompanyListItem
              key={company.guid}
              selectCustomer={selectCompanySupplier}
              company={company}
            />
          ))
        ) : (
          <Table.Row>
            <Table.Cell>
              Não existem clientes com os critérios pesquisados
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};

export default observer(SupplierCompanyList);
