import React, { Fragment, useEffect, useState } from "react";
import { Button, Form, Input, Segment } from "semantic-ui-react";
import Agent from "../../../app/api/agent";
import {
  changeStatus,
  deleteTransport,
} from "../../../app/api/TransportRequest";
import IsAuthorized from "../../../app/common/authorization/IsAuthorized";
import NegativeMessage from "../../../app/common/messages/NegativeMessage";
import WarningMessage from "../../../app/common/messages/WarningMessage";
import {
  getCurrentStatus,
  isCanceled,
  isDelivered,
} from "../../../app/dataAccess/TransportData";
import { ITransport } from "../../../app/models/transport";
import { AuthorizationRoles } from "../../../app/models/user";
import TransportAddressInfo from "../details/TransportAddressInfo";
import TransportRefundInfo from "../details/TransportRefundInfo";
import TransportStatus from "./TransportStatus";

const StatusDashboard: React.FC = () => {
  const [searchValue, setSearchValue] = useState("");
  const [transport, setTransport] = useState<ITransport | undefined>(undefined);
  const [error, setError] = useState(false);

  const load = (id?: number) => {
    setTransport(undefined);
    setError(false);
    Agent.Transport.detailsById(id ? id : parseInt(searchValue))
      .then((tranport) => {
        setTransport(tranport);
      })
      .catch(() => setError(true));
  };

  useEffect(() => {
    return () => {
      setError(false);
      setTransport(undefined);
    };
  }, [setError, setTransport]);

  return (
    <Segment.Group>
      <Segment>
        <Form>
          <Input
            type="number"
            onChange={(_, data) => setSearchValue(data.value)}
            fluid
            value={searchValue}
            placeholder="Nº Guia..."
          >
            <input />
            <Button
              role="search_button" // esta role permite que a pagina seja atualizada pela modal
              type="submit"
              color="red"
              content="Pesquisar"
              onClick={() => load()}
            />
          </Input>
        </Form>
      </Segment>
      <Segment>
        {error ? (
          <NegativeMessage
            header="Erro na pesquisa"
            message="O transporte não existe ou não tem permissões para aceder!"
          />
        ) : transport === undefined ? null : (
          <Fragment>
            <IsAuthorized
              roles={[AuthorizationRoles.Admin]}
              component={
                <Fragment>
                  <Button.Group widths={2}>
                    <Button
                      fluid
                      content="Anterior"
                      icon="left arrow"
                      labelPosition="left"
                      onClick={() => {
                        let value = Math.max(parseInt(searchValue) - 1, 1);
                        setSearchValue(value.toString());
                        load(value);
                      }}
                    />
                    <Button
                      fluid
                      content="Próximo"
                      icon="right arrow"
                      labelPosition="right"
                      onClick={() => {
                        let value = parseInt(searchValue) + 1;
                        setSearchValue(value.toString());
                        load(value);
                      }}
                    />
                  </Button.Group>
                </Fragment>
              }
            />
            {isCanceled(getCurrentStatus(transport)) ||
            isDelivered(transport.status) ? null : (
              <Segment basic>
                <Button.Group widths={2}>
                  <IsAuthorized
                    roles={[AuthorizationRoles.Admin]}
                    component={
                      <Button
                        color="red"
                        content="Cancelar envio"
                        onClick={() =>
                          deleteTransport(transport.transportGuid).then(() =>
                            load()
                          )
                        }
                      />
                    }
                  />
                  <IsAuthorized
                    roles={[
                      AuthorizationRoles.Admin,
                      AuthorizationRoles.Courier,
                    ]}
                    component={
                      <Button
                        color="green"
                        content="Entregue"
                        onClick={async () => {
                          await changeStatus(
                            transport.transportGuid,
                            "delivered"
                          );
                          load();
                        }}
                      />
                    }
                  />
                </Button.Group>
              </Segment>
            )}
            <TransportAddressInfo transport={transport!} />
          </Fragment>
        )}
      </Segment>
      <Segment textAlign="center">
        {transport ? (
          <Fragment>
            <TransportStatus tStatus={transport.status} />
            <TransportRefundInfo refund={transport.refund} />
          </Fragment>
        ) : null}

        <WarningMessage
          header="Atenção"
          message="Módulo em desenvolvimento! O estado apresentado pode não apresentar a realidade."
        />
      </Segment>
    </Segment.Group>
  );
};

export default StatusDashboard;
