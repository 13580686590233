import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import { toast } from "react-toastify";
import Agent from "../api/agent";
import { addCourier, createWarehouse } from "../api/WarehouseRequest";
import { PT_pt } from "../common/util/expressions";
import { LIMIT_BY_PAGE } from "../common/util/globals";
import { IRoute } from "../models/route";
import { ICourier } from "../models/user";
import { IWarehouse, WarehouseFormValues } from "../models/warehouse";
import { RootStore } from "./rootStore";

export default class WarehouseStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    reaction(
      () => this.predicate.keys(),
      () => {
        this.page = 0;
        this.clearWarehouses();
        this.loadWarehouses();
      }
    );
  }

  @observable warehouses: IWarehouse[] = [];
  @observable warehouse: IWarehouse | null = null;
  @observable loading = false;
  @observable submitting = false;
  @observable loadingInitial = false;
  @observable predicate = new Map();
  @observable page = 0;
  @observable whCount = 0;

  @computed get axiosParams() {
    const params = new URLSearchParams();
    if (!this.predicate.has("limit"))
      params.append("limit", (LIMIT_BY_PAGE*5).toString());
    params.append("offset", `${this.page ? this.page * LIMIT_BY_PAGE : 0}`);
    this.predicate.forEach((value, key) => {
      params.append(key, value);
    });
    return params;
  }

  @computed get totalPages() {
    return Math.ceil(this.whCount / LIMIT_BY_PAGE);
  }

  @action setPage = (page: number | string) => {
    this.page = Number(page) - 1;
  };

  @action setInifinityPage = (page: number) => {
    this.page = page;
  };

  @action addWarehouseRoute = (route: IRoute) => {
    this.warehouse?.routes?.push(route);
  }
  /**
   * Método para fazer o load inicial de todas os armazens no sistema
   * Vai popular a var "warehouses"
   */
  @action loadWarehouses = async () => {
    this.loadingInitial = true;
    try {
      let params = this.axiosParams;
      if (this.warehouse)
        params.append("warehouseId", this.warehouse.id.toString());
      const warehouseEnvelope = await Agent.Warehouses.list(params);
      const { warehousesDTO, warehouseCount } = warehouseEnvelope;
      runInAction(() => {
        this.warehouses = []
        this.warehouses.push(...warehousesDTO)
        this.whCount = warehousesDTO.length;
        this.loadingInitial = false;
      });
    } catch (error) {
      console.log(error);
      this.loadingInitial = false;
    }
  };

  /**
   * Load do armazem pelo seu id, para ser apresentada detalhadamente
   * Atualiza a rota na lista
   * @param id armazem a dar load
   */
  @action loadWarehouse = async (id: number) => {
    // let warehouse = this.getWarehouse(id);
    if (this.warehouse != null && this.warehouse.id === id) return;
    try {
      this.loading = true;
      let warehouse = await Agent.Warehouses.details(id);
      runInAction(() => {
        if (warehouse) {
          console.log('warehouse', JSON.stringify(warehouse))
          this.warehouses = this.warehouses.filter((x) => x.id !== id);
          this.warehouses.push(warehouse);
          this.warehouse = warehouse;
        }
      });
      return warehouse;
    } catch (error) {
      toast.error(PT_pt.errors.submitting);
    } finally {
      this.loading = false;
    }
  };

  /**
   * Método usado para adicionar um armazem novo
   * @param warehouse armazém a ser adicionado
   */
  @action createWarehouse = async (values: WarehouseFormValues) => {
    this.submitting = true;
    try {
      let warehouse = await createWarehouse(values);
      runInAction(() => {
        this.warehouses.push(warehouse);
        this.whCount++;
      });
    } catch (error) {
      throw error;
    } finally {
      this.submitting = false;
    }
  };

  /**
   * Método para adicionar um estafeta ao armazém
   * @param courier estafeta a ser adicionado
   */
  @action addCouriers = async (warehouseId: number, couriers: string[]) => {
    try {
      this.submitting = true;

      await addCourier(warehouseId, couriers);

      runInAction(() => {
        this.rootStore.courierStore.updateCourierWarehouse(couriers[0], warehouseId, this.warehouses.filter(x => x.id === warehouseId)[0].name)
        if (this.warehouse && this.warehouse?.couriers) {
          let addCouriers: ICourier[] = couriers.map(cId => {
            let courier = this.rootStore.courierStore.getCourier(cId);
            courier.warehouseId = warehouseId;
            return courier;
          })
          this.warehouse.couriers.push(...addCouriers)
        }
      });
      this.rootStore.modalStore.closeModal();
    } catch (error) {
      toast.error(PT_pt.errors.submitting);
    } finally {
      this.submitting = false;
    }
  };

  addCourier = (whId: number, c: string) => {
    let courier = this.rootStore.courierStore.getCourier(c);
    let warehouse = this.getWarehouse(whId);

    if (warehouse && courier) warehouse.couriers?.push(courier);
  };

  getWarehouse = (id: number) => {
    try {
      for (let index = 0; index < this.warehouses.length; index++) {
        const element = this.warehouses[index];
        if (element.id === id) return element;
      }
      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  hasWarehouse = (id: number, whs: IWarehouse[]) => {
    try {
      for (let index = 0; index < whs.length; index++) {
        const element = whs[index];
        if (element.id === id) return true;
      }
      return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  hasCourier = (id: string, whs: IWarehouse) => {
    if (whs.couriers) {
      for (let index = 0; index < whs.couriers.length; index++) {
        const element = whs.couriers[index];
        if (element.id === id) return true;
      }
      return false;
    }
    console.log("Estafetas não inicializados no armazém!");
    return false;
  };

  hasRoute = (id: number, whs: IWarehouse) => {
    if (whs.routes) {
      for (let index = 0; index < whs.routes.length; index++) {
        const element = whs.routes[index];
        if (element.id === id) return true;
      }
      return false;
    }
    console.log("Rotas não inicializadas no armazém!");
    return false;
  };

  @computed get getWarehousesSliced(): IWarehouse[] {
    // let slicePage = this.warehouses.slice(
    //   this.page * LIMIT_BY_PAGE,
    //   this.page * LIMIT_BY_PAGE + LIMIT_BY_PAGE
    // );
    return this.warehouses;
  }

  @action clearWarehouse = () => {
    this.warehouse = null;
  };

  @action clearWarehouses = () => {
    this.warehouses = [];
  };

  @action whSetPredicate = (predicate: string, value: string | Date) => {
    this.predicate.clear();
    if (predicate !== "all") {
      this.predicate.set(predicate, value);
    }
  };
}
