import { observer } from 'mobx-react-lite'
import React from 'react'
import { List } from 'semantic-ui-react';
import { ICourier } from '../../../../app/models/user';

interface IProps {
    courier: ICourier;
}

const WarehouseCourierListItem: React.FC<IProps> = ({ courier }) => {
    return (
        <List.Item key={courier.id}>
            <List.Content key={courier.id}><a href={`/courier/${courier.id}`}>{courier.displayname}</a></List.Content>
        </List.Item>
    )
}

export default observer(WarehouseCourierListItem)