import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Button, Card, Icon, Image } from "semantic-ui-react";
import IsAuthorized from "../../app/common/authorization/IsAuthorized";
import { AuthorizationRoles, IUser } from "../../app/models/user";
import { RootStoreContext } from "../../app/stores/rootStore";
import RegisterForm from "./RegisterForm";

interface IProps {
  userList: IUser[];
}

const UserDetails: React.FC<IProps> = ({ userList }) => {
  const rootStore = useContext(RootStoreContext);
  const { openModal } = rootStore.modalStore;
  return (
    <Card.Group stackable>
      {userList.map((user) => (
        <Card key={user.username}>
          <Card.Content>
            <Image
              floated="right"
              size="mini"
              src="https://react.semantic-ui.com/images/avatar/large/steve.jpg"
            />
            <Card.Header>
              <Icon name="user outline" /> {user.displayname}
            </Card.Header>
            <Card.Meta>
              <Icon name="mail" /> {user.email}
            </Card.Meta>
            <Card.Description>{user.username}</Card.Description>
          </Card.Content>
          {user.address ? (
            <Card.Meta textAlign="center">
              {`${
                user.address.addressDisplayName
              } - ${user.address.location.toString()}`}
            </Card.Meta>
          ) : null}
          <IsAuthorized
            component={
              <Card.Content extra>
                <div className="ui two buttons">
                  <Button
                    basic
                    color="green"
                    onClick={() =>
                      openModal(
                        <RegisterForm user={user} registerType="company" />
                      )
                    }
                  >
                    Editar
                  </Button>
                </div>
              </Card.Content>
            }
            roles={[AuthorizationRoles.Admin, AuthorizationRoles.Company]}
          />
        </Card>
      ))}
    </Card.Group>
  );
};

export default observer(UserDetails);
