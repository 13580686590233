import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { history } from "../..";
import { IActivity, IActivityEnvelope } from "../models/activity";
import { IAddress, IAddressFormValues } from "../models/address";
import { ICompaniesEnvelope, ICompany, ICompanySummaryAddresses, ICompanySummaryEnvelope } from "../models/company";
import {
  ICustomer,
  ICustomerEnvelope,
  ICustomerFormValues,
} from "../models/customer";
import { FuelFeeEnvelope } from "../models/fuelfee";
import { County, District, IPostcodeRoute, Locale, PostCode, PostCodeRangeFormValues } from "../models/geo";
import { IRefundStatus } from "../models/refund";
import { IEditRouteQuery, IRoute, IRouteEnvelope, RouteFormValues } from "../models/route";
import { ShiftType } from "../models/shift";
import { ISupplier } from "../models/supplier";
import {
  ICsvIntegrationEnvelope,
  IStatus,
  ITransport,
  ITransportEnvelope,
  ITransportFormValues,
  ITransportSummaryEnvelope,
} from "../models/transport";
import {
  CourierFormValues,
  ICourier,
  ICourierEditQuery,
  ICourierEnvelope,
  IUser,
  IUserFormValues,
} from "../models/user";
import {
  IWarehouse,
  IWarehouseEnvelope,
  WarehouseFormValues,
} from "../models/warehouse";
import { IZone } from "../models/zone";
import { IGetCompanyAddress, IGetCompanyCustomers } from "./middleware/query/address";
import { GetLocalesRespDTO } from "./middleware/query/geo";
import { AddToRouteResponseDTO } from "./middleware/query/route";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem("jwt");
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(undefined, (error) => {
  console.log(error)
  const { status, data, config } = error.response;
  if (error.message === "Network Error" && !error.response) {
    toast.error("Network error!");
  }
  if ((status === 404 || status === 409) && config.method === "post") {
    toast.error(Object.values<string>(data.errors)[0]);
  }
  if (status === 400 && config.method === "post") {
    toast.error(data.errors);
  }
  if (status === 400 && config.method === "get") {
    toast.error(Object.values<string>(data.errors)[0]);
  }
  if (status === 400 && config.method === "put") {
    toast.error(Object.values<string>(data.errors)[0]);
  }

  if (status === 401) {
    toast.error("Não tem autorização!");
  }

  if (
    status === 400 &&
    config.method === "get" &&
    data.errors.hasOwnProperty("id")
  ) {
    history.push("/notfound");
  }

  if (status === 500) {
    history.push("/notfound");
    toast.error("Erro de servidor - Tente novamente!");
  }
  throw error.response;
});

const respondeBody = (response: AxiosResponse) => response.data;

const requests = {
  get: (url: string) => axios.get(url).then(respondeBody),
  post: (url: string, body: {}) => axios.post(url, body).then(respondeBody),
  put: (url: string, body: {}) => axios.put(url, body).then(respondeBody),
  del: (url: string) => axios.delete(url).then(respondeBody),
  patch: (url: string, body: {}) => axios.patch(url, body).then(respondeBody),
};

const Activities = {
  list: (params: URLSearchParams): Promise<IActivityEnvelope> =>
    axios.get("/activities", { params: params }).then(respondeBody),
  details: (id: string) => requests.get(`/activities/${id}`),
  create: (activity: IActivity) => requests.post("/activities/", activity),
  update: (activity: IActivity) =>
    requests.put(`/activities/${activity.id}`, activity),
  delete: (id: string) => requests.del(`/activities/${id}`),
  attend: (id: string) => requests.post(`/activities/${id}/attend`, {}),
  unattend: (id: string) => requests.del(`/activities/${id}/attend`),
};

const User = {
  current: (): Promise<IUser> => requests.get("/user"),
  login: (user: IUserFormValues): Promise<IUser> =>
    requests.post(`/user/login`, user),
  register: (user: IUserFormValues): Promise<IUser> =>
    requests.post(`/user/register`, user),
  registerCourier: (user: CourierFormValues): Promise<ICourier> =>
    requests.post(`/user/register/courier`, user),
  editCourier: (user: ICourierEditQuery): Promise<ICourier> =>
    requests.put(`/user/courier`, user),
  registerToCompany: (
    companyGuid: string,
    user: IUserFormValues
  ): Promise<IUser> => requests.post(`company/${companyGuid}/users`, user),
  edit: (user: IUserFormValues): Promise<IUser> => requests.post(`/user`, user),
};

const Company = {
  create: (company: ICompany) => requests.post(`/company/register`, company),
  edit: (company: ICompany) => requests.post(`/company/edit`, company),
  list: (params: URLSearchParams): Promise<ICompaniesEnvelope> =>
    axios.get(`/company/list`, { params: params }).then(respondeBody),
  all: (): Promise<ICompaniesEnvelope> => requests.get("/company/all"),
  details: (id: string): Promise<ICompany> => requests.get(`/company/${id}`),
  // get all company addresses
  addresses: (companyId: string): Promise<IGetCompanyAddress> => requests.get(`/company/${companyId}/address`),
  customers: (companyId: string): Promise<IGetCompanyCustomers> => requests.get(`/company/${companyId}/customer`),
  updateAddress: (guid: string, address: IAddress) =>
    requests.post(`/company/updateaddress/${guid}`, address),
  userCompany: (): Promise<ICompany> => requests.get("/company/user"),
  addAddress: (guid: string, address: IAddress) =>
    requests.post(`/company/address/${guid}`, address),
};

const Address = {
  create: (address: IAddressFormValues) => requests.post(`/address`, address),
  edit: (address: IAddressFormValues): Promise<string> =>
    requests.post(`/address/edit/${address.addressGuid}`, address),
};

const Transport = {
  list: (params: URLSearchParams): Promise<ITransportSummaryEnvelope> =>
    axios.get(`/transport/company`, { params: params }).then(respondeBody),
  details: (id: string) => requests.get(`/transport/${id}`),
  detailsById: (id: number): Promise<ITransport> =>
    requests.get(`/transport/resume?id=${id}`),
  create: (transport: ITransportFormValues, customerGuid: string) =>
    requests.post(`/transport/create?customerId=${customerGuid}`, transport),
  createFile: (file: FormData): Promise<ICsvIntegrationEnvelope> =>
    axios.post("/transport/create/file", file).then(respondeBody),
  delete: (transportGuid: string): Promise<any> =>
    requests.del(`/transport/${transportGuid}`),
  changeStatus: (status: IStatus) => requests.put(`/transport/status`, status),
  printLabelStatus: (transportsGuid: string[]) =>
    axios.post(`/transport/label`, { transportsGuid }).then(respondeBody),
  printManifestStatus: (transportsGuid: string[]) =>
    axios.post(`/transport/manifest`, { transportsGuid }).then(respondeBody),
  printManifest: (params: URLSearchParams): Promise<ITransportEnvelope> =>
    axios.get(`/transport/manifest`, { params: params }).then(respondeBody),
  updateRefundStatus: (id: number, status: IRefundStatus): Promise<any> =>
    axios
      .post(`/transport/refund/${id}`, { currentStatus: status })
      .then(respondeBody),
};

const Customer = {
  create: (
    customer: ICustomerFormValues,
    companyGuid: string
  ): Promise<ICustomer> =>
    axios.post(`/customer/${companyGuid}`, customer).then(respondeBody),
  list: (
    companyGuid: string,
    params: URLSearchParams
  ): Promise<ICustomerEnvelope> =>
    axios
      .get(`customer/company/${companyGuid}`, { params: params })
      .then(respondeBody),
  addAddress: (customerGuid: string, address: IAddress): Promise<IAddress> =>
    requests.post(`customer/address/${customerGuid}`, address),
  details: (customerGuid: string): Promise<ICustomer> =>
    requests.get(`/customer/${customerGuid}`)
};

const Supplier = {
  details: (vatNumber: string): Promise<ISupplier> =>
    requests.get(`/supplier?vatnumber=${vatNumber}`),
  list: (
    vatnumber: string,
    params: URLSearchParams
  ): Promise<ICompaniesEnvelope> =>
    axios
      .get(`/supplier/list?vatnumber=${vatnumber}`, { params: params })
      .then(respondeBody),
  listResumed: (
    vatnumber: string,
    params: URLSearchParams
  ): Promise<ICompanySummaryEnvelope> =>
    axios.get(`/supplier/addresses/list?vatnumber=${vatnumber}`, { params: params })
      .then(respondeBody),
  addCompany: (vatNumber: string, companyGuid: string): Promise<ICompany> =>
    axios
      .post(
        `/supplier/company?vatnumber=${vatNumber}&companyGuid=${companyGuid}`
      )
      .then(respondeBody),
  removeCompany: (vatNumber: string, companyGuid: string) =>
    requests.post(
      `/supplier/company/delete?vatnumber=${vatNumber}&companyGuid=${companyGuid}`,
      {}
    ),
};

const Report = {
  resumedManifest: (type: string[]) =>
    axios
      .post(`/report/transport/resumed`, type, {
        method: "POST",
        responseType: "blob",
      })
      .then(respondeBody),
  resumedManifestList: (transportsGuid: string[]) =>
    axios
      .post(`/report/transport/resumed/list`, transportsGuid, {
        method: "POST",
        responseType: "blob",
      })
      .then(respondeBody),
  detailedManifest: (transportsGuid: string[]) =>
    axios
      .post(`/report/transport`, transportsGuid, {
        method: "POST",
        responseType: "blob",
      })
      .then(respondeBody),
  labels: (transportsGuid: string[]) =>
    axios
      .post(`/report/label`, transportsGuid, {
        method: "POST",
        responseType: "blob",
      })
      .then(respondeBody),
  resumedTransportReport: (params: URLSearchParams, companiesGuid: string[]) =>
    axios
      .post(`/report/company/transport/resumed`, companiesGuid, {
        method: "POST",
        responseType: "blob",
        params: params,
      })
      .then(respondeBody),
  detailedTransportsReport: (
    params: URLSearchParams,
    companiesGuid: string[]
  ) =>
    axios
      .post(`/report/company/transport`, companiesGuid, {
        method: "POST",
        responseType: "blob",
        params: params,
      })
      .then(respondeBody),
  detailedDebitReport: (params: URLSearchParams, companiesGuid: string[]) =>
    axios
      .post(`/report/company/debit`, companiesGuid, {
        method: "POST",
        responseType: "blob",
        params: params,
      })
      .then(respondeBody),
  resumedDebitReport: (params: URLSearchParams, companiesGuid: string[]) =>
    axios
      .post(`/report/company/debit/resumed`, companiesGuid, {
        method: "POST",
        responseType: "blob",
        params: params,
      })
      .then(respondeBody),
  refundReport: (params: URLSearchParams, companiesGuid: string[]) =>
    axios
      .post(`/report/refund`, companiesGuid, {
        method: "POST",
        responseType: "blob",
        params: params,
      })
      .then(respondeBody),
  volumeShiftReport: (params: URLSearchParams, companiesGuid: string[]) =>
    axios
      .post(`/report/volumes`, companiesGuid, {
        method: "POST",
        responseType: "blob",
        params: params,
      })
      .then(respondeBody),
  transportByCourier: (shiftType: ShiftType) =>
    axios
      .get(`/report/courier?shiftType=${shiftType}`, {
        method: "GET",
        responseType: "blob",
      })
      .then(respondeBody),
  courierDeliveryVolumes: (courierIds: string[]) =>
    axios
      .get(`/report/courier/delivery?${courierIds.map(c => `courierIds=${c}`).join('&')}`, {
        method: "GET",
        responseType: "blob",
      })
      .then(respondeBody),
  warehouseVolumes: (warehouseIds: string[]) =>
    axios
      .get(`/report/warehouse/volumes?${warehouseIds.map(c => `warehouseIds=${c}`).join('&')}`, {
        method: "GET",
        responseType: "blob",
      })
      .then(respondeBody),
};

const Zones = {
  create: (zone: IZone) => requests.post(`/zone`, zone),
  list: (): Promise<IZone[]> => requests.get(`/zone/list`),
};

const FuelFee = {
  get: (): Promise<FuelFeeEnvelope> => requests.get("/fuelfee"),
  add: (value: string) => requests.post(`/fuelfee?fuelFee=${value}`, { value }),
};

const Warehouses = {
  create: (warehouse: WarehouseFormValues): Promise<IWarehouse> =>
    requests.post(`/warehouse`, warehouse),
  list: (params: URLSearchParams): Promise<IWarehouseEnvelope> =>
    axios.get(`/warehouse`, { params: params }).then(respondeBody),
  details: (id: number): Promise<IWarehouse> => requests.get(`/warehouse/details/${id}`),
  addCouriers: (whId: number, courierIds: string[]) =>
    requests.patch(`/warehouse/couriers`, { Id: whId, Courier: courierIds }),
  addRoutes: (whId: number, routesIds: number[]) =>
    requests.patch(`/warehouse/routes`, { whId, routesIds }),
};

const Routes = {
  create: (route: RouteFormValues): Promise<IRoute> =>
    requests.post(`/route`, route),
  edit: (params: IEditRouteQuery): Promise<IRoute> =>
    requests.put(`/route`, params),
  list: (params: URLSearchParams): Promise<IRouteEnvelope> =>
    axios.get(`/route/list`, { params }).then(respondeBody),
  details: (id: number): Promise<IRoute> => requests.get(`/route?id=${id}`),
  addPostCodes: (routeName: string, postCodes: PostCodeRangeFormValues): Promise<AddToRouteResponseDTO> =>
    requests.patch(`/route/postcodes`, { name: routeName, postcodeRange: postCodes }),
  addWarehouse: (routeId: number, whId: number) =>
    requests.patch(`/route/warehouse`, { routeId, warehouseId: whId }),
  addCourier: (routeId: number, courierId: string) =>
    requests.patch(`/route/courier`, { routeId, courierId }),
  addLocal: (localeIds: number[], routeName: string) =>
    requests.patch(`/route/locale`, { ids: localeIds, name: routeName }),
  addCounty: (routeId: number, county: string) =>
    requests.patch(`/route/county`, { routeId, county }),
  addDistrict: (routeId: number, district: string) =>
    requests.patch(`/route/district`, { routeId, district }),
  listLocales: (routeId: number): Promise<Locale[]> =>
    requests.get(`/route/locales/${routeId}`),
  listPostCodes: (routeId: number): Promise<PostCode[]> =>
    requests.get(`/route/${routeId}/postcodes`),
};

const Couriers = {
  list: (params: URLSearchParams): Promise<ICourierEnvelope> =>
    axios.get(`/courier/list`, { params }).then(respondeBody),
  details: (id: string): Promise<ICourier> => requests.get(`/courier/${id}`),
  listRoutes: (id: string): Promise<IRoute[]> =>
    requests.get(`/courier/${id}`),
  addRoute: (courierId: string, routeId: number) =>
    requests.patch(`/route/courier/`, { courierId, routeId }),
  updateWh: (courierID: string, whId: number) =>
    requests.patch(`/courier/warehouse`, { courierID, whId }),
};

const Geo = {
  getPostCode: (postCode: string, country: string): Promise<IPostcodeRoute> =>
    requests.get(`/geo/postcode?country=${country}&postcode=${postCode}`),
  listPostCode: (): Promise<PostCode[]> => requests.get(`/geo/postcode/list`),
  getLocale: (name: string, country: string): Promise<Locale> =>
    requests.get(`/geo/locale/${country}/${name}`),
  listLocales: (): Promise<GetLocalesRespDTO> => requests.get(`/geo/locale/list?limit=5000`),
  listLocalesNoRoute: (routeId: number): Promise<GetLocalesRespDTO> =>
    requests.get(`/geo/locale/filtered/noRoute?limit=5000&routeId=${routeId}`),
  getCounty: (name: string, country: string): Promise<County> =>
    requests.get(`/geo/county/${country}/${name}`),
  listCounties: (): Promise<County[]> => requests.get(`/geo/county/list`),
  getDistrict: (name: string, country: string): Promise<District> =>
    requests.get(`/geo/district/${country}/${name}`),
  listDistricts: (): Promise<District[]> => requests.get(`/geo/district/list`),
};

const Agent = {
  Activities,
  User,
  Company,
  Address,
  Transport,
  Customer,
  Supplier,
  Report,
  Zones,
  FuelFee,
  Routes,
  Warehouses,
  Couriers,
  Geo,
};

export default Agent;
