import React, { Fragment } from "react";
import { Field } from "react-final-form";
import { Checkbox, Divider, Grid, Header, Input } from "semantic-ui-react";
import {
  composeValidators,
  required,
} from "../../../app/common/form/Validators";

interface IProps {
  isRefund: boolean;
}

const CreateTransportDetailsExtra: React.FC<IProps> = ({ isRefund }) => {
  return (
    <Grid.Row>
      <Grid.Column width={3}>
        <Header content="Reembolso?" />
        <Field name="isRefund">
          {(props) => (
            <Checkbox
              name={props.input.name}
              checked={props.input.checked}
              value="isRefund"
              toggle
              onChange={(_ev, data) => props.input.onChange(data.checked)}
            />
          )}
        </Field>
      </Grid.Column>
      <Grid.Column width={3}>
        {isRefund && (
          <Fragment>
            <Field
              name="refundValue"
              type="number"
              validate={composeValidators(required)}
            >
              {(props) => (
                <Input
                  {...props}
                  label={{ basic: true, content: "€" }}
                  labelPosition="right"
                  placeholder="Valor"
                />
              )}
            </Field>
          </Fragment>
        )}
      </Grid.Column>
      <Grid.Column width={4}>
        <Divider vertical content="Obrigatório" />
      </Grid.Column>
      <Grid.Column textAlign="right" width={6}>
        <Header content="Matéria perigosa?" />
        <Field name="isDangerous">
          {(props) => (
            <Checkbox
              name={props.input.name}
              checked={props.input.checked}
              value="isDangerous"
              toggle
              onChange={(_ev, data) => props.input.onChange(data.checked)}
            />
          )}
        </Field>
      </Grid.Column>
    </Grid.Row>
  );
};

export default CreateTransportDetailsExtra;
