import { observer } from "mobx-react-lite";
import React from "react";
import {
  Grid,
  Container,
} from "semantic-ui-react";
import AddressInfo from "../../../app/common/address/AddressInfo";
import { ITransport } from "../../../app/models/transport";

interface IProps {
  transport: ITransport
}

const TransportAdddressInfo: React.FC<IProps> = ({ transport }) => {
  return (
    <Grid divided="vertically">
      <Grid.Row columns={2}>
        <Grid.Column>
          <Container as="h5" textAlign='left' >
            Remetente
          </Container>
            <AddressInfo canEdit={false} address={transport.addressFrom} />
        </Grid.Column>
        <Grid.Column textAlign="right">
          <Container as="h5" textAlign='right'>
            Destinatário
          </Container>
            <AddressInfo canEdit={false} address={transport.addressTo} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default observer(TransportAdddressInfo);
