import { observer } from "mobx-react-lite";
import { useCallback, useContext, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Header, Segment } from "semantic-ui-react";
import IsAuthorized from "../../../../app/common/authorization/IsAuthorized";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { AuthorizationRoles } from "../../../../app/models/user";
import { RootStoreContext } from "../../../../app/stores/rootStore";
import WarehouseCourierList from "./WarehouseCourierList";
import WarehouseRouteList from "./WarehouseRouteList";

interface IProps {
  id: string;
}

const WareHouseDetails: React.FC<RouteComponentProps<IProps>> = ({
  match,
  history,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { warehouse, loading, loadWarehouse } =
    rootStore.warehouseStore;

  const loadWh = useCallback(
    () => {
      loadWarehouse(parseInt(match.params.id))
    },
    [match.params.id, loadWarehouse],
  )

  useEffect(() => {
    loadWh()
  }, [
    match.params.id,
    warehouse,
    loadWh,
    loading
  ]);

  if (loading || !warehouse)
    return (
      <LoadingComponent content="A carregar o armazém" />
    )

  return (
    <Segment>
      <IsAuthorized
        component={
          <Button
            icon="left arrow"
            content="Voltar"
            labelPosition="left"
            onClick={() => history.goBack()}
          />
        }
        roles={[AuthorizationRoles.Admin, AuthorizationRoles.Company]}
      />
      <Segment inverted>
        <h3>Armazém: {warehouse.name}</h3>
        {warehouse.address} <p />
        {warehouse.postcode}
      </Segment>
      <Segment.Group horizontal>
        <Segment>
          <Header size="small" content="Rotas" />
          <WarehouseRouteList warehouseId={warehouse.id} routes={warehouse.routes} />
        </Segment>
        <Segment>
          <Header size="small" content="Estafetas" />
          <WarehouseCourierList whId={warehouse.id} couriers={warehouse.couriers} />
        </Segment>
      </Segment.Group>
    </Segment>
  );
};

export default observer(WareHouseDetails);
