import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Button, Segment } from "semantic-ui-react";
import IsAuthorized from "../../../app/common/authorization/IsAuthorized";
import { getCompanyAddressList } from "../../../app/dataAccess/CompanyData";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { AuthorizationRoles } from "../../../app/models/user";
import { RootStoreContext } from "../../../app/stores/rootStore";
import AddressForm from "../../address/AddressForm";
import CompanyStoreList from "./CompanyStoreList";

const CompanyStore = () => {
  const rootStore = useContext(RootStoreContext);
  const { company } = rootStore.companyStore;
  const { openModal } = rootStore.modalStore;

  let addresses = getCompanyAddressList(company!);
  if(company === undefined) return <LoadingComponent content='teste' />
  return (
    <Segment.Group>
      <IsAuthorized
        roles={[AuthorizationRoles.Admin, AuthorizationRoles.Company]}
        component={
          <Segment clearing>
            <Button
              floated="right"
              color="teal"
              onClick={() =>
                openModal(
                  <AddressForm guid={company!.guid} entityType="company" />
                )
              }
              content="Adicionar endereço"
            />
          </Segment>
        }
      />
      <CompanyStoreList addresses={addresses} />
    </Segment.Group>
  );
};

export default observer(CompanyStore);
