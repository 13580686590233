import { observer } from "mobx-react-lite";
import React, { Fragment, useContext } from "react";
import { Segment, Container } from "semantic-ui-react";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { RootStoreContext } from "../../../app/stores/rootStore";
import CreateTransportCustomer from "./CreateTransportCustomer";
import CreateTransportShipHeader from "./CreateTransportShipHeader";
import CreateTransportShipInfo from "./CreateTransportShipInfo";
import CreateTransportShipper from "./CreateTransportShipper";

interface IProps {
  addressTo: any;
  addressFrom: any;
  setAddress: (...args: any) => any;
}

const CreateTransportReceive: React.FC<IProps> = ({
  addressFrom,
  addressTo,
  setAddress,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { loading } = rootStore.transportStore;

  if (loading) return <LoadingComponent content="A carregar informação" />;
  return (
    <Fragment>
      <Segment>
        <CreateTransportShipHeader
          selected={addressFrom.addressGuid}
          addressType="Remetente"
        />
        <CreateTransportCustomer
          setAddress={setAddress}
          addressType="addressFrom"
        />
        <CreateTransportShipInfo
          key={addressFrom.addressGuid}
          selectedAddressGuid={addressFrom.addressGuid}
          addressType="addressFrom"
        />
      </Segment>
      <Segment>
        <Container>
          <CreateTransportShipHeader
            selected={addressTo.addressGuid}
            addressType="Destinatário"
          />
          <CreateTransportShipper
            setAddress={setAddress}
            addressType="addressTo"
          />
        </Container>
        <CreateTransportShipInfo
          user
          key={addressTo.addressGuid}
          addressType="addressTo"
        />
      </Segment>
    </Fragment>
  );
};

export default observer(CreateTransportReceive);
