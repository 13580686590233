import { IAddress } from "../models/address";

export const getMainAddress = (addresses: IAddress[]) => {
    return addresses.find(x => x.isMain)!;
}

export const getAddress = (addresses: IAddress[], addressGuid: string) => {
    return addresses.find(x => x.addressGuid === addressGuid);
}

export const getDisplayName = (address : IAddress) => {
    return address.addressDisplayName;
}

export const getLocation = (address: IAddress) => {
    return address.location;
}

export const generateOptions = (addresses: IAddress[]) => {
    return addresses.map((address) => {
      return {
        key: address.addressGuid,
        value: address.addressGuid,
        text: `${address.addressDisplayName}, ${address.location}`,
      };
    });
  };
